import { LitElement, html, nothing } from 'lit';
import { customElement, property, state } from 'lit/decorators.js';
import { connect } from 'pwa-helpers/connect-mixin.js';
import { store } from '../../store/store.js';
import { initialize } from '../../store/env/env.js';
import { signedInExistingUser } from '../../store/auth/auth.js';
import './sdb-preview-dialog.js';

@customElement('sdb-embedded-preview-dialog')
class SdbEmbeddedPreviewDialog extends connect(store)(LitElement) {

    @property({ type: Object })
    accessor settings;

    @property({ type: Object })
    accessor user;

    @state()
    accessor ready = false;

    render() {
        return html`
            <store-provider .value=${store}>
                <sdb-preview-dialog></sdb-preview-dialog>
            </store-provider>
        `;
    }

    firstUpdated() {
        store.dispatch(initialize({ settings: this.settings }));
    }
	
	updated(changedProperties) {
		if (changedProperties.has("user")) {
		store.dispatch(signedInExistingUser({ user: this.user }));
		}
	}
}

import { html, LitElement } from 'lit';
import { customElement, property, state } from 'lit/decorators.js';
import { connect } from 'pwa-helpers/connect-mixin.js';
import { store } from '../store/store.js';
import { authenticate } from '../store/auth/auth.js';
import { Routes } from '@lit-labs/router';
import 'urlpattern-polyfill';
import './authentication/sdb-authenticate-silent-renew.js';
import './authentication/sdb-authenticate-callback.js';
import './sdb-composition-editor-app.js';
import './preview/sdb-preview-close-dialog.js';

@customElement('sdb-route')
class SdbRoute extends connect(store)(LitElement) {

    @property({ type: String })
    accessor id;

    @property({ type: String })
    accessor type;

    @state()
    accessor signedIn = false;

    @state()
    accessor busy = false;

    constructor() {
        super();

        this._needsAuthentication = false;

        this._routes = new Routes(this, [
            {
                path: '/silentrenew',
                render: () => html`<sdb-authenticate-silent-renew></sdb-authenticate-silent-renew>`,
            },
            {
                path: '/callback',
                render: () => html`
                    <sdb-authenticate-callback
                        @redirect=${(e) => { this._goto(e.detail.path); }}>
                    </sdb-authenticate-callback>
                `,
            },
            {
                path: '/close-preview',
                render: () => html`<sdb-preview-close-dialog></sdb-preview-close-dialog>`,
            },
            {
                path: '/',
                render: () => { this._goto('/drafts'); },
            },
            {
                path: '/*',
                render: () => this._authenticatedRender(html`
                    <store-provider .value=${store}>
                        <sdb-composition-editor-app></sdb-composition-editor-app>
                    </store-provider>
                `),
                enter: async () => {
                    this._needsAuthentication = true;
                },
            },
        ]);
    }

    render() {
        return html`<div>${this._routes.outlet()}</div>`;
    }

    stateChanged(state) {
        super.stateChanged(state);

        this.signedIn = state.auth.signedIn;
        this.busy = state.oidc.isLoadingUser || state.auth.redirecting;

        if (this._needsAuthentication && !this.signedIn && !this.busy) {
            store.dispatch(authenticate());
        }
    }

    async _goto(path) {
        await this._routes.goto(path);
        window.history.pushState({}, '', path);
    }

    _authenticatedRender(authenticatedHtml) {
        return !this.signedIn ? html`<sdb-loading></sdb-loading>` : authenticatedHtml;
    }
}

export const deepExtend = (...args) => {
    if (args.length < 1 || typeof args[0] !== 'object') {
        return false;
    }

    if (args.length < 2) {
        return args[0];
    }

    const target = args[0];

    args.slice(1).forEach((obj) => {
        if (typeof obj !== 'object' || obj === null || Array.isArray(obj)) {
            return;
        }

        Object.keys(obj).forEach((key) => {
            const src = _safeGetProperty(target, key); // source value
            const val = _safeGetProperty(obj, key); // new value

            // recursion prevention
            if (val === target) {
                return;
            }

            // if new value isn't an object then just overwrite by new value
            // instead of extending.
            if (typeof val !== 'object' || val === null) {
                target[key] = val;
                return;
            }

            // just clone arrays (and recursive clone objects inside)
            if (Array.isArray(val)) {
                target[key] = _deepCloneArray(val);
                return;
            }

            // custom cloning and overwrite for specific objects
            if (_isSpecificValue(val)) {
                target[key] = _cloneSpecificValue(val);
                return;
            }

            // overwrite by new value if source isn't object or array
            if (typeof src !== 'object' || src === null || Array.isArray(src)) {
                target[key] = deepExtend({}, val);
                return;
            }

            // source value and new value are objects both, extending...
            target[key] = deepExtend(src, val);
        });
    });

    return target;
};

export const isObjectEqual = (obj1, obj2) => {
    if (obj1 === obj2) return true;

    if (!_isObject(obj1) || !_isObject(obj2)) {
        return false;
    }

    let keys1 = Object.keys(obj1);
    let keys2 = Object.keys(obj2);

    if (keys1.length !== keys2.length) {
        return false;
    }

    for (let key of keys1) {
        if (!keys2.includes(key) || !isObjectEqual(obj1[key], obj2[key])) {
            return false;
        }
    }

    return true;
}

export const assignAndIsDirty = (target, source) => {
    // create a deep copy of the target object
    const targetCopy = JSON.parse(JSON.stringify(target));

    // perform assign operation
    target = deepExtend(target, source);

    // compare the original copy and the mutated object
    return !isObjectEqual(target, targetCopy);
}

const _isObject = (obj) => {
    return obj !== null && typeof obj === 'object';
};

const _isSpecificValue = (val) => {
    return (typeof Buffer !== 'undefined' && val instanceof Buffer) ||
           val instanceof Date ||
           val instanceof RegExp;
};

const _cloneSpecificValue = (val) => {
    if (typeof Buffer !== 'undefined' && val instanceof Buffer) {
        const x = Buffer.alloc ? Buffer.alloc(val.length) : new Buffer(val.length);
        val.copy(x);
        return x;
    } else if (val instanceof Date) {
        return new Date(val.getTime());
    } else if (val instanceof RegExp) {
        return new RegExp(val);
    } else {
        throw new Error('Unexpected situation');
    }
};

const _deepCloneArray = (arr) => {
    return arr.map((item) => {
        if (typeof item === 'object' && item !== null) {
            if (Array.isArray(item)) {
                return _deepCloneArray(item);
            } else if (_isSpecificValue(item)) {
                return _cloneSpecificValue(item);
            } else {
                return deepExtend({}, item);
            }
        } else {
            return item;
        }
    });
};

const _safeGetProperty = (object, property) => {
    return property === '__proto__' ? undefined : object[property];
};

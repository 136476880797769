/* eslint-disable indent */
import { html, css, LitElement } from 'lit';
import { customElement, state, query } from 'lit/decorators.js';
import connect from 'wc-context-redux/connect';
import sharedStyles from '../../app/styles.js';
import {
    loadDraftsIfNeeded,
    publishDraft,
    createDraft,
    duplicateDraft,
    deleteDraft,
} from '../../store/draft/draft.js';
import { navigate } from '../../store/route/route.js';
import { previewDraft } from '../../store/preview/player.js';
import { cancelBubble } from '../../helpers/events.js';
import { translate } from '../../helpers/translate.js';
import { renderTimestampField } from '../../helpers/controls.js';
import '@lit-labs/virtualizer';
import '@polymer/app-layout/app-layout.js';
import '@polymer/paper-listbox/paper-listbox.js';
import '@polymer/paper-item/paper-item.js';
import '@polymer/paper-menu-button/paper-menu-button.js';
import '@material/web/fab/fab.js';
import '@sdblearning/sdb-learning-frontend';
import '../shared/sdb-search-input.js';
import '../shared/sdb-loading.js';
import '../shared/sdb-empty-list.js';
import '../shared/sdb-dialog.js';

const mapStateToProps = (state) => ({
    drafts: state.draft.list,
    total: state.draft.total,
    search: state.draft.search,
    isLoadingFirstTime: state.draft.status === 'loading' && state.draft.page === -1,
    user: state.auth.user,
});

const mapDispatchToProps = (dispatch) => ({
    loadDraftsIfNeeded: (args) => dispatch(loadDraftsIfNeeded(args)),
    publishDraft: (draftId) => dispatch(publishDraft({ draftId })),
    createDraft: (title) => dispatch(createDraft({ title: title })),
    duplicateDraft: (draftId) => dispatch(duplicateDraft({ draftId })),
    deleteDraft: (draftId) => dispatch(deleteDraft({ draftId })),
    navigate: (path) => dispatch(navigate(path)),
});

@customElement('sdb-composition-draft-list')
class SdbCompositionDraftList extends connect(mapStateToProps, mapDispatchToProps)(LitElement) {

    @state()
    accessor drafts;

    @state()
    accessor total;

    @state()
    accessor search;

    @state()
    accessor isLoadingFirstTime;

    @state()
    accessor user;

    @query('#new_title')
    accessor newTitle;

    static styles = [
        sharedStyles,
        css`
            :host {
                max-width: var(--tcg-content-max-width);
                width: 100%;
            }

            #drafts_list {
                contain: none !important; /* lit-virtualizer hack */
            }

            sdb-content-listitem {
                width: 100%; /* lit-virtualizer screws this up */
            }

            paper-item {
                cursor: pointer;
            }

            paper-icon-button {
                color: var(--icon-button-color);
            }

            paper-listbox {
                min-width: 150px;
            }

            .no-results {
                width: 100%;
                max-width: var(--tcg-content-max-width);
                height: 100%;
                display: flex;
                flex-direction: column;
                align-items: center;
            }

            #no_results_img {
                width: 400px;
                height: 400px;
                background-color: var(--tcg-secondary-background-color);
            }
        `,
    ];

    render() {
        if (this.isLoadingFirstTime) {
            return html`<sdb-loading></sdb-loading>`;
        }

        return html`
            <sdb-layout-60-40>
                <sdb-nav-titlebar slot="left">
                    <span>${translate('headerDrafts')}</span>
                    <span ?hidden=${this.total === undefined}>&nbsp;(${this.total})</span>
                </sdb-nav-titlebar>
                <sdb-search-input
                    slot="right"
                    placeholder=${translate('search')}
                    value=${this.search}
                    @search=${(e) => { this.loadDraftsIfNeeded({ search: e.detail.searchText, reload: true }); }}>
                </sdb-search-input>
            </sdb-layout-60-40>
            <sdb-layout-100>
                ${!this.total
                    ? html`<sdb-empty-list label=${translate('noCompositionsFound')}></sdb-empty-list>`
                    : html`
                        <sdb-content-list separator>
                            <lit-virtualizer
                                id="drafts_list"
                                .items=${this.drafts}
                                .renderItem=${(draft, index) => this._renderDraftListItem(draft, index)}
                                .scrollTarget=${document}>
                            </lit-virtualizer>
                        </sdb-content-list>
                    `
                }
                <md-fab @click=${()=> { this._openDialog('add', null); }}>
                    <sdb-content-icon
                        slot="icon"
                        icon="fal fa-plus"
                        size="24">
                    </sdb-content-icon>
                </md-fab>
            </sdb-layout-100>

            <sdb-dialog
                type="confirm"
                id="publish_dialog"
                heading=${translate('publishHeading')}
                body=${translate('publishBody')}
                confirmCaption=${translate('publish')}
                @confirm=${(e) => { this.publishDraft(e.detail.args); }}>
            </sdb-dialog>

            <sdb-dialog
                type="confirm"
                id="duplicate_dialog"
                heading=${translate('duplicateHeading')}
                confirmCaption=${translate('duplicate')}
                @confirm=${(e) => { this.duplicateDraft(e.detail.args); }}>
            </sdb-dialog>

            <sdb-dialog
                type="confirm"
                id="delete_dialog"
                heading=${translate('deleteHeading')}
                confirmCaption=${translate('delete')}
                @confirm=${(e) => { this.deleteDraft(e.detail.args); }}>
            </sdb-dialog>

            <sdb-dialog
                type="form"
                id="add_dialog"
                heading=${translate('createHeading')}
                confirmCaption=${translate('create')}
                @confirm=${() => { this.createDraft(this.newTitle.value); this.newTitle.value = '';  }}>
                <paper-input
                    id="new_title"
                    transparent
                    style="width: 500px;"
                    label=${translate('title')}>
                </paper-input>
            </sdb-dialog>
        `;
    }

    firstUpdated() {
        this.dispatchEvent(
            new CustomEvent('fullscreen', {
                bubbles: true,
                composed: true,
                detail: {
                    fullscreen: false,
                },
            }),
        );
    }

    _renderDraftListItem(draft, index) {
        this.loadDraftsIfNeeded({ index });

        return html`
            <sdb-content-listitem
                icon="fal fa-square-pen"
                .headline=${draft.title}
                .subtitle=${this._getSubtitle(draft)}
                @click=${()=> { this.navigate(`draft/${draft.id}`); }}>

                <paper-menu-button
                    slot="secondaryAction"
                    horizontal-align="right"
                    vertical-align="bottom"
                    @click=${cancelBubble}>
                    <sdb-content-iconbutton icon="fal fa-ellipsis-vertical" slot="dropdown-trigger"></sdb-content-iconbutton>
                    <paper-listbox slot="dropdown-content">
                        <paper-item @click=${()=> { this._openDialog('publish', draft.id); }}>${translate('publish')}</paper-item>
                        <paper-item @click=${()=> { previewDraft(draft.id); }}>${translate('preview')}</paper-item>
                        <paper-item @click=${()=> { this._openDialog('duplicate', draft.id); }}>${translate('duplicate')}</paper-item>
                        <paper-item class="warning" @click=${()=> { this._openDialog('delete', draft.id); }}>${translate('delete')}</paper-item>
                    </paper-listbox>
                </paper-menu-button>

            </sdb-content-listitem>
        `;
    }

    _getSubtitle(draft) {
        let s = '';
        if (draft.lastModified) {
            s += `${translate('lastModified')}: ${renderTimestampField(draft.lastModified)}`;
        }
        if (draft.lastPublished) {
            if (s.length > 0) {
                s += ' ,';
            }
            s += `${translate('lastPublished')}: ${renderTimestampField(draft.lastPublished)}`;
        }
        return s;
    }

    _renderTimestamp(field) {
        return moment((field || {}).timestamp).fromNow();
    }

    _openDialog(action, args) {
        this.renderRoot.querySelector(`#${action}_dialog`).open(args);
    }
}


import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { httpGet } from '../../helpers/http.js';

export const loadMicrolearnings = createAsyncThunk(
    'microlearning/fetch',
    async (_, { getState }) => {
        const { env, auth } = getState();

        const ltiItems = await httpGet(`${env.settings.microlearningsEndpoint}?clientName=TCG_Internal&ltiType=lti`, auth.user);

        const info = await httpGet(env.settings.microlearningsInfoEndpoint, auth.user);
        const lookup = Object.fromEntries(info.map(item => [item.externalLtiItemId, item]));

        // for now, make the projection here
        return ltiItems
            .filter((i) => i.consumerKey === 'TCG_Internal')
            .map((i) => {
                const info = lookup[i.id] || {
                    contextName: '-',
                    name: i.name,
                    contentType: '-',
                };
                return {
                    uniqueId: i.uniqueId,
                    targetId: i.targetId,
                    consumerKey: i.consumerKey,
                    displayName: `${info.name} | ${info.contextName}`,
                    contextName: info.contextName,
                    name: info.name,
                    contentType: info.contentType,
                }
            });
    },
    {
        condition: (args, { getState }) => {
            const { force } = args || {};
            const { microlearning: state } = getState();

            if (state.status === 'loading' && !force) {
                return false;
            }

            // for now load once, only when forced load again
            if (state.list && !force) {
                return false;
            }
        }

    },
);

const microlearningSlice = createSlice({
    name: 'microlearning',
    initialState: {
        status: 'idle',
        error: null
    },
    reducers: {
        filterMicrolearnings: (state, action) => {
            console.log('filter ml');
            const { payload } = action;

            state.filter = payload.searchText;
            state.filteredList = filterList(state.list, payload.searchText);
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(loadMicrolearnings.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(loadMicrolearnings.fulfilled, (state, action) => {
                const { payload } = action;

                state.status = 'success';
                state.list = payload;
                state.filteredList = filterList(payload, state.filter);
            })
            .addCase(loadMicrolearnings.rejected, (state, action) => {
                state.status = 'failed';
                state.error = action.error;
            });
    },
});

const filterList = (list, text) => !text
    ? list || []
    : list?.filter((i) => i.displayName.toLowerCase().includes(text.toLowerCase())) || [];

export const { filterMicrolearnings } = microlearningSlice.actions;

export default microlearningSlice.reducer;

import {
    createAsyncThunk,
    createSlice,
    isPending,
    isFulfilled,
    isRejected
} from '@reduxjs/toolkit';
import { httpGet, httpPost } from '../../helpers/http.js';
import { launchTypes } from '../elearning/helpers.js';

export const previewMicrolearning = createAsyncThunk(
    'preview/microlearning',
    async (args, { getState }) => {
        const { env, auth } = getState();
        const { launchId } = args.microlearning;

        const data = await getLtiStartData({
            url: env.settings.microlearningStartUrlEndpoint,
            launchId: launchId,
            user: auth.user,
        });

        return {
            launchId: launchId,
            url: data.url,
            popupCanClose: true,
            popup: true,
        };
    },
);

export const previewLtiItem = createAsyncThunk(
    'preview/ltiItem',
    async (args, { getState }) => {
        const { env, auth } = getState();
        const { targetId } = args.item;

        const data = await getLtiStartData({
            url: env.settings.ltiStartUrl,
            launchId: targetId,
            user: auth.user,
        });

        return {
            launchId: targetId,
            url: data.url,
            popupCanClose: false,
            popup: true,
        };
    },
);

export const previewElearning = createAsyncThunk(
    'preview/elearning',
    async (args, { getState }) => {
        const { env, auth } = getState();
        const { targetId, playerType } = args.item;

        var previewItem = await httpGet(`${env.settings.elearningItemsEndpoint}preview/${targetId}`, auth.user);

        switch (playerType) {
            case 0: // 'lti1':
                const data = await getLtiStartData({
                    url: previewItem.startUrl,
                    launchId: targetId,
                    user: auth.user,
                });

                return {
                    playerType: playerType,
                    launchId: targetId,
                    launchType: previewItem.launchType,
                    url: data.url,
                    popupCanClose: false,
                    popup: true,
                };

            case 1: // 'onlineStudio':
                return {
                    playerType: playerType,
                    launchId: targetId,
                    launchType: previewItem.launchType,
                    launchData: {
                        ...previewItem.startData,
                        referenceId: previewItem.reference,
                    },
                    popupCanClose: true,
                    popup: true,
                };

            case 2: // 'scorm12':
            case 3: // 'blackbox':
                return {
                    playerType: playerType,
                    launchId: targetId,
                    launchType: previewItem.launchType,
                    launchData: previewItem.startData,
                    url: previewItem.startUrl,
                    popupCanClose: false,
                    popup: true,
                };
            default:
                return {};
        }
    },
);

const getLtiStartData = async (args) => {
    const port = window.location.port ? `:${window.location.port}` : '';
    const baseUrl = `${window.location.protocol}//${window.location.hostname}${port}`;

    return httpPost(args.url, args.user, {
        uniqueId: args.launchId,
        instanceId: args.instanceId,
        launchPresentationReturnUrl: `${baseUrl}/close-preview`,
        reset: args.reset, // tcgStartUrl only
        idString: args.user.profile.sub, // startUrl only
        displayName: args.user.profile.name,
    });
}

const previewSlice = createSlice({
    name: 'preview',
    initialState: {
        status: 'idle',
        error: null
    },
    reducers: {
        closePreview: (state) => {
            state.popupOpen = false;
            state.launchId = null;
            state.launchType = launchTypes.LAUNCHTYPE_REGULAR;
            state.launchData = null;
            state.launchUrl = null;
    },
    },
    extraReducers: (builder) => {
        builder
            .addMatcher(isPending(previewMicrolearning, previewLtiItem, previewElearning), (state) => {
                state.status = 'loading';
            })
            .addMatcher(isFulfilled(previewMicrolearning, previewLtiItem, previewElearning), (state, action) => {
                const { playerType, launchId, launchType, launchData, url, popup, popupCanClose } = action.payload;

                state.status = 'success';
                state.playerType = playerType;
                state.launchId = launchId;
                state.launchType = launchType;
                state.launchData = launchData;
                state.launchUrl = url;
                state.popupOpen = popup ? true : undefined;
                state.autoClose = popup ? popupCanClose : undefined;
            })
            .addMatcher(isRejected(previewMicrolearning, previewLtiItem, previewElearning), (state, action) => {
                state.status = 'failed';
                state.error = action.error;
            });
    },
});

export const { closePreview } = previewSlice.actions;

export default previewSlice.reducer;

/* eslint-disable indent */
import { html, css, LitElement } from 'lit';
import { customElement, property, state } from 'lit/decorators.js';
import connect from 'wc-context-redux/connect';
import { loadMicrolearnings } from '../../store/microlearning/microlearning.js';
import { translate } from '../../helpers/translate.js';
import '../shared/sdb-search-input.js';

const mapStateToProps = (state) => ({
    list: state.microlearning.list,
    isLoading: state.microlearning.status === 'loading' || !state.microlearning.list,
});

const mapDispatchToProps = (dispatch) => ({
    loadList: () => dispatch(loadMicrolearnings()),
});

@customElement('sdb-microlearning-select-input')
class SdbMicrolearningSelectInput extends connect(mapStateToProps, mapDispatchToProps)(LitElement) {

    @property({ type: String })
    accessor launchId;

    @property({ type: String })
    accessor labelText = '';

    @state()
    accessor list;

    @state()
    accessor isLoading;

    constructor() {
        super();

        document.addEventListener('microlearningSelected', (e) => {
            if (this !== e.detail.sender)
                return;

            this.dispatchEvent(new CustomEvent('itemSelected', {
                detail: {
                    item: e.detail.item,
                },
            }));
        })
    }

    static get styles() {
        return css`
            :host {
                width: 100%;
            }

            .control {
                width: 100%;
                cursor: pointer !important;
            }

            .label {
                color: rgb(115, 115, 115);
                cursor: default;
                display: inline;
                font-family: Roboto, sans-serif;
                font-size: 12px;
                line-height: 16px;
                text-wrap: nowrap;
                white-space-collapse: collapse;
                width: auto;
                -webkit-font-smoothing: antialiased;
                -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
            }

            .input {
                padding: 10px;
                border: 1px dashed #888;
            }

            .text: {
                font-size: 16px;
                color: #000;
            }

            .text.loading {
                font-size: 16px;
                color: rgb(115, 115, 115);
            }
        `;
    }

    render() {
        if (this.isLoading) {
            return html`
                <div class="control">
                    <label class="label">${this.labelText}</label>
                    <div class="input">
                        <div class="loading text">${translate('loading')}</div>
                    </div>
                </div>
            `;
        }

        return html`
            <div class="control">
                <label class="label">${this.labelText}</label>
                <div class="input" @click=${this._openDialog}>
                    <div class="text">${this._findName()}</div>
                </div>
            <div>
        `;
    }

    firstUpdated() {
        this.loadList();
    }

    _openDialog() {
        this.dispatchEvent(new CustomEvent('openMicrolearningSelectDialog', {
            detail: {
                sender: this,
            },
            composed: true,
            bubbles: true,
        }));
    }

    _findName() {
        if (!this.list || !this.launchId) {
            return translate('selectElearningItem');
        };

        const item = this.list.find((i) => i.uniqueId === this.launchId);

        return item ? item.displayName : '';
    }
}


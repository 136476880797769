import { createSlice } from '@reduxjs/toolkit';
import { userManager } from './userManager.js';

export const authenticate = () => async (dispatch, getState) => {
    const { oidc } = getState();

    if (oidc.user) {
        await dispatch(signedIn({ user: oidc.user }));
    } else {
        dispatch(signin());
        dispatch(redirecting());
    }
};

export const signin = () => async () => {
    userManager.signinRedirect({
        state: {
            url: window.location.pathname + window.location.search,
        },
    });
};

export const signedInExistingUser = ({ user }) => async (dispatch) => {
    await dispatch(signedIn({ user }));
};

export const signout = () => async () => {
    userManager.signoutRedirect({
        state: {
            url: window.location.pathname + window.location.search
        },
    });
};

export const handleSilentRenewCallback = () => async (dispatch) => {
    userManager.signinSilentCallback()
        .then(() => {
            console.info('renewed token');
        })
        .catch((err) => {
            console.error(err);
            dispatch(signInError({ error: err }));
        });
};

const authSlice = createSlice({
    name: 'auth',
    initialState: {
        redirecting: false,
        signedIn: false,
        user: null,
    },
    reducers: {
        redirecting: (state) => {
            state.redirecting = true;
            state.signedIn = false;
        },
        signedIn: (state, action) => {
            const { payload } = action;

            state.redirecting = false;
            state.signedIn = true;
            state.user = payload.user;
        },
        signedOut: (state) => {
            state.redirecting = false;
            state.signedIn = false;
            state.user = null;
        },
        signInError: (state) => {
            state.redirecting = false;
            state.signedIn = false;
            state.user = null;
            state.error = true;
        },
    },
});

export const { redirecting, signedIn, signedOut, signInError } = authSlice.actions;

export default authSlice.reducer;

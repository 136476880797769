import { createAsyncThunk, createSlice, isRejected } from '@reduxjs/toolkit';
import { httpGet, httpPost, PAGESIZE } from '../../helpers/http.js';
import { getPlayerType } from './helpers.js';

export const loadElearnings = createAsyncThunk(
    'elearning/fetch',
    async ({ filters = {}, reload }, { getState }) => {
        const { elearning: state, env, auth } = getState();

        const portalId = env?.portalIdentifier || '';
        const newPage = reload ? 0 : state.page + 1;
        const params = new URLSearchParams({
            page: newPage,
            pageSize: PAGESIZE,
        });

        const search = filters.search !== undefined ? filters.search : state.filters.search;
        if (search && search !== '') {
            params.set('search', search);
        }

        const playerType = filters.playerType !== undefined ? filters.playerType : state.filters.playerType;
        if (playerType && playerType !== '') {
            params.set('player', parseInt(playerType, 10));
        }

        const supplier = filters.supplier !== undefined ? filters.supplier : state.filters.supplier;
        if (supplier && supplier !== '') {
            params.set('supplier', supplier);
        }

        const response = await httpGet(`${env.settings.elearningItemsEndpoint}${portalId}?${params}`, auth.user);

        return {
            list: response.data,
            page: newPage,
            total: response.total,
            reload: reload,
            search: search,
            playerType: playerType,
            supplier: supplier,
        };
    },
    {
        condition: ({ index, reload }, { getState }) => {
            const { elearning: state } = getState();
            if (state.status === 'loading') {
                return false;
            }

            if (!reload && (index < state.list.length - 1 || index >= state.total - 1)) {
                return false;
            }
        },
    },
);

export const loadFilters = createAsyncThunk(
    'elearning/fetchFilters',
    async (_, { getState }) => {
        const { env, auth } = getState();

        const portalId = env?.portalIdentifier || '';
        const response = await httpGet(`${env.settings.elearningItemsEndpoint}filters/${portalId}`, auth.user);

        return {
            playerTypes: response.playerTypes,
            suppliers: response.suppliers,
        };
    },
    {
        condition: ({ reload }, { getState }) => {
            const { elearning: state } = getState();

            if (!reload && state.playerTypes?.length > 0) {
                return false;
            }
        },
    },
);

const elearningSlice = createSlice({
    name: 'elearning',
    initialState: {
        status: 'idle',
        filters: {},
        error: null,
        page: -1,
    },
    extraReducers: (builder) => {
        builder
            .addCase(loadElearnings.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(loadElearnings.fulfilled, (state, action) => {
                const { reload, list, page, total, search, playerType, supplier } = action.payload;

                state.status = 'success';
                state.list = reload ? [...list] : [...state.list, ...list];
                state.page = page;
                state.total = total;
                state.filters = { search, playerType, supplier };
            })
            .addCase(loadFilters.pending, (state) => {
                state.status = 'loadingFilters';
            })
            .addCase(loadFilters.fulfilled, (state, action) => {
                const { playerTypes, suppliers } = action.payload;

                state.status = 'success';
                state.playerTypes = playerTypes.map((v) => ({ value: v + '', label: getPlayerType(v)}));
                state.suppliers = suppliers.map((s) => ({ value: s.supplierKey, label: s.name}));
            })
            .addMatcher(
                isRejected(
                    loadElearnings,
                    loadFilters,
                ),
                (state, action) => {
                    state.status = 'failed';
                    state.error = action.error;
                },
            );
    },
});

export default elearningSlice.reducer;


// publishing helpers, thunks are created in the scorm and lti slice
export const publishAsComposition = async (args, { getState }) => {
    const { item } = args;
    const { env, auth } = getState();

    const suffix = getPlayerType(item.playerType);

    await httpPost(`${env.settings.packageEndpoint}${suffix}`, auth.user, {
        portalId: env.portalIdentifier,
        publisherIdString: auth.user.profile.sub,
        publisherName: auth.user.profile.name,
        items: [
            {
                targetId: item.targetId,
                type: 'elearning',
            }
        ],
        updateMode: 2, // 'none'
        distributionKeys: env.hasGlobalId ? [] : [env.portalIdentifier],
    });

    return {
        ...item,
        lastModified: new Date(),
        elearningType: 0,
    };
}

export const unpublishAsComposition = async (args, { getState }) => {
    const { item } = args;
    const { env, auth } = getState();

    await httpPost(`${env.settings.unpublishEndpoint}`, auth.user, {
        targetId: item.targetId,
        portalIds: [env.portalIdentifier],
    });

    return {
        ...item,
        lastModified: new Date(),
        elearningType: 1,
    };
}

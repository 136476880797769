import { store } from '../store.js';

const WINDOW_NAME = 'composition-player-preview';

export const previewDraft = async (draftId) => {
    const settings = store.getState().env.settings;
    window.open(`${settings.previewEndpoint}draft/${draftId}`, WINDOW_NAME);
};

export const previewComposition = async (compositionId) => {
    const settings = store.getState().env.settings;
    window.open(`${settings.previewEndpoint}composition/${compositionId}`, WINDOW_NAME);
};

import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { httpGet, PAGESIZE } from '../../helpers/http.js';

export const loadOsItemsIfNeeded = createAsyncThunk(
    'os/fetch',
    async (args, { getState }) => {
        const { os: state, env, auth } = getState();

        const portalId = env?.portalIdentifier || '';
        const newPage = args.reload ? 0 : state.page + 1;
        const params = new URLSearchParams({
            player: 'onlinestudio',
            page: newPage,
            pageSize: PAGESIZE,
        });

        const search = args.search || args.search === '' ? args.search : state.search;
        if (search) {
            params.set('search', search);
        }

        const response = await httpGet(`${env.settings.elearningItemsEndpoint}${portalId}?${params}`, auth.user);

        return {
            list: response.data,
            page: newPage,
            total: response.total,
            reload: args.reload,
            search: search,
        };
    },
    {
        condition: (args, { getState }) => {
            const { os: state } = getState();

            if (!args.reload && (args.index < state.list.length - 1 || args.index >= state.total - 1)) {
                return false;
            }
        },
    },
);

const osSlice = createSlice({
    name: 'os',
    initialState: {
        status: 'idle',
        list: [],
        error: null,
        page: -1
    },
    extraReducers: (builder) => {
        builder
            .addCase(loadOsItemsIfNeeded.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(loadOsItemsIfNeeded.fulfilled, (state, action) => {
                const { payload } = action;

                state.status = 'success';
                state.list = payload.reload ? [...payload.list] : [...state.list, ...payload.list];
                state.page = payload.page;
                state.total = payload.total;
                state.search = payload.search;
            })
            .addCase(loadOsItemsIfNeeded.rejected, (state, action) => {
                state.status = 'failed';
                state.error = action.error;
            });
    },
});

export default osSlice.reducer;

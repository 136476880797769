import { html, LitElement } from 'lit';
import { customElement, property } from 'lit/decorators.js';

class BaseElement extends LitElement {
    trigger(ev, detail) {
        this.dispatchEvent(new CustomEvent(ev, {
            detail,
            bubbles: true,
            composed: true,
        }));
    }
}

@customElement('sdb-sortable-list')
class SdbSortableList extends BaseElement {

    @property({ type: Number})
    accessor currentSource;

    @property({ type: Number })
    accessor currentTarget;

    firstUpdated() {
        this.addEventListener('dragover', (ev) => ev.preventDefault());
        this.addEventListener('currentTarget', (ev) => this.setCurrentTarget(ev));
        this.addEventListener('currentSource', (ev) => this.setCurrentSource(ev));
        this.addEventListener('complete', (ev) => this.setComplete(ev));
        this.items.map((e, i) => {
            e.index = e.originalIndex = e.currentIndex = i;
            e.currentSource = null;
            return e;
        });
    }

    get items() {
        return [...this.querySelectorAll('sdb-sortable-item')];
    }

    get arrangement() {
        return this.items.map((item, id) => {
            return {
                index: id,
                originalIndex: item.originalIndex,
            };
        });
    }

    reindex() {
        this.items.map((e, i) => e.index = i);
    }

    render() {
        return html`
            <style>
                :host {
                display: block;
                }
            </style>
            <slot></slot>
        `;
    }

    sort() {
        const mapIndex = (item, i) => {
            if (item.currentIndex == this.currentSource) {
                item.index = this.currentTarget + 1;
            } else if (item.index >= this.currentTarget) {
                item.index += 1;
            }
            return item;
        };
        const sortIndex = (a, b) => (a.index > b.index) ? 1 : -1;
        const reduceIndex = (item, i) => {
            item.index = i;
        };
        const unordered = (item, i) => item.index != i;

        this.items.map(mapIndex).sort(sortIndex).map(reduceIndex);

        if (this.items.filter(unordered).length) {
            this.items.sort(sortIndex).map((e) => {
                this.removeChild(e); return e;
            }).map((e) => {
                this.append(e);
            });
        }
    }

    setCurrentTarget(ev) {
        this.currentTarget = ev.detail.index;
        this.sort();
    }

    setCurrentSource(ev) {
        this.items.map((i) => {
            i.currentSource = ev.detail.index;
        });
        this.currentSource = ev.detail.index;
    }

    setComplete(ev) {
        this.items.map((e, i) => {
            e.currentIndex = i;
            e.currentSource = null;
        });
        this.trigger('sorted', { indexes: this.arrangement });
    }
}

@customElement('sdb-sortable-item')
class SdbSortableItem extends BaseElement {

    @property({ type: Number })
    accessor index;

    @property({ type: Number })
    accessor originalIndex;

    @property({ type: Number })
    accessor currentIndex;

    @property({ type: Number })
    accessor currentSource;

    @property({ type: String })
    accessor html;

    firstUpdated() {
        this.setAttribute('draggable', 'true');
        const events = ['dragstart', 'dragover', 'dragend'];

        events.map((e) => this.addEventListener(e, (ev) => this[e](ev), false));
    }

    render() {
        const isSource = this.currentSource == this.currentIndex;
        const highlight = '#eee';

        return html`
            <style>
                :host {
                    display: block;
                    cursor: grab;
                    ${isSource ? html` background-color: ${highlight};` : html``}
                }
            </style>
            <slot></slot>
        `;
    }

    dragstart(ev) {
        this.trigger('currentSource', { index: this.index });
    }

    dragover(ev) {
        ev.preventDefault();
        const before = (ev.offsetY - (ev.target.clientHeight / 2)) < 1;
        const index = before ? this.index - 1 : this.index;

        this.trigger('currentTarget', { index });
    }

    dragend(ev) {
        ev.preventDefault();
        this.trigger('complete');
    }
}

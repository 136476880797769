/* eslint-disable indent */
import { html, css, LitElement } from 'lit';
import { customElement, property, state } from 'lit/decorators.js';
import connect from 'wc-context-redux/connect';
import { translate } from '../../helpers/translate.js';

const mapStateToProps = (state) => ({
    isLoading: state.elearning.status === 'loadingFilters' || !state.elearning.playerTypes,
});

const mapDispatchToProps = () => ({});

@customElement('sdb-elearning-select-input')
class SdbElearningSelectInput extends connect(mapStateToProps, mapDispatchToProps)(LitElement) {

    @property({ type: String })
    accessor launchId;

    @property({ type: String })
    accessor launchName;

    @property({ type: String })
    accessor labelText = '';

    @state()
    accessor isLoading;

    static get styles() {
        return css`
            :host {
                width: 100%;
            }

            .control {
                width: 100%;
                cursor: pointer !important;
            }

            .label {
                color: rgb(115, 115, 115);
                cursor: default;
                display: inline;
                font-family: Roboto, sans-serif;
                font-size: 12px;
                line-height: 16px;
                text-wrap: nowrap;
                white-space-collapse: collapse;
                width: auto;
                -webkit-font-smoothing: antialiased;
                -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
            }

            .input {
                padding: 10px;
                border: 1px dashed #888;
            }

            .text: {
                font-size: 16px;
                color: #000;
            }

            .text.loading {
                font-size: 16px;
                color: rgb(115, 115, 115);
            }
        `;
    }

    connectedCallback() {
        super.connectedCallback();
        document.addEventListener('elearningSelected', this._handleElearningSelected.bind(this));
    }

    disconnectedCallback() {
        super.disconnectedCallback();
        document.removeEventListener('elearningSelected', this._handleElearningSelected.bind(this))
    }

    render() {
        if (this.isLoading) {
            return html`
                <div class="control">
                    <label class="label">${this.labelText}</label>
                    <div class="input">
                        <div class="loading text">${translate('loading')}</div>
                    </div>
                </div>
            `;
        }

        return html`
            <div class="control">
                <label class="label">${this.labelText}</label>
                <div class="input" @click=${this._openDialog}>
                    <div class="text">${this.launchName || translate('selectElearningItem')}</div>
                </div>
            <div>
        `;
    }

    _handleElearningSelected(e) {
        if (this !== e.detail.sender)
            return;

        this.dispatchEvent(new CustomEvent('itemSelected', {
            detail: {
                elearning: e.detail.elearning,
            },
        }));
    }

    _openDialog() {
        this.dispatchEvent(new CustomEvent('openElearningSelectDialog', {
            detail: {
                sender: this,
            },
            composed: true,
            bubbles: true,
        }));
    }
}



import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { httpGet, httpPost } from '../../helpers/http.js';
import { sortOnDate } from '../../helpers/controls.js';
import { deepExtend } from '../../helpers/extend.js';

export const loadAccreditationInfo = createAsyncThunk(
    'accreditationInfo/fetch',
    async (args, { getState }) => {
        const { targetId } = args;
        const { env, auth } = getState();

        const response = await httpGet(`${env.settings.accreditationInfoEndpoint}${targetId}`, auth.user);

        return {
            catalogItemName: response.name,
            accreditations: response.accreditations || [],
            organizations: response.organizations.map((o) => ({
                value: o.key,
                label: o.key,
            })),
        };
    },
);

export const saveAccreditationInfo = createAsyncThunk(
    'accreditationInfo/save',
    async (args, { getState }) => {
        const { targetId } = args;
        const { accreditationInfo: state, env, auth } = getState();

        return await httpPost(`${env.settings.accreditationInfoEndpoint}${targetId}`, auth.user, state.current);
    },
);

export const process = createAsyncThunk(
    'accreditationInfo/process',
    async (args, { getState }) => {
        const { targetId } = args;
        const { env, auth } = getState();

        return await httpPost(`${env.settings.accreditationProcessInfoEndpoint}${targetId}`, auth.user, {
            skipFailed: false,
        });
    },
);

const accreditationInfoSlice = createSlice({
    name: 'accreditationInfo',
    initialState: {
        status: 'idle',
        error: null,
    },
    reducers: {
        updateAccreditationMapping: (state, action) => {
            const { index, changes } = action.payload;

            state.current = state.current.map((o, i) => i === index ? deepExtend(o, changes) : o);
            state.dirty = true;
        },
        createAccreditationMapping: (state) => {
            state.current.unshift({
                supplierKey: 'TCG',
                organizationKey: state.organizations[0].value,
                data: {},
                $opened: true
            });
            state.dirty = true;
        },
        removeAccreditationMapping: (state, action) => {
            const { index } = action.payload;

            state.current.splice(index, 1);
            state.dirty = true;
        },
        openAccreditationMapping: (state, action) => {
            const { index, value } = action.payload;

            state.current[index].$opened = value;
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(loadAccreditationInfo.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(loadAccreditationInfo.fulfilled, (state, action) => {
                const { catalogItemName, accreditations, organizations } = action.payload;

                state.status = 'success';
                state.catalogItemName = catalogItemName;
                state.current = sortOnDate(accreditations, 'validFrom', true);
                state.organizations = organizations;
                state.dirty = false;
            })
            .addCase(loadAccreditationInfo.rejected, (state, action) => {
                state.status = 'failed';
                state.error = action.error;
            })
            .addCase(saveAccreditationInfo.pending, (state) => {
                state.status = 'saving';
            })
            .addCase(saveAccreditationInfo.fulfilled, (state) => {
                state.status = 'success';
                state.current = sortOnDate(state.current.map((o) => ({ ...o, $opened: false })), 'validFrom', true);
                state.dirty = false;
            })
            .addCase(saveAccreditationInfo.rejected, (state, action) => {
                state.status = 'failed';
                state.error = action.error;
            })
            .addCase(process.pending, (state) => {
                state.status = 'processing';
            })
            .addCase(process.fulfilled, (state) => {
                state.status = 'success';
            })
            .addCase(process.rejected, (state, action) => {
                state.status = 'failed';
                state.error = action.error;
            })
    },
});

export const {
    updateAccreditationMapping,
    createAccreditationMapping,
    removeAccreditationMapping,
    openAccreditationMapping,
} = accreditationInfoSlice.actions;

export default accreditationInfoSlice.reducer;

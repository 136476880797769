import moment from 'moment/dist/moment.js';

const EMPTY_ITEM_VALUE = '~~empty~~';

const LOCALES = {
    nl: {
        calendar: 'Kalender',
        cancel: 'Annuleren',
        clear: 'Vrijmaken',
        time: 'Tijd',
        today: 'Vandaag',
        week: 'Week'
    },
    en: {
        calendar: 'Calender',
        cancel: 'Cancel',
        clear: 'Clear',
        time: 'Time',
        today: 'Today',
        week: 'Week'
    }
};

// vaadin-select doesn't except value: null, so we use a string
export const toSelectItems = (obj, emptyLabel) => [
    { value: EMPTY_ITEM_VALUE, label: emptyLabel },
    ...obj
];

export const toSelectedItemValue = (value) => !value && value !== 0 ? EMPTY_ITEM_VALUE : value;

export const getSelectedItemValue = (e) => e.target.value === EMPTY_ITEM_VALUE ? null : e.target.value;

export const formatDatePickerDate = (date) => moment(date).format('YYYY-MM-DD');

export const setupDatePicker = (datePicker) => {
    if (!datePicker)
        return;

    const strings = LOCALES[moment.locale()];

    datePicker.i18n = {
        ...datePicker.i18n,
        ...strings,
        monthNames: moment.months(),
        weekdays: moment.weekdays(),
        weekdaysShort: moment.weekdaysShort(),
        firstDayOfWeek: moment.localeData().firstDayOfWeek(),
        formatDate: d => moment(d).format(moment.localeData().longDateFormat('L')),
        parseDate: s => {
            let rslt = moment(s, moment.localeData().longDateFormat('L'));
            return {
                day: rslt.date(),
                month: rslt.month(),
                year: rslt.year()
            };
        },
        formatTitle: (monthName, fullYear) => `${monthName} ${fullYear}`,
    };
};

export const renderTimestampField = ({ timestamp }) => renderTimestamp(timestamp || {});

export const renderTimestamp = (timestamp) => moment(timestamp).fromNow();

export const formatDate = (date) => !date ? '' : moment.utc(date).local().format('LL');

export const formatTime = (date) => !date ? '' : moment.utc(date).local().format('LT');

export const formatDateTime = (date) => formatDate(date) + ' '  + formatTime(date);

export const sortOnDate = (list, prop, descending) => !descending
    ? [...list].sort((a, b) => new Date(a[prop]) - new Date(b[prop]))
    : [...list].sort((a, b) => new Date(b[prop]) - new Date(a[prop]));

export const getExpirationStatus = (date, ...args) => {
    const now = moment.utc();
    const almostExpired = moment().add(...args);

    return !date
        ? 'none'
        : moment(date).isBefore(now)
            ? 'expired'
            : moment(date).isBefore(almostExpired)
                ? 'almost-expired'
                : 'not-expired';
};

import { html, css, LitElement } from 'lit';
import { customElement } from 'lit/decorators.js';
import sharedStyles from '../../app/styles.js';

@customElement('sdb-overlay-editor-element')
class SdbOverlayEditorElement extends LitElement {

    static get styles() {
        return [
            sharedStyles,
            css`
                :host {
                    --body: #F7F7F7;
                    --tcg-list-bottom-margin: 80px;
                    --tcg-content-max-width: 1400px;
                    --tcg-list-padding: 24px;
                    --tcg-input-padding-bottom: 24px;
                    --icon-button-color: rgba(0,0,0, var(--dark-secondary-opacity));
                    --tcg-input-container-background-color: hsl(0, 0%, 96%);
                    --tcg-input-container-border-radius: 4px 4px 0 0;
                    --tcg-minerva-green: rgb(102, 153, 102); /* #669966 */
                    --tcg-minerva-green-background: rgba(102, 153, 102, 0.2);
                    --tcg-minerva-green-dark: rgb(72, 109, 72); /* #486D48 */
                    --tcg-minerva-red: rgb(204, 102, 102); /* #CC6666 */
                    --tcg-minerva-red-background: rgba(204, 102, 102, 0.2);
                    --tcg-minerva-red-dark: rgb(153, 51, 51); /* #993333 */
                    --tcg-minerva-orange: rgb(204, 153, 0); /* #CC9900 */
                    --tcg-minerva-orange-background: rgba(204, 153, 0, 0.2);
                    --tcg-minerva-orange-dark: rgb(188, 134, 0); /* #BC8600 */
                    --tcg-black-primary-opacity: rgba(0, 0, 0, var(--dark-primary-opacity));
                    --tcg-minerva-gray: rgba(0, 0, 0, 0.04);
                    --sdb-primary-button: #f49d10;
                    --mdc-theme-primary: var(--tcg-primary-background-color);
                    --mdc-theme-secondary: var(--tcg-secondary-background-color);
                    --mdc-theme-on-secondary: var(--tcg-primary-color-onsecondary);

                    /* MAIN */
                    --body: #F7F7F7;
                    --theme-primary: var(--sdb-theme-primary, #00b1aa);
                    --theme-secondary: var(--sdb-theme-secondary, #1e958c);
                    --theme-tertiary: var(--sdb-theme-tertiary, #1f9bb4);
                    /* PROGRESS BAR */
                    --progress-primary:#f49d10;
                    --progress-secondary:#033850;
                    --progress-tertiary:#CC6666;
                    --progress-100:#00b1aa;
                    /* ELEMENT COLORS */
                    --element-lightgrey: #eaeaea;
                    --element-grey: #dddedf;
                    --element-darkgrey: #cacaca;
                    --element-disabled: #a0a0a0;
                    /* HEADER */
                    --header-primary: var(--sdb-header-primary, #00b1aa);
                    --header-secondary: var(--sdb-header-primary, #136f8b);
                    --header-tertiary: var(--sdb-header-primary, #b2b2b2);
                    /* TEXT */
                    --text-primary: #023850;
                    --text-light: #ffffff;
                    --text-dark: #023850;
                    font-family: Source Sans Pro, sans-serif;
                    --font-family: Source Sans Pro, sans-serif;
                    --font-default: 400 16px Source Sans Pro, sans-serif;
                    /* DIALOG */
                    --warning-color: #f49d10;
                    --warning-background: #F3E8D6;
                    --alert-color: #f41710;
                    --alert-background: #F3D2D1;
                    --success-color: #00b1aa;
                    --success-background: #CFE9E8;
                    /* BUTTONS */
                    --btn-primary: var(--sdb-button-primary, #f49d10);
                    --btn-primary-hover: var(--sdb-button-primary-hover, #ed7422);
                    --btn-secondary: var(--sdb-button-secondary, #023850);
                    --btn-secondary-hover: var(--sdb-button-secondary, #013247);
                    --btn-disabled: #eee;

                    --paper-input-container: {
                        --paper-input-container-color: hsl(0, 0%, 58%);
                        background-color: var(--tcg-input-container-background-color);
                        border-radius: var(--tcg-input-container-border-radius);
                        padding-bottom:0;
                        padding-left: 12px;
                        padding-right: 12px;
                    }

                    --paper-input-container-underline: {
                        /*
                        undo padding-left and padding-right of --paper-input-container
                        this makes sure that the underline runs across the whole input container
                        */
                        margin-left: -12px;
                        margin-right: -12px;
                    }

                    --paper-input-container-underline-focus: {
                        /*
                        undo padding-left and padding-right of --paper-input-container
                        this makes sure that the underline runs across the whole input container
                        */
                        margin-left: -12px;
                        margin-right: -12px;
                    }

                    --iron-autogrow-textarea: {
                        padding:0; /* Browser stylesheet defaults to 2px padding, makes this misalign with the label when using paper-textarea. */
                    }

                    --iron-overlay-backdrop-opacity: 0.54;

                    --tcg-subtitle-1: {
                        font-size: 16px;
                        color: rgba(0,0,0,.54);
                    }
                    --tcg-subtitle-2: {
                        font-size: 14px;
                        color: rgba(0,0,0,.54);
                    }

                    /* ################################### */
                    /* Standardized TCG font style classes */
                    /* ################################### */
                    --tcg-subtitle-1-white: {
                        font-size: 16px;
                        color: rgba(255,255,255,var(--dark-secondary-opacity)); /* white 0.54 */
                    }
                    --tcg-subtitle-1-black: {
                        font-size: 16px;
                        color: rgba(0,0,0,var(--light-secondary-opacity)); /* black 0.7 */
                    }
                    --tcg-subtitle-2-white: {
                        font-size: 14px;
                        color: rgba(255,255,255,var(--dark-secondary-opacity)); /* white 0.54 */
                    }
                    --tcg-subtitle-2-black: {
                        font-size: 14px;
                        color: rgba(0,0,0,var(--light-secondary-opacity)); /* black 0.7 */
                    }
                    --tcg-overline-white: {
                        font-size: 10px;
                        text-transform: uppercase;
                        color: rgba(255,255,255,var(--dark-secondary-opacity)); /* white 0.54 */
                    }
                    --tcg-overline-black: {
                        font-size: 10px;
                        text-transform: uppercase;
                        color: rgba(0,0,0,var(--light-secondary-opacity)); /* black 0.7 */
                    }
                    --tcg-H5-white: {
                        font-size: 24px;
                        color: rgba(255,255,255,var(--light-primary-opacity)); /* white 1.0 */
                    }
                    --tcg-H5-black: {
                        font-size: 24px;
                        color: rgba(0,0,0,var(--dark-primary-opacity)); /* black 0.87 */
                    }
                    --tcg-body1-white: {
                        font-size: 16px;
                        color: rgba(255,255,255,var(--light-primary-opacity)); /* white 1.0 */
                    }
                    --tcg-body1-black: {
                        font-size: 16px;
                        color: rgba(0,0,0,var(--dark-primary-opacity)); /* black 0.87 */
                    }
                    --tcg-body2-white: {
                        font-size: 14px;
                        color: rgba(255,255,255,var(--light-secondary-opacity)); /* white 0.7 */
                    }
                    --tcg-body2-black: {
                        font-size: 14px;
                        color: rgba(0,0,0,var(--dark-secondary-opacity)); /* black 0.54 */
                    }
                    --tcg-Button-Active-white: {
                        font-size: 14px;
                        text-transform: uppercase;
                        font-weight: 500;
                        color: rgba(255,255,255,var(--light-primary-opacity)); /* white 1.0 */
                    }
                    --tcg-Button-Active-black: {
                        font-size: 14px;
                        text-transform: uppercase;
                        font-weight: 500;
                        color: rgba(0,0,0,var(--light-primary-opacity)); /* white 1.0 */
                    }
                    --tcg-Button-InActive-white: {
                        font-size: 14px;
                        text-transform: uppercase;
                        font-weight: 500;
                        color: rgba(255,255,255,var(--light-secondary-opacity)); /* white 0.7 */
                    }
                    --tcg-Button-InActive-black: {
                        font-size: 14px;
                        text-transform: uppercase;
                        font-weight: 500;
                        color: rgba(0,0,0,var(--dark-secondary-opacity)); /* black 0.54 */
                    }
                    --tcg-disabled: {
                        opacity: 0.5;
                    }
                    /* ################################### */

                    /* ################################ */
                    /* Standardized TCG opacity classes */
                    /* ################################ */
                    --tcg-opacity-012: {
                        opacity: var(--light-divider-opacity);
                    }
                    --tcg-opacity-03: {
                        opacity: var(--light-disabled-opacity);
                    }
                    --tcg-opacity-038: {
                        opacity: var(--dark-disabled-opacity);
                    }
                    --tcg-opacity-054: {
                        opacity: var(--dark-secondary-opacity);
                    }
                    --tcg-opacity-07: {
                        opacity: var(--light-secondary-opacity);
                    }
                    --tcg-opacity-087: {
                        opacity: var(--dark-primary-opacity);
                    }
                    --tcg-opacity-1: {
                        opacity: var(--light-primary-opacity);
                    }
                    /* ################################ */
                }
            `,
        ];
    }

    render() {
        return html`<slot></slot>`;
    }

    firstUpdated() {
        let container = document.getElementById('app_overlay_container');

        if (!container) {
            container = document.createElement('div', { id: 'app_overlay_container' });
            document.body.appendChild(container);
        }
        container.appendChild(this);
    }
}


import { html, css, LitElement } from 'lit';
import { customElement, property, state, query } from 'lit/decorators.js';
import '@vaadin/upload/theme/material/vaadin-upload.js';
import locales from './sdb-upload-locales.js';

@customElement('sdb-upload')
class SdbUpload extends LitElement {

    @property({
        type: Boolean, hasChanged: () => {
            try {
                return !!document.createEvent('TouchEvent');
            } catch (e) {
                return false;
            }
        }
    })
    accessor nodrop;

    @property({ type: String })
    accessor target = '';

    @property({ type: String })
    accessor method = 'POST';

    @property({ type: Object })
    accessor headers = {};

    @property({ type: Number })
    accessor timeout = 0;

    @property({ type: Array, reflect: true })
    accessor files = [];

    @property({ type: Number })
    accessor maxFiles = Infinity;

    @property({ type: Boolean })
    accessor maxFilesReached = false;

    @property({ type: Number })
    accessor maxFileSize = Infinity;

    @property({ type: String })
    accessor accept = '';

    @property({ type: String })
    accessor formDataName = 'file';

    @property({ type: Boolean })
    accessor noAuto = false;

    @property({ type: Boolean })
    accessor withCredentials = false;

    @property({ type: String })
    accessor capture;

    @property({ type: String })
    accessor language;

    @state()
    accessor errorMessage = 'dfgds';

    @query('#vaadin_upload')
    accessor vaadinUpload;

    uploadFiles(files) {
        this.vaadinUpload.uploadFiles(files);
    }

    getFiles() {
        return this.vaadinUpload.files;
    }

    constructor() {
        super();

        let language = (navigator.language || navigator.browserLanguage || 'en').substring(0, 2);
        this.language = ['nl', 'en'].indexOf(language) > -1 ? language : 'en';
    }

    get i18n() {
        return locales[this.language];
    }

    static get styles() {
        return css`
            :host {
                display: block;
            }

            :host([hidestartbutton]) vaadin-upload-file::part(start-button) {
                display:none;
            }

            :host([hideretrybutton]) vaadin-upload-file::part(retry-button) {
                display:none;
            }

            :host([hideremovebutton]) vaadin-upload-file::part(remove-button) {
                display:none;
            }

            span#error_message{
                display: block;
                padding: 10px 15px;
                color: var(--alert-color);
            }

            :host vaadin-upload > vaadin-button {
                position: relative;
                display: inline-flex;
                align-items: center;
                margin: 5px 5px 5px 0;
                background: var(--btn-primary);
                color: var(--btn-primary-text);
                text-transform:uppercase;
                border: none;
                border-radius: 2px;
                cursor: pointer;
                font: var(--font-default);
                transition: scale .1s ease-in-out;
                -webkit-transform: rotateZ(360deg);
            }

            :host vaadin-upload > vaadin-button:hover {
                transform: scale(1.05);
            }

        `;
    }

    render() {
        return html`
            <div>
                <vaadin-upload
                    id="vaadin_upload"
                    ?nodrop=${this.nodrop}
                    target=${this.target}
                    method=${this.method}
                    .headers=${this.headers}
                    timeout=${this.timeout}
                    .i18n=${this.i18n}
                    .files=${this.files}
                    max-files=${this.maxFiles}
                    ?max-files-reached=${this.maxFilesReached}
                    max-file-size=${this.maxFileSize}
                    accept=${this.accept}
                    form-data-name=${this.formDataName}
                    ?no-auto=${this.noAuto}
                    ?with-credentials=${this.withCredentials}
                    capture=${this.capture}
                    @file-reject=${this._fileReject}
                    @files-changed=${this._filesChanged}
                    @max-files-reached-changed = ${this._dispatchWrappedEvent}
                    @upload-before=${this._dispatchWrappedEvent}
                    @upload-progress=${this._dispatchWrappedEvent}
                    @upload-request=${this._dispatchWrappedEvent}
                    @upload-response=${this._dispatchWrappedEvent}
                    @upload-retry=${this._dispatchWrappedEvent}
                    @upload-success=${this._dispatchWrappedEvent}
                    @upload-start=${this._dispatchWrappedEvent}
                    @upload-error=${this._dispatchWrappedEvent}>
                </vaadin-upload>
                <span id="error_message" .hidden=${this.errorMessage}>${this.errorMessage}</span>
            <div>
        `;
    }

    _fileReject(e) {
        this.errorMessage = e.detail.error;
        this._dispatchWrappedEvent(e);
    }

    _filesChanged(e) {
        window.setTimeout(() => {
            for (let uploadFile of this.vaadinUpload.shadowRoot.querySelectorAll("#fileList vaadin-upload-file")) {
                uploadFile.setAttribute('exportparts', "start-button, retry-button, remove-button, commands");
            }
        }, 0);

        this.errorMessage = null;
        this._dispatchWrappedEvent(e);
    }

    _dispatchWrappedEvent(e) {
        var result = this.dispatchEvent(new CustomEvent(e.type, e));
        if (!result)
            e.preventDefault();
        return result;
    }
}

export const createSupplierKey = (name) => name
    ? name.replace(/[^a-zA-Z0-9-]/g, '').toLowerCase()
    : null;

export const getPlayerType = (playerType) => {
    switch (playerType) {
        case 0: return 'LTI';
        case 1: return 'Online Studio';
        case 2: return 'SCORM';
        default: return '';
    }
}

export const launchTypes = {
    LAUNCHTYPE_REGULAR: 0,
    LAUNCHTYPE_NEWWINDOW: 1,
};


import { css } from 'lit';

export default css`
    .tcg-subtitle-1-white {
        @apply --tcg-subtitle-1-white;
    }
    .tcg-subtitle-1-black {
        @apply --tcg-subtitle-1-black;
    }
    .tcg-subtitle-2-white {
        @apply --tcg-subtitle-2-white;
    }
    .tcg-subtitle-2-black {
        @apply --tcg-subtitle-2-black;
    }
    .tcg-overline-white {
        @apply --tcg-overline-white;
    }
    .tcg-overline-black {
        @apply --tcg-overline-black;
    }
    .tcg-H5-white {
        @apply --tcg-H5-white;
    }
    .tcg-H5-black {
        @apply --tcg-H5-black;
    }
    .tcg-body1-white {
        @apply --tcg-body1-white;
    }
    .tcg-body1-black {
        @apply --tcg-body1-black;
    }
    .tcg-body2-white {
        @apply --tcg-body2-white;
    }
    .tcg-body2-black {
        @apply --tcg-body2-black;
    }
    .tcg-Button-Active-white {
        @apply --tcg-Button-Active-white;
    }
    .tcg-Button-Active-black {
        @apply --tcg-Button-Active-black;
    }
    .tcg-Button-InActive-white {
        @apply --tcg-Button-InActive-white;
    }
    .tcg-Button-InActive-black {
        @apply --tcg-Button-InActive-black;
    }
    .tcg-disabled {
        @apply --tcg-disabled;
    }

    .tcg-opacity-012 {
        @apply --tcg-opacity-012;
    }
    .tcg-opacity-03 {
        @apply --tcg-opacity-03;
    }
    .tcg-opacity-038 {
        @apply --tcg-opacity-038;
    }
    .tcg-opacity-054 {
        @apply --tcg-opacity-054;
    }
    .tcg-opacity-07 {
        @apply --tcg-opacity-07;
    }
    .tcg-opacity-087 {
        @apply --tcg-opacity-087;
    }
    .tcg-opacity-1 {
        @apply --tcg-opacity-1;
    }

    vaadin-select {
        background-color: var(--tcg-input-container-background-color);
        border-radius: var(--tcg-input-container-border-radius);
        border-bottom: -8px; /* fix the margin-bottom of the inner element */
    }

    vaadin-select[transparent] {
        background-color: transparent;
    }

    paper-icon-item paper-icon-button:not([disabled]),
    paper-item paper-icon-button:not([disabled]),
    paper-menu-button paper-button:not([disabled]) {
        color: var(--icon-button-color);
    }

    paper-button:not([disabled]):not(.primary):not(.contained) {
        color: var(--tcg-primary-background-color);
    }

    paper-button {
        font-size: 14px;
        font-weight: 500;
        letter-spacing: 1.25px;
    }

    paper-button.primary, paper-button.contained {
        background-color: var(--sdb-button-primary);
        color: var(--sdb-button-primary-text);
    }

    paper-badge iron-icon{
        color: white;
    }

    a {
        text-decoration: none;
        color: var(--tcg-primary-background-color); /* TODO: don't use bg color */
    }

    app-header {
        background-color: var(--tcg-primary-background-color-dark);
        color: var(--tcg-primary-color-onprimary);
    }

    .header {
        background-color: var(--tcg-primary-background-color);
        color: var(--tcg-primary-color-onprimary);
        width: 100%;
        box-sizing: border-box;
        padding: 2rem 2rem 0 2rem;
    }
    .nowrap {
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
    }

    paper-card {
        flex-grow: 1;
        background: white;
    }

    .with-href {
        cursor: pointer;
    }

    paper-input {
        --paper-input-container_-_background-color: var(--tcg-input-container-background-color);
        --paper-input-container_-_--paper-input-container-color: hsl(0, 0%, 58%);
        --paper-input-container_-_border-radius: var(--tcg-input-container-border-radius);
    }

    paper-input[transparent] {
        --paper-input-container_-_background-color: transparent;
    }

    paper-input[readonly] {
        --paper-input-container-underline: {
            display: none;
            height: 0;
        }
    }

    paper-item-body[two-line] {
        height: 71px; /* IE 11 fix for min-height: 72px; */
    }

    paper-tab {
        padding: 0 24px 0 24px;
    }

    paper-tabs {
        --paper-tabs-selection-bar-color: var(--btn-primary);}

    paper-tabs paper-badge {
        --paper-badge-margin-left: 24px;
        --paper-badge-margin-bottom: 8px;
    }

    paper-badge {
        --paper-badge-background: var(--btn-primary);
        --paper-badge-text-color: var(--tcg-primary-color-onprimary);
    }

    paper-toast {
        text-align: center;
    }

    app-drawer {
        --app-drawer-content-container: {
            background: transparent;
        }
    }

    .filterContent {
        background: #fff;
    }


    .card-header__iron-icon {
        padding: 0.6rem;
        border-radius: 50%;
        background: #ddd; /* TODO: choose TCG color */

        margin-right: 1rem;
    }

    .card-content__paper-listbox {
        margin-top: -1rem;
    }

    .form-input-width {
        width: calc(50% - 12px);
    }

    @media (max-width: 640px) {
        .form-input-width {
            width: 100%;
        }
    }

    /* dialog start */
    .dialog-content {
        padding: 0 2rem 0 2rem;
    }

    .dialog-content-footer {
        display: flex;
        align-items: center;
        justify-content: space-between;
    }

    .dialog-content-footer__buttons {
        margin-right: 3rem;
    }
    /* dialog end */

    .page-info {
        background-color: var(--tcg-primary-background-color);
        color: var(--tcg-primary-color-onprimary);
        width: 100%;
        box-sizing: border-box;
        padding: 2rem;
    }

    .page-info a {
        color: var(--tcg-primary-color-onprimary);
    }

    .grid-buttons {
        display: flex;
    }

    #content {
        box-sizing: border-box;
        @apply --layout-horizontal;
        flex-wrap: wrap;
        justify-content: center;
        align-items: stretch;
        padding: 0;
        width: 100%;
    }

    .full-width {
        width: 100%;
        flex-basis: auto;
        max-width: none;
    }

    *[hidden] {
        display: none;
    }

    /* TODO: remove both 50% because it is not centering */
    paper-spinner {
        position: fixed;
        left: 50%;
        top: 50%;
        display:none;
    }
    paper-spinner[active] {
        display:inline-block;
    }

    .comment--true {
        --iron-icon-fill-color: var(--tcg-primary-color-onsecondary);
    }

    .comment--false {
        --iron-icon-fill-color: rgba(0, 0, 0 , 0.3); /* TODO: global disabled var ??? */
    }

    .date-time-range {
        display: flex;
    }

    .date-time-range__start {
        margin-right: 3rem;
    }

    paper-item-body {
        border-bottom: 1px solid rgba(0,0,0, var(--dark-divider-opacity));
    }

    .paper-item-extra {
        margin-right: 2rem;
    }

    .paper-item-extra-date {
        display: flex;
    }

    .paper-item-extra-icon {
        margin-right: 0.618rem;
    }

    sdb-nav-mainmenu-item,
    sdb-nav-mainmenu-item::part(menu-item),
    sdb-content-icon.dashboardTileIcon {
        --text-light: var(--tcg-primary-color-onsecondary);
    }

    sdb-content-button:not([secondary]) {
        --text-light: var(--sdb-button-primary-text);
    }


    md-fab {
        --md-fab-icon-color: var(--tcg-primary-color-onsecondary);
        --md-fab-container-color: var(--btn-primary, var(--accent-color));
        position: fixed;
        bottom: 16px;
        right: 16px;
    }

    #toastDiv {
        height: 70px;
        position: fixed;
        bottom: 0;
    }

    @media (max-width: 640px) {
        :host {
            --tcg-list-padding: 0;
        }
    }

    @media (min-width: 1280px) {
        #content {
            padding: 1rem 2rem 1rem 2rem;
        }

        #drawer-toggle {
            display: none;
        }
    }

    .titleText {
        font-size: 24px;
        opacity: 0.87;
        margin: 10px;
    }

    .body1Text {
        font-size: 16px;
        opacity: 0.6;
        margin: 10px;
    }

    .avatar {
        display: inline-block;
        box-sizing: border-box;
        width: 40px;
        height: 40px;
        text-align: center;
    }

    .group-icon-background {
        background: #e0e0e0;
    }

    .group-photo-icons {
        color: rgba(103, 103, 103, 1);
        position: relative;
        top: 50%;
        transform: translateY(-50%);
    }

    .filterHeaderBody2TextWithPadding {
        padding-top: 20px;
        font-size: 16px;
        opacity: 0.6;
    }

    .heading {
        color:rgba(0,0,0, .54);
        font-size: 16px;
    }

    wl-button {
        --button-letter-spacing: 0.078125rem;
        font-weight: 500;
        --button-font-size: 14px;
        border-radius: 4px;
        padding: 11px 16px;
        --font-family-sans-serif: 'Roboto', 'Noto', sans-serif;
    }

    wl-banner {
        padding: 16px;
        --banner-content-padding: 0;
    }

    paper-toggle-button {
        --paper-toggle-button-checked-bar-color: var(--btn-primary);
        --paper-toggle-button-checked-button-color: var(--btn-primary);
        --paper-toggle-button-checked-ink-color: var(--btn-primary);
    }

    /* ######################### */
    /* Styling for FAB movement: */
    /* ######################### */
    @keyframes moveLeft {
        from {margin-right: 0px;}
        to {margin-right: 350px;}
    }

    @keyframes moveRight {
        from {margin-right: 350px;}
        to {margin-right: 0px;}
    }
    .fabPosition {
        transition: margin-right 0.2s;
        margin-right: 350px;
    }

    :host([toggle-filter]) .fabPosition,
    :host([togglefilter]) .fabPosition {
        /* LitElement translates toggleFilter to togglefilter instead of toggle-filter :( */
        margin-right: 0px;
    }
    @media(max-width:600px) {
        .fabPosition {
            margin-right: 0px;
        }
    }
    .slot-div{
        display: inline-block;
        box-sizing: border-box;
        width: 40px;
        height: 40px;
        text-align: center;
        background: #666666;
    }
    .slot-icon-inside-div{
        color: rgb(255, 255, 255);
        position: relative;
        top: 50%;
        transform: translateY(-50%);
    }
    .chip-close-icon{
        cursor:pointer;
        --iron-icon-height: 16px;
        --iron-icon-width: 16px;
        position: relative;
        right: 0;
        color: var(--paper-chip-background-color, #E0E0E0);
        background-color: var(--paper-chip-close-color, #A6A6A6);
        border-radius: 50%;
        top: -1px;
    }
    .toast-error {
        --paper-toast-background-color: #d9534f;
        border-color: #d43f3a;
        --paper-toast-color: white;
    }
    /* ######################### */

    /* Compostion addons  */

    paper-item {
        --font-family-sans-serif: 'Source Sans Pro', sans-serif;
        font-family: 'Source Sans Pro', sans-serif;
    }

    paper-item.warning {
        color: firebrick;
    }

    md-fab {
        --md-fab-container-color: var(--btn-primary, var(--accent-color));
        --md-fab-icon-color: var(--tcg-primary-color-onsecondary);
        --md-fab-container-shape: 50%;
        position: fixed;
        bottom:16px;
        right: 16px;
    }
`;

const featureFlags = {
    standAloneLti: [
        '//d86c54f6-6d65-47b5-a647-a6b6a758c545//e_j.vanschouwen',
        '//d86c54f6-6d65-47b5-a647-a6b6a758c545//j.vanschouwen',
        '//d86c54f6-6d65-47b5-a647-a6b6a758c545//e_s.rijken',
        '//d86c54f6-6d65-47b5-a647-a6b6a758c545//e_m.kreukniet',
    ],
    standAloneOs: [
        '//d86c54f6-6d65-47b5-a647-a6b6a758c545//e_j.vanschouwen',
        '//d86c54f6-6d65-47b5-a647-a6b6a758c545//j.vanschouwen',
        '//d86c54f6-6d65-47b5-a647-a6b6a758c545//e_s.rijken',
    ],
    replaceCatalogItems: [
        '//d86c54f6-6d65-47b5-a647-a6b6a758c545//e_j.vanschouwen',
        '//d86c54f6-6d65-47b5-a647-a6b6a758c545//j.vanschouwen',
        '//d86c54f6-6d65-47b5-a647-a6b6a758c545//e_s.rijken',
        '//d86c54f6-6d65-47b5-a647-a6b6a758c545//e.rikkerink',
        '//d86c54f6-6d65-47b5-a647-a6b6a758c545//a.risamasu',
        '//d86c54f6-6d65-47b5-a647-a6b6a758c545//j.vangeenen',
        '//d86c54f6-6d65-47b5-a647-a6b6a758c545//s.eggermont',
    ],
};

export const hasFeatureFlag = (user, flag) => featureFlags[flag]?.includes(user.profile.sub) || false;
import { createSlice } from '@reduxjs/toolkit';
import environments from '../../app/environments.js'

export const GLOBAL_ID = 'GLOBAL';

const envSlice = createSlice({
    name: 'env',
    initialState: {
    },
    reducers: {
        initialize: (state, action) => {
            const { payload } = action;
            if (!payload) {
                return;
            }

            let { env, portalIdentifier } = payload.settings;
            if (['development', 'production'].indexOf(env) === -1) {
                env = 'development';
            }

            state.portalIdentifier = portalIdentifier || GLOBAL_ID;
            state.hasGlobalId = portalIdentifier === GLOBAL_ID;
            state.settings = environments[env];
        },
    },
});

export const { initialize } = envSlice.actions;

export default envSlice.reducer;
/* eslint-disable indent */
/* eslint-disable max-len */
import { html, css, nothing, LitElement } from 'lit';
import { customElement, property, state, query } from 'lit/decorators.js';
import { map } from 'lit/directives/map.js';
import connect from 'wc-context-redux/connect';
import sharedStyles from '../../app/styles.js';
import {
    loadDraft,
    saveDraft,
    updateDraft,
    createDraftItem,
    updateDraftItem
} from '../../store/draft/draft.js';
import { navigate } from '../../store/route/route.js';
import { previewDraft } from '../../store/preview/player.js';
import { toDuration, fromDuration } from '../../helpers/converters.js';
import { translate } from '../../helpers/translate.js';
import { isRegular, completionTypes } from '../../store/draft/types.js';
import { scrollIntoViewIfNeeded } from '../../helpers/dom.js';
import '@polymer/paper-input/paper-input.js';
import '@vaadin/text-area/theme/material/vaadin-text-area.js';
import '../shared/sdb-overlay-editor-element.js';
import '../shared/sdb-loading.js';
import '../shared/sdb-sortable-list.js';
import '../shared/sdb-overlay-detail.js';
import '../shared/sdb-image-editor.js';
import '../shared/sdb-rich-text-editor.js';
import '../elearning/sdb-microlearning-select-dialog.js';
import '../elearning/sdb-elearning-select-dialog.js';
import './sdb-composition-draft-item.js';


const mapStateToProps = (state) => ({
    draft: state.draft.current,
    numItems: ((state.draft.current || {}).items || []).length,
    isLoading: state.draft.status === 'loading' || !state.draft.current,
    user: state.auth.user,
    isDirty: state.draft.dirty,
    hasGlobalId: state.env.hasGlobalId,
    language: state.env.language,
});

const mapDispatchToProps = (dispatch) => ({
    loadDraft: (draftId) => dispatch(loadDraft({ draftId })),
    saveDraft: (draftId) => dispatch(saveDraft({ draftId })),
    updateDraft: (changes) => dispatch(updateDraft({ changes })),
    createDraftItem: () => dispatch(createDraftItem()),
    updateDraftItem: (itemId, changes) => dispatch(updateDraftItem({ itemId, changes })),
    navigate: (path) => dispatch(navigate(path)),
});

@customElement('sdb-composition-draft')
class SdbCompositionDraft extends connect(mapStateToProps, mapDispatchToProps)(LitElement) {

    @property({ type: String })
    accessor draftId;

    @state()
    accessor draft;

    @state()
    accessor numItems;

    @state()
    accessor isLoading = false;

    @state()
    accessor user;

    @state()
    accessor isDirty = false;

    @state()
    accessor language;

    @query('#dirty_form_dialog')
    accessor dirtyFormDialog;

    @query('#new_block_button')
    accessor newBlockButton;

    get singleItem() {
        return (this.draft.items || {}).length ? this.draft.items[0] : {};
    }

    static get styles() {
        return [
            sharedStyles,
            css`
                div.content {
                    width: 100%;
                }

                .sdb-primary-button {
                    font-family: "Source Sans Pro", sans-serif;
                    background-color: var(--btn-primary, #f49d10);
                    color: var(--sdb-button-primary-text, rgba(0,0,0,.87));
                }

                sdb-content-collapsablecard {
                    max-width: 1400px;
                }

                .column-layout {
                    margin-left: -36px;
                    width: 100%;
                    display: flex;
                    flex-direction: row;
                    align-items: flex-start;
                }

                .column-right {
                    display: flex;
                    flex-direction: column;
                    width: 100%;
                    padding-left: 30px;
                }

                .form-layout {
                    margin-top: 20px;
                    display: flex;
                    flex-direction: row;
                    align-items: flex-start;
                }

                .form-layout-left {
                    display: flex;
                    width: 40%;
                }

                .form-layout-left .label {
                    padding-top: 16px;
                }

                .form-layout-right {
                    display: flex;
                    width: 60%;
                    flex-direction: column;
                }

                .row-layout {
                    width: 100%;
                    display: flex;
                }

                .row-layout > *:not(:first-child) {
                    margin-left: 20px;
                }

                vaadin-text-area {
                    width: 100%;
                }

                .medium {
                    width: 100%;
                    max-width: 700px;
                }

                .small {
                    width: 100%;
                    max-width: 300px;
                }

                .xsmall {
                    width: 100%;
                    max-width: 100px;
                }

                paper-item:not([disabled]) {
                    cursor: pointer;
                }

                div.items {
                    display: flex;
                    flex-direction: column;
                    align-items: flex-start;
                }

                sdb-composition-draft-item {
                    margin-top: 15px;
                    width: 100%;
                }

                @keyframes zoomIn {
                    0% {
                        transform: scale(0);
                    }
                    100% {
                        transform: scale(1);
                    }
                }

                @keyframes slideDown {
                    0% {
                        top: -56px;
                    }
                    100% {
                        top: 0;
                    }
                }
            `,
        ];
    }

    render() {
        if (this.isLoading || this.draftId !== this.draft.id) {
            return html`<sdb-loading></sdb-loading>`;
        }

        return html`
            <sdb-overlay-detail
                headline=${this.draft.title}
                @back=${() => this.isDirty ? this.dirtyFormDialog.open() : this.navigate('drafts') }>

                <div slot="buttons">
                    <sdb-content-button
                        id="preview_button"
                        label=${translate('preview')}
                        primary
                        ?disabled=${this.isDirty}
                        @click=${()=> { previewDraft(this.draft.id); }}>
                    </sdb-content-button>
                    <sdb-content-button
                        id="save_button"
                        label=${translate('save')}
                        primary
                        ?disabled=${!this.isDirty}
                        @click=${()=> { this.saveDraft(this.draft.id); }}>
                    </sdb-content-button>
                </div>

                <div slot="content" class="content">
                    <sdb-layout-100>

                        <sdb-content-collapsablecard
                            id="general_card"
                            headline=${translate('generalSettings')}
                            icon="fal fa-cubes"
                            headlineSize="16px"
                            iconSize="32">

                            <div class="column-layout">
                                <sdb-image-editor
                                    image=${this.draft.image}
                                    draftId=${this.draft.id}
                                    iconType="elearning"
                                    @change=${(e) => { this.updateDraft({ image: e.detail.value }); }}>
                                </sdb-image-editor>

                                <div class="column-right">
                                    <paper-input
                                        id="title_input"
                                        transparent
                                        label=${translate('title')}
                                        value=${this.draft.title}
                                        @change=${(e) => this.updateDraft({ title: e.target.value })}>
                                    </paper-input>

                                    <paper-input
                                        id="display_title_input"
                                        transparent
                                        label=${translate('displayTitle')}
                                        value=${this.draft.displayTitle}
                                        @change=${(e) => this.updateDraft({ displayTitle: e.target.value })}>
                                    </paper-input>

                                    <vaadin-text-area
                                        id="summary_textarea"
                                        label=${translate('summary')}
                                        value=${this.draft.summary}
                                        @change=${(e) => this.updateDraft({ summary: e.target.value })}>
                                    </vaadin-text-area>

                                    <sdb-rich-text-editor
                                        id="description_rte"
                                        label=${translate('description')}
                                        value=${this.draft.description}
                                        language=${this.language}
                                        @change=${(e) => this.updateDraft({ description: e.detail.value })}>
                                    </sdb-rich-text-editor>

                                    <div class="form-layout">
                                        <div class="form-layout-left">
                                            <div class="label">${translate('completionSettings')}</div>
                                        </div>
                                        <div class="form-layout-right">
                                            ${this.hasGlobalId && isRegular(this.draft.compositionType)
                                                ? html`
                                                    <paper-input
                                                        id="min_duration_input"
                                                        transparent
                                                        class="small"
                                                        type="number"
                                                        label=${translate('requiredDuration')}
                                                        value=${fromDuration(this.draft.progressSettings.minTotalDuration)}
                                                        @change=${(e) => this.updateDraft({
                                                            progressSettings: {
                                                                minTotalDuration: toDuration(e.target.value)
                                                            }
                                                        })}>
                                                        <div slot="suffix">${translate('minutes')}</div>
                                                    </paper-input>
                                                ` : nothing
                                            }

                                            <div class="row-layout">
                                                <vaadin-select
                                                    id="compeltiontype_select"
                                                    transparent
                                                    class="medium"
                                                    label=${translate('completionType')}
                                                    value=${String(this.draft.progressSettings?.completionType || 0)}
                                                    @change=${(e) => this.updateDraft({
                                                        progressSettings: {
                                                            completionType : +e.target.value,
                                                        }
                                                    })}
                                                    .items=${Object.entries(completionTypes).map(([key, value]) => ({
                                                        value: String(value),
                                                        label: translate(key)
                                                    }))}>
                                                </vaadin-select>

                                                ${this.draft.progressSettings?.completionType === completionTypes.MIN_NUM_BLOCKS
                                                    ? html`
                                                        <paper-input
                                                            id="min_items_input"
                                                            transparent
                                                            class="xsmall"
                                                            type="number"
                                                            min="1"
                                                            max=${this.draft.items.length}
                                                            label=${translate('minItems')}
                                                            value=${String(this.draft.progressSettings?.minNumberItems || 1)}
                                                            @change=${(e) => this.updateDraft({
                                                                progressSettings: {
                                                                    minNumberItems: +e.target.value
                                                                }
                                                            })}>
                                                        </paper-input>
                                                    `: nothing
                                                }
                                            </div>
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </sdb-content-collapsablecard>

                        ${isRegular(this.draft.compositionType)
                            ? html`
                                <div class="items">
                                    ${this.draft.items ? html`
                                        ${map(this.draft.items, (item, index) => html`
                                            <sdb-composition-draft-item
                                                .item=${item}
                                                .isFirst=${index === 0}
                                                .isLast=${index === this.draft.items.length - 1}>
                                            </sdb-composition-draft-item>
                                        `)}` : nothing
                                    }

                                    <sdb-layout-filler size="15"></sdb-layout-filler>
                                    <sdb-content-button
                                        id="new_block_button"
                                        label=${translate('newBlock')}
                                        secondary
                                        @click=${() => { this.createDraftItem(); }}>
                                    </sdb-content-button>
                                </div>
                            ` : nothing
                        }

                    </sdb-layout-100>
                </div>
            </sdb-overlay-detail>

            <sdb-dialog
                type="confirm"
                id="dirty_form_dialog"
                heading=${translate('dirtyFormHeading')}
                body=${translate('dirtyFormBody')}
                confirmCaption=${translate('continue')}
                @confirm=${(e) => { this.navigate('drafts'); }}>
            </sdb-dialog>

            <sdb-microlearning-select-dialog></sdb-microlearning-select-dialog>
            <sdb-elearning-select-dialog></sdb-elearning-select-dialog>
        `;
    }

    firstUpdated() {
        this.dispatchEvent(
            new CustomEvent('fullscreen', {
                bubbles: true,
                composed: true,
                detail: {
                    fullscreen: true,
                },
            }),
        );
    }

    updated(changes) {
        if (!changes.get('numItems')) {
            return;
        }

        const oldValue = parseInt(changes.get('numItems'), 10);

        if (this.numItems > oldValue) {
            setTimeout(() => {
                scrollIntoViewIfNeeded(this.newBlockButton);
            }, 0);
        }
    }
}


/* eslint-disable indent */
import { html, css, LitElement } from 'lit';
import { customElement, property } from 'lit/decorators.js';
import { choose } from 'lit/directives/choose.js';
import sharedStyles from '../../app/styles.js';
import { translate } from '../../helpers/translate.js';
import '@polymer/paper-dialog/paper-dialog.js';
import '@polymer/paper-button/paper-button.js';
import '@polymer/neon-animation/animations/scale-up-animation.js';
import '@polymer/neon-animation/animations/fade-out-animation.js';

const dialogTypes = {
    INFO: 'info',
    CONFIRM: 'confirm',
    FORM: 'form',
};

@customElement('sdb-dialog')
class SdbDialog extends LitElement {

    @property({ type: String })
    accessor type = dialogTypes.INFO;

    @property({ type: String })
    accessor heading = '';

    @property({ type: String })
    accessor confirmCaption = translate('ok');

    @property({ type: String })
    accessor cancelCaption = translate('cancel');

    @property({ type: Boolean, reflect: true })
    accessor confirmDisabled = false;

    @property({ type: String })
    accessor body;

    @property({ type: Boolean })
    accessor opened = false;

    constructor() {
        super();
        this.args = null;
    }

    static get styles() {
        return [
            sharedStyles,
            css`
                p {
                    max-width: 700px;
                }
            `,
        ];
    }

    render() {
        return html`
            <paper-dialog
                id="tcg_dialog"
                ?opened=${this.opened}
                @opened-changed=${this._handleOpenedChanged}
                modal>
                <h2>${this.heading}</h2>
                ${choose(this.type, [
                    [dialogTypes.INFO, () => html`<p>${this.body}</p>`],
                    [dialogTypes.CONFIRM, () => html`<p ?hidden=${!this.body}>${this.body}</p>`],
                    [dialogTypes.FORM, () => html`<slot></slot>`],
                ], () => html`<p>unknown type</p>`)}
                <div class="buttons">
                    <sdb-content-button
                        label=${this.cancelCaption}
                        dialog-dismiss
                        outlined
                        ?hidden=${this.type === dialogTypes.INFO}
                        @click=${() => this._trigger('cancel')}>
                    </sdb-content-button>
                    <sdb-content-button
                        label=${this.confirmCaption}
                        dialog-confirm
                        autofocus
                        secondary
                        ?disabled=${this.confirmDisabled}
                        @click=${() => this._trigger('confirm')}>
                    </sdb-content-button>
                </div>
            </paper-dialog>
        `;
    }

    open(args) {
        this.args = args;
        this.renderRoot.querySelector('#tcg_dialog').open();
    }

    _trigger(eventName) {
        this.dispatchEvent(
            new CustomEvent(eventName, {
                detail: {
                    args: this.args,
                },
                bubbles: true,
                composed: true,
            }),
        );
    }

    _handleOpenedChanged(e) {
        let x = e.detail;
    }
}


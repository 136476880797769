import { html, css, LitElement } from 'lit';
import { customElement } from 'lit/decorators.js';
import { store } from '../store/store.js';
import { connect } from 'pwa-helpers/connect-mixin.js';
import { Router } from '@lit-labs/router';
import moment from 'moment/dist/moment.js';
import { getLanguage } from '../helpers/translate.js';
import 'moment/dist/locale/nl.js';
import './sdb-route.js';

@customElement('sdb-main')
class SdbMain extends connect(store)(LitElement) {
    constructor() {
        super();

        this._routes = new Router(this, []);
    }

    static get styles() {
        return css`
            :host {
                font-family: 'Source Sans Pro', 'sans-serif';
            }
        `;
    }

    render() {
        return html`<sdb-route></sdb-route>`;
    }

    async firstUpdated() {
        const lang = getLanguage();
        moment.locale(lang);
    }
}


import { createSlice, isFulfilled, isRejected } from '@reduxjs/toolkit';
import { startListening } from '../listenerMiddleware.js';
import { translate } from '../../helpers/translate.js';

const notifySlice = createSlice({
    name: 'notify',
    initialState: {
        text: null,
    },
    reducers: {
        notify: (state, action) => {
            const { payload } = action;

            state.text = payload.text;
        },
        notifyDone: (state) => {
            state.text = null;
        },
    },
});

export const { notify, notifyDone } = notifySlice.actions;

export default notifySlice.reducer;

startListening({
    matcher: isFulfilled,
    effect: async (action, { dispatch }) => {
        let message = null;
        switch (action.type) {
            case 'draft/publish/fulfilled': message = 'draftPublished'; break;
            case 'draft/create/fulfilled': message = 'draftCreated'; break;
            case 'draft/duplicate/fulfilled': message = 'draftDuplicated'; break;
            case 'draft/delete/fulfilled': message = 'draftDeleted'; break;
            case 'draft/save/fulfilled': message = 'draftSaved'; break;
            case 'comp/distribute/fulfilled': message = 'compositionDistributed'; break;
            case 'comp/rollbackReplace/fulfilled': message = 'compositionRollbacked'; break;
            case 'lti/publish/fulfilled': message = 'ltiItemPublished'; break;
            case 'lti/unpublish/fulfilled': message = 'ltiItemUnpublished'; break;
            case 'lti/create/fulfilled': message = 'ltiItemCreated'; break;
            case 'lti/delete/fulfilled': message = 'ltiItemDeleted'; break;
            case 'lti/save/fulfilled': message = 'ltiItemSaved'; break;
            case 'accreditationInfo/save/fulfilled': message = 'accreditationsSaved'; break;
            case 'accreditationInfo/process/fulfilled': message = 'accreditationsProcessed'; break;
            case 'scorm/publish/fulfilled': message = 'scormItemPublished'; break;
            case 'scorm/create/fulfilled': message = 'scormItemCreated'; break;
            case 'scorm/delete/fulfilled': message = 'scormItemDeleted'; break;
            case 'scorm/save/fulfilled': message = 'scormItemSaved'; break;
        }

        if (message) {
            dispatch(notify({ text: translate(message) }));
        }
    },
});

startListening({
    matcher: isRejected,
    effect: async (_, { dispatch }) => {
        dispatch(notify({ text: translate('errorOccurred') }));
    },
});

import { html, css, LitElement } from 'lit';
import { customElement, property, state, query } from 'lit/decorators.js';
import connect from 'wc-context-redux/connect';
import sharedStyles from '../../app/styles.js';
import { saveLtiItem, updateLtiItem } from '../../store/lti/lti.js';
import { navigate } from '../../store/route/route.js';
import { translate } from '../../helpers/translate.js';
import { toLtiScore, fromLtiScore } from '../../helpers/converters.js';
import {
    completionPresets,
    getCompletionPresetKey,
    getCompletionPreset,
    scorePresets,
    getScorePresetKey,
    getScorePreset,
} from '../../store/lti/presets.js';
import { previewLtiItem } from '../../store/preview/preview.js';
import { selectRenderer } from '@vaadin/select/lit.js';
import '@vaadin/select/theme/material/vaadin-select.js';
import '@vaadin/item/theme/material/vaadin-item.js';
import '@vaadin/list-box/theme/material/vaadin-list-box.js';
import '@polymer/paper-input/paper-input.js';
import '@polymer/iron-form/iron-form.js';
import '../shared/sdb-loading.js';
import '../shared/sdb-dialog.js';
import '../shared/sdb-parameters.js';
import '../shared/sdb-overlay-detail.js';

const mapStateToProps = (state) => ({
    ltiItem: state.lti.current,
    suppliers: state.lti.suppliers,
    keySecrets: state.lti.keys,
    isLoading: state.lti.status === 'loading' || !state.lti.current || !state.lti.suppliers || !state.lti.keys,
    isDirty: state.lti.dirty,
});

const mapDispatchToProps = (dispatch) => ({
    saveLtiItem: (id) => dispatch(saveLtiItem({ id })),
    updateLtiItem: (changes) => dispatch(updateLtiItem({ changes })),
    previewLtiItem: (item) => dispatch(previewLtiItem({ item })),
    navigate: (path) => dispatch(navigate(path)),
});

@customElement('sdb-lti-item')
class SdbLtiItem extends connect(mapStateToProps, mapDispatchToProps)(LitElement) {

    @property({ type: String })
    accessor ltiItemId;

    @state()
    accessor ltiItem;

    @state()
    accessor suppliers;

    @state()
    accessor keySecrets;

    @state()
    accessor isLoading = false;

    @state()
    accessor isDirty = false;

    @query('#dirty_form_dialog')
    accessor dirtyFormDialog;

    @query('#preset_dialog')
    accessor presetDialog;

    @query('#lti_item_form')
    accessor ltiItemForm;

    get completionPreset() {
        return getCompletionPresetKey(this.ltiItem);
    }

    get scorePreset() {
        return getScorePresetKey(this.ltiItem);
    }

    static get styles() {
        return [
            sharedStyles,
            css`
                #lti_item_form {
                    width: 100%;
                }

                vaadin-select#preset_select {
                    width: 40%;
                    min-width: 200px;
                }

                vaadin-select#key_secrets_combo {
                    width: 100%;
                }

                sdb-content-collapsablecard {
                    max-width: 1400px;
                }

                paper-input, sdb-content-checkbox {
                    margin-bottom: 5px;
                }

                paper-input.small {
                    width: 350px;
                }

                paper-input[required] label {
                    color: red;
                }

                .combine {
                    display: flex;
                    align-items: flex-start;
                }

                .combine vaadin-select {
                    width: 400px;
                }

                .combine paper-input {
                    width: 100px;
                    margin-left: 10px;
                }
            `,
        ];
    }

    render() {
        if (this.isLoading || this.ltiItemId !== this.ltiItem.uniqueId) {
            return html`<sdb-loading></sdb-loading>`;
        }

        return html`
            <sdb-overlay-detail
                headline=${this.ltiItem.displayName}
                @back=${() => this.isDirty ? this.dirtyFormDialog.open() : this.navigate('lti') }>

                <div slot="buttons">
                    <sdb-content-button
                        id="preview_button"
                        label=${translate('preview')}
                        primary
                        ?disabled=${this.isDirty}
                        @click=${()=> { this.previewLtiItem(this.ltiItem); }}>
                    </sdb-content-button>
                    <sdb-content-button
                        id="save_button"
                        label=${translate('save')}
                        primary
                        ?disabled=${!this.isDirty}
                        @click=${() => {
                            if (this.ltiItemForm.validate())
                                this.saveLtiItem(this.ltiItem.id);
                        }}>
                    </sdb-content-button>
                </div>

                <iron-form id="lti_item_form" slot="content">
                    <form>
                        <sdb-layout-100>

                            <sdb-content-card id="general_card">
                            <paper-input
                                id="name_input"
                                transparent
                                label=${translate('name')}
                                value=${this.ltiItem.name}
                                @change=${(e) => {
                                    const trimmedValue = e.target.value.trim();
                                    this.updateLtiItem({
                                        name: trimmedValue,
                                        displayName: trimmedValue,
                                    });
                                }}
                            >
                            </paper-input>

                                <paper-input
                                    id="description_textarea"
                                    transparent
                                    label=${translate('description')}
                                    value=${this.ltiItem.description}
                                    @change=${(e) => this.updateLtiItem({ description: e.target.value })}>
                                </paper-input>

                                <paper-input
                                    id="launch_url_input"
                                    transparent
                                    label=${`${translate('launchUrl')} *`}
                                    auto-validate
                                    required
                                    error-message=${translate('errRequired')}
                                    value=${this.ltiItem.launchUrl}
                                    @change=${(e) => this.updateLtiItem({ launchUrl: e.target.value })}>
                                </paper-input>

                                <vaadin-select
                                    id="preset_select"
                                    transparent
                                    label=${translate('supplier')}
                                    value=${this.ltiItem.type}
                                    ?hidden=${Object.keys(this.suppliers).length === 0}
                                    @change=${(e) => {
                                        this.updateLtiItem({ type: e.target.value });
                                        this.presetDialog.open({ key: e.target.value });
                                    }}
                                    .items=${Object.keys(this.suppliers).map((key) => ({ value: key, label: key }))}>
                                </vaadin-select>
                            </sdb-content-card>

                            <sdb-content-card id="secret_card">
                                <sdb-layout-50-50>
                                    <div slot="left">
                                        <paper-input
                                            id="consumer_key_input"
                                            transparent
                                            label=${`${translate('consumerKey')} *`}
                                            auto-validate
                                            required
                                            error-message=${translate('errRequired')}
                                            value=${this.ltiItem.consumerKey}
                                            @change=${(e) => this.updateLtiItem({ consumerKey: e.target.value })}>
                                        </paper-input>

                                        <paper-input
                                            id="shared_secret_input"
                                            transparent
                                            label=${`${translate('sharedSecret')} *`}
                                            auto-validate
                                            required
                                            error-message=${translate('errRequired')}
                                            value=${this.ltiItem.sharedSecret}
                                            @change=${(e) => this.updateLtiItem({ sharedSecret: e.target.value })}>
                                        </paper-input>
                                    </div>
                                    <div slot="right">
                                        <vaadin-select
                                            id="key_secrets_combo"
                                            transparent
                                            label=${translate('keySecretsList')}
                                            @value-changed=${(e) => {
                                                if (!e.target.value) // cant use @change here, so check initial value changed
                                                    return;

                                                this.updateLtiItem({
                                                    consumerKey: e.target.value.consumerKey,
                                                    sharedSecret: e.target.value.sharedSecret,
                                                })
                                            }}
                                            ${selectRenderer(this._renderKeySecrets, this.keySecrets)}>
                                        </vaadin-select>
                                    </div>
                                </sdb-layout-50-50>
                            </sdb-content-card>

                            <sdb-content-collapsablecard
                                id="secret_card"
                                headline=${translate('settings')}
                                icon="fal fa-sliders-up"
                                headlineSize="16px"
                                iconSize="32">

                                <sdb-layout-50-50>
                                    <div slot="left">
                                        <sdb-content-checkbox
                                            id="fullScreen_checkbox"
                                            label=${translate('fullScreen')}
                                            ?checked=${this.ltiItem.fullScreen}
                                            @checked-changed=${(e) => this.updateLtiItem({ fullScreen: e.detail.value })}>
                                        </sdb-content-checkbox>

                                        <sdb-content-checkbox
                                            id="require_new_window_checkbox"
                                            label=${translate('requireNewWindow')}
                                            ?checked=${this.ltiItem.requireNewWindow}
                                            @checked-changed=${(e) => this.updateLtiItem({ requireNewWindow: e.detail.value })}>
                                        </sdb-content-checkbox>

                                        <sdb-content-checkbox
                                            id="anonymous_checkbox"
                                            label=${translate('anonymous')}
                                            ?checked=${this.ltiItem.anonymous}
                                            @checked-changed=${(e) => this.updateLtiItem({ anonymous: e.detail.value })}>
                                        </sdb-content-checkbox>
                                    </div>
                                    <div slot="right">

                                        <div class="combine">
                                            <vaadin-select
                                                id="completion_select"
                                                transparent
                                                label=${translate('completionType')}
                                                value=${this.completionPreset}
                                                @change=${(e) => {this.updateLtiItem(getCompletionPreset(e.target.value));}}
                                                .items=${Object.keys(completionPresets).map((key) => ({
                                                    value: key,
                                                    label: translate(`preset_${key}`),
                                                }))}>
                                            </vaadin-select>

                                            <paper-input
                                                id="finish_score_input"
                                                transparent
                                                type="number"
                                                step="any"
                                                min="0"
                                                max="100"
                                                step="1"
                                                label=${translate('finishScore')}
                                                value=${fromLtiScore(this.ltiItem.finishScore)}
                                                auto-validate
                                                pattern="^\d{1,3}*$"
                                                error-message=${translate('errChooseBetween0_100')}
                                                ?hidden=${this.completionPreset !== 'score'}
                                                @change=${(e) => {
                                                    if (!e.target.invalid) {
                                                        this.updateLtiItem({ finishScore: toLtiScore(e.target.value) });
                                                    }
                                                }}>
                                            </paper-input>
                                        </div>

                                        <div class="combine">
                                            <vaadin-select
                                                id="score_select"
                                                transparent
                                                label=${translate('scoreType')}
                                                value=${this.scorePreset}
                                                @change=${(e) => {this.updateLtiItem(getScorePreset(e.target.value));}}
                                                .items=${Object.keys(scorePresets).map((key) => ({
                                                    value: key,
                                                    label: translate(`preset_${key}`),
                                                    disabled: key === 'masteryScore' && this.completionPreset !== 'firstResult',
                                                }))}>
                                            </vaadin-select>

                                            <paper-input
                                                id="masteryscore_score_input"
                                                transparent
                                                type="number"
                                                step="any"
                                                min="0"
                                                max="100"
                                                step="1"
                                                label=${translate('masteryScore')}
                                                value=${fromLtiScore(this.ltiItem.masteryScore)}
                                                auto-validate
                                                pattern="^\d{1,3}*$"
                                                error-message=${translate('errChooseBetween0_100')}
                                                ?hidden=${this.scorePreset !== 'masteryScore'}
                                                @change=${(e) => {
                                                    if (!e.target.invalid) {
                                                        this.updateLtiItem({ masteryScore: toLtiScore(e.target.value) });
                                                    }
                                                }}>
                                            </paper-input>
                                        </div>
                                    </div>
                                </sdb-layout-50-50>

                            </sdb-content-collapsablecard>

                            <sdb-content-collapsablecard
                                id="parameters_card"
                                headline=${translate('parameters')}
                                icon="fal fa-bars"
                                headlineSize="16px"
                                iconSize="32"
                            >
                                <paper-input
                                    id="big_number"
                                    transparent
                                    label=${translate('bigNumberParameter')}
                                    value=${this.ltiItem.bigNumberParameter}
                                    @change=${(e) => {
                                        if (!e.target.invalid) {
                                            let value = e.target.value.trim();
                                            if (value == "") {
                                                value = null;
                                            }
                                            this.updateLtiItem({
                                                bigNumberParameter: value,
                                            });
                                        }
                                    }}
                                >
                                </paper-input>
                                <paper-input
                                    id="v_en_vn"
                                    transparent
                                    label=${translate('vEnVnNumberParameter')}
                                    value=${this.ltiItem.vEnVnNumberParameter}
                                    @change=${(e) => {
                                        if (!e.target.invalid) {
                                            let value = e.target.value.trim();
                                            if (value == "") {
                                                value = null;
                                            }
                                            this.updateLtiItem({
                                                vEnVnNumberParameter: value,
                                            });
                                        }
                                    }}
                                >
                                </paper-input>
                                <sdb-parameters
                                    .parameters=${this.ltiItem.parameters}
                                    @changed=${(e) =>
                                        this.updateLtiItem({
                                            parameters: {
                                                ...e.detail.value,
                                            },
                                        })}
                                >
                                </sdb-parameters>
                            </sdb-content-collapsablecard>

                        </sdb-layout-100>
                    </form>
                </iron-form>
            </sdb-overlay-detail>

            <sdb-dialog
                type="confirm"
                id="dirty_form_dialog"
                heading=${translate('dirtyFormHeading')}
                body=${translate('dirtyFormBody')}
                confirmCaption=${translate('continue')}
                @confirm=${(e) => { this.navigate('lti'); }}>
            </sdb-dialog>

            <sdb-dialog
                type="confirm"
                id="preset_dialog"
                heading=${translate('presetHeading')}
                body=${translate('presetBody')}
                confirmCaption=${translate('preset')}
                @confirm=${(e) => { this.updateLtiItem(this.suppliers[e.detail.args.key]); }}>
            </sdb-dialog>
        `;
    }

    firstUpdated() {
        this.dispatchEvent(
            new CustomEvent('fullscreen', {
                bubbles: true,
                composed: true,
                detail: {
                    fullscreen: true,
                },
            }),
        );
    }

    _renderKeySecrets() {
       return html`
            <vaadin-list-box>
                ${this.keySecrets.map(kv => html`
                    <vaadin-item .value=${kv} label=${kv.consumerKey}>
                        <div>
                            <span>${kv.consumerKey}</span>
                            <span style="font-size:13px; color: #888;">(${kv.count})</span>
                        </div>
                        <div style="font-size:13px; color: #888;">${kv.sharedSecret}</div>
                    </vaadin-item>
                `)}
            </vaadin-list-box>
        `;
    }
}

import { html, css, nothing, LitElement } from 'lit';
import { customElement, property, state } from 'lit/decorators.js';
import { map } from 'lit/directives/map.js';
import { translate } from '../../helpers/translate.js';

@customElement('sdb-chipbar-extended')
class SdbChipbarExtended extends LitElement {

    @property({ type: Array})
    accessor chips = [] ;

    @property({ type: Number})
    accessor maxVisible = 5;

    @property({ type: Boolean })
    accessor collapsible = false;

    @state()
    accessor collapsed = true;

    get sortedChips() {
        return [...this.chips].sort(); // Sorting in ascending order
    }

    get inline() {
        return this.sortedChips.slice(0, this.maxVisible);
    }

    get remaining() {
        return this.sortedChips.length - this.maxVisible;
    }

    static get styles() {
        return css`
            .more-or-less {
                display: block;
                display: flex;
                margin-top: 10px;
            }

            .more-or-less sdb-content-icon {
                margin-left: 5px;
            }
        `;
    }

    render() {
        if (!this.chips)
            return nothing;

        return html`
            <sdb-content-chipbar>
                ${map(this.collapsed ? this.inline : this.sortedChips, (chip) => html`
                    <sdb-content-chip label=${chip}></sdb-content-chip>
                `)}
                ${this.remaining > 0 && this.collapsed
                    ? html`<span title=${this.sortedChips.join(', ')}>+${this.remaining}</span>`
                    : nothing
                }
            </sdb-content-chipbar>
            ${this.collapsible && this.sortedChips.length > 0
                ? html`
                    <div
                        class="more-or-less"
                        @click=${() => this.collapsed = !this.collapsed}>
                        <span>${translate(this.collapsed ? 'showAll' : 'hide')}</span>
                        <sdb-content-icon icon="fal fa-circle-chevron-${this.collapsed ? 'down' : 'up'}"></sdb-content-icon>
                    </div>`
                : nothing
            }
    `;
    }
}



import { configureStore } from '@reduxjs/toolkit'
import {
    createOidcMiddleware,
    loadUser,
    reducer as oidc
} from '@competencegroup/redux-oidc-es6';
import { listenerMiddleware } from './listenerMiddleware.js';
import { userManager } from './auth/userManager.js';
import draft from './draft/draft.js';
import comp from './comp/comp.js';
import elearning from './elearning/elearning.js';
import portal from './portal/portal.js';
import lti from './lti/lti.js';
import scorm from './scorm/scorm.js';
import scormPreview from './scorm/scormPreview.js';
import os from './os/os.js';
import catalog from './catalog/catalog.js';
import microlearning from './microlearning/microlearning.js';
import accreditation from './accreditation/accreditation.js';
import accreditationInfo from './accreditationInfo/accreditationInfo.js';
import preview from './preview/preview.js';
import notify from './notify/notify.js';
import route from './route/route.js';
import auth from './auth/auth.js';
import env from './env/env.js';

export const store = configureStore({
    reducer: {
        oidc: oidc,
        draft: draft,
        comp: comp,
        elearning: elearning,
        portal: portal,
        route: route,
        notify: notify,
        lti: lti,
        scorm: scorm,
        scormPreview: scormPreview,
        os: os,
        catalog: catalog,
        microlearning: microlearning,
        accreditation: accreditation,
        accreditationInfo: accreditationInfo,
        preview: preview,
        auth: auth,
        env: env,
    },
    middleware: getDefaultMiddleware => getDefaultMiddleware({
        thunk: true,
        devTools: true,
        serializableCheck: {
            ignoredTypes: ['redux-oidc/USER_FOUND'],
            ignoredPaths: ['oidc.user']
        }
    }).prepend(
        listenerMiddleware.middleware,
    ).concat([
        createOidcMiddleware(userManager),
    ]),
});

loadUser(store, userManager);

import { html, nothing, LitElement } from 'lit';
import { customElement, property, state } from 'lit/decorators.js';
import { connect } from 'pwa-helpers/connect-mixin.js';
import { store } from '../../store/store.js';
import { initialize } from '../../store/env/env.js';
import { signedInExistingUser } from '../../store/auth/auth.js';
import './sdb-composition-editor.js';

@customElement('sdb-embedded-composition-editor')
class SdbEmbeddedCompositionEditor extends connect(store)(LitElement) {

    @property({ type: Object })
    accessor settings;

    @property({ type: Object })
    accessor user;

    @state()
    accessor ready = false;

    render() {
        if (!this.ready) {
            return nothing;
        }

        return html`
            <store-provider .value=${store}>
                <sdb-composition-editor></sdb-composition-editor>
            </store-provider>
        `;
    }

    stateChanged(state) {
        this.ready = !!state.env.settings;
    }

    firstUpdated() {
        store.dispatch(initialize({ settings: this.settings }));

        if (this.user) {
            store.dispatch(signedInExistingUser({ user: this.user }));
        }
    }
}

/**
 * Method to scroll into view port, if it's outside the viewport
 *
 * @param {Object} target - DOM Element
 */
export const scrollIntoViewIfNeeded = (target) => {
    // Target is outside the viewport from the bottom
    if (target.getBoundingClientRect().bottom > window.innerHeight) {
        //  The bottom of the target will be aligned to the bottom of the visible area of the scrollable ancestor.
        target.scrollIntoView(false);
    }

    // Target is outside the view from the top
    if (target.getBoundingClientRect().top < 0) {
        // The top of the target will be aligned to the top of the visible area of the scrollable ancestor
        target.scrollIntoView();
    }
};

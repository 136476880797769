import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import  { httpGet } from '../../helpers/http.js';

export const loadPortals = createAsyncThunk(
    'portal/fetch',
    async (_, { getState }) => {
        const { env, auth } = getState();

        return await httpGet(env.settings.portalsEndpoint, auth.user);
    },
    {
        condition: (args, { getState }) => {
            const { force } = args || {};
            const { portal: state } = getState();

            // for now load once, only when forced load again
            if (state.status !== 'idle' && !force) {
                return false;
            }
        }
    },
);

const portalSlice = createSlice({
    name: 'portal',
    initialState: {
        status: 'idle',
        list: [],
        error: null
    },
    extraReducers: (builder) => {
        builder
            .addCase(loadPortals.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(loadPortals.fulfilled, (state, action) => {
                const { payload } = action;

                state.status = 'success';
                state.list = payload;
            })
            .addCase(loadPortals.rejected, (state, action) => {
                state.status = 'failed';
                state.error = action.error;
            });
    },
});

export default portalSlice.reducer;

import { html, css, LitElement } from 'lit';
import { customElement, property, state, query } from 'lit/decorators.js';
import connect from 'wc-context-redux/connect';
import { unzip } from 'unzipit';
import sharedStyles from '../../app/styles.js';
import { translate } from '../../helpers/translate.js';
import '@vaadin/select/theme/material/vaadin-select.js';
import '@polymer/paper-input/paper-input.js';
import '@polymer/iron-form/iron-form.js';
import '../shared/sdb-loading.js';
import '../shared/sdb-dialog.js';
import '../shared/sdb-parameters.js';
import '../shared/sdb-overlay-detail.js';
import '../shared/sdb-upload.js';
import { httpPost } from '../../helpers/http.js';

const mapStateToProps = (state) => ({
    settings: state.env.settings,
    user: state.auth.user,
    portalId: state.env.portalIdentifier,
});

const mapDispatchToProps = (dispatch) => ({
});

@customElement('sdb-scorm-package-upload')
class SdbScormPackageUpload extends connect(mapStateToProps, mapDispatchToProps)(LitElement) {

    @query('#uploader')
    accessor uploader;

    @state()
    accessor error;

    clear() {
        this.uploader.files = [];
    }

    constructor() {
        super();

        this.package = null;
    }

    static get styles() {
        return [
            sharedStyles,
            css`
                .buttons {
                    width: 100%;
                    display: flex;
                    justify-content: flex-end;
                }

                .error {
                    display: block;
                    color: firebrick;
                    font-size: 13px;
                    margin-left: 50px;
                    margin-top: -10px;
                }
            `,
        ];
    }

    render() {
        return html`
            <div>
                <sdb-upload
                    id="uploader"
                    accept="application/x-zip-compressed"
                    maxFiles="1"
                    formDataName="uploadedFile"
                    method="PUT"
                    noAuto
                    hideStartButton
                    hideRetryButton
                    @files-changed=${this._filesChanged}
                    @upload-request=${this._uploadRequest}
                    @upload-success=${this._uploadSuccess}
                    @upload-error=${this._uploadError}>
                </sdb-upload>
                <span class="error" .hidden=${!this.error}>${this.error}</span>
            </div>
        `;
    }

    async _filesChanged(e) {
        const files = e.detail.value;
        if (!Array.isArray(files))
            return;

        if (files.length === 0) {
            this.error = null;
        } else if (!await this._isScorm12Package(files[0])) {
            this.error = translate('invalidScormPackage');
            return;
        }

        await this._upload();
    }

    async _upload() {
        const files = this.uploader.getFiles();
        if (!files.length)
            return;

        const file = files[0];

        this.package = await httpPost(
            `${this.settings.scormPackageEndpoint}${this.portalId}`,
            this.user,
            {
                filename: file.name,
            },
        );

        file.uploadTarget = this.package.uploadUrl;
        this.uploader.uploadFiles(file);
    }

    _uploadRequest(e) {
        e.preventDefault();

        e.detail.xhr.setRequestHeader('x-ms-blob-type', 'BlockBlob');
        e.detail.xhr.send(e.detail.file);
    }

    _uploadSuccess(e) {
        return this.dispatchEvent(new CustomEvent('upload-completed', {
            detail: {
                ...e.detail,
                package: this.package,
            }
        }));
    }

    _uploadError(e) {
        return this.dispatchEvent(new CustomEvent('upload-error', {
            detail: e.detail,
        }));
    }

    async _isScorm12Package(file) {
        try {
            const { entries } = await unzip(file);
            const manifest = entries['imsmanifest.xml'];

            if (!manifest)
                return false;

            const text = await manifest.text();
            const parser = new DOMParser();
            const xmlDoc = parser.parseFromString(text, 'text/xml');
            const schemaVersion = xmlDoc.getElementsByTagName('schemaversion')[0].childNodes[0].nodeValue;

            return schemaVersion == '1.2';
        }
        catch (e) {
            console.error(e);
            return false;
        }
    }
}

/* eslint-disable indent */
import { html, css, LitElement } from 'lit';
import { customElement, property, query } from 'lit/decorators.js';
import { Debouncer } from '@polymer/polymer/lib/utils/debounce.js';
import { timeOut } from '@polymer/polymer/lib/utils/async.js';
import sharedStyles from '../../app/styles.js';
import '@polymer/paper-input/paper-input.js';

@customElement('sdb-search-input')
class SdbSearchInput extends LitElement {

    @property({ type: String })
    accessor placeholder;

    @property({ type: String })
    accessor value;

    @property({ type: Boolean })
    accessor hasBorder = false;

    @query('#search')
    accessor searchInput;

    constructor() {
        super();
        this._initialized = false;
    }

    static styles = [
        sharedStyles,
        css`
            div.search {
                display: flex;
                flex-direction: row;
                background: white;
                border: none;
                border-radius: 21px;
                padding: 1px 20px 1px 25px;
                align-items: center;
            }

            div.search > sdb-content-icon {
                color: var(--text-primary);
                padding-left: 10px;
            }

            :host([has-border]) div.search {
                border: 1px solid #aaa;
            }
        `,
    ];

    render() {
        return html`
            <custom-style>
                <style>
                    paper-input.custom {
                        justify-self: center;
                        width: 100%;
                        --primary-text-color: var(--text-primary);
                        --paper-input-container-color: #aaa;
                        --paper-input-container-focus-color: var(--text-primary);
                        --paper-input-container-invalid-color: var(--text-primary);
                        --paper-input-container-invalid-color: var(--text-primary);
                        --paper-input-container: { background-color: transparent; };
                        --paper-input-container-underline: { display: none; height: 0;};
                        --paper-input-container-underline-focus: { display: none; };
                    }
                </style>
            </custom-style>

            <div class="search">
                <paper-input
                    class="custom"
                    no-label-float
                    aria-disabled="false"
                    placeholder=${this.placeholder}
                    value=${this.value}
                    @value-changed=${this._valueChanged}>
                </paper-input>
                <sdb-content-icon icon="fal fa-magnifying-glass"></sdb-content-icon>
            </div>
        `;
    }

    _valueChanged(e) {
        if (!this._initialized) {
            this._initialized = true;
            return;
        }

        const searchText = e.target.value;

        this._onChangedDebouncer = Debouncer.debounce(
            this._onChangedDebouncer, // initially undefined
            timeOut.after(400),
            () => {
                this.dispatchEvent(
                    new CustomEvent('search', {
                        bubbles: true,
                        composed: true,
                        detail: {
                            searchText: searchText,
                        },
                    }),
                );
            }
        );
    }
}


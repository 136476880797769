import { html, LitElement } from 'lit';
import { customElement } from 'lit/decorators.js';

@customElement('sdb-preview-close-dialog')
class SdbPreviewCloseDialog extends LitElement {
    constructor() {
        super();

        const appWindow = window.parent; // assume we're loaded in an iframe

        if (appWindow) {
            appWindow.postMessage('close-preview', '*');
        }
    }

    render() {
        return html``;
    }
}


import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { httpGet, httpPost, PAGESIZE } from '../../helpers/http.js';
import { filterValidPortalIds } from '../catalog/catalogHelpers.js';

export const loadCompositionsIfNeeded = createAsyncThunk(
    'comp/fetch',
    async (args, { getState }) => {
        const { comp: state, env, auth} = getState();

        const portalId = env?.portalIdentifier || '';
        const newPage = args.reload ? 0 : state.page + 1;

        const params = new URLSearchParams({
            page: newPage,
            pageSize: PAGESIZE,
        });

        const search = args.search || args.search === '' ? args.search : state.search;
        if (search) {
            params.set('search', search);
        }

        const response = await httpGet(`${env.settings.compositionsEndpoint}/${portalId}?${params}`, auth.user);

        return {
            list: response.data,
            page: newPage,
            total: response.total,
            reload: args.reload,
            search: search,
        };
    },
    {
        condition: (args, { getState }) => {
            const { comp: state } = getState();

            if (!args.reload && (args.index < state.list.length - 1 || args.index >= state.total - 1)) {
                return false;
            }
        }
    },
);

export const loadComposition = createAsyncThunk(
    'comp/fetchOne',
    async (args, { getState }) => {
        const { env, auth } = getState();

        return await httpGet(`${env.settings.publishEndpoint}info/${args.targetId}`, auth.user);
    },
);

export const distributeComposition = createAsyncThunk(
    'comp/distribute',
    async (args, { getState }) => {
        const { env, auth, portal } = getState();
        const { composition, portalIds, replaceTargetId, replaceName, flags } = args;

        await httpPost(`${env.settings.distributeEndpoint}${composition.id}`, auth.user, {
            portalIds: filterValidPortalIds(portalIds, portal.list),
            replacesTargetId: replaceTargetId,
            replacesTargetName: replaceName,
            copyOptions: flags,
        });

        return await httpGet(`${env.settings.publishEndpoint}info/${composition.uniqueId}`, auth.user);
    },
);

export const rollbackReplace = createAsyncThunk(
    'comp/rollbackReplace',
    async (args, { getState }) => {
        const { env, auth, portal } = getState();
        const { composition, portalIds, replaceTargetId } = args;

        await httpPost(`${env.settings.rollbackReplaceEndpoint}${composition.id}`, auth.user, {
            portalIds: filterValidPortalIds(portalIds, portal.list),
            replacesTargetId: replaceTargetId,
        });

        return await httpGet(`${env.settings.publishEndpoint}info/${composition.uniqueId}`, auth.user);
    },
);

const compSlice = createSlice({
    name: 'comp',
    initialState: {
        status: 'idle',
        page: -1,
        list: [],
        infos: {},
        error: null,
    },
    extraReducers: (builder) => {
        builder
            .addCase(loadCompositionsIfNeeded.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(loadCompositionsIfNeeded.fulfilled, (state, action) => {
                const { payload } = action;

                state.status = 'success';
                state.list = payload.reload ? [...payload.list] : [...state.list, ...payload.list];
                state.page = payload.page;
                state.total = payload.total;
                state.search = payload.search;
            })
            .addCase(loadCompositionsIfNeeded.rejected, (state, action) => {
                state.status = 'failed';
                state.error = action.error;
            })
            .addCase(loadComposition.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(loadComposition.fulfilled, (state, action) => {
                const { payload } = action;

                state.status = 'success';
                state.current = payload;
            })
            .addCase(loadComposition.rejected, (state, action) => {
                state.status = 'failed';
                state.error = action.error;
            })
            .addCase(distributeComposition.pending, (state) => {
                state.status = 'distributing';
            })
            .addCase(distributeComposition.fulfilled, (state, action) => {
                const { payload } = action;

                state.status = 'success';
                state.current = payload;
            })
            .addCase(distributeComposition.rejected, (state, action) => {
                state.status = 'failed';
                state.error = action.error;
            })
            .addCase(rollbackReplace.pending, (state) => {
                state.status = 'distributing';
            })
            .addCase(rollbackReplace.fulfilled, (state, action) => {
                const { payload } = action;

                state.status = 'success';
                state.current = payload;
            })
            .addCase(rollbackReplace.rejected, (state, action) => {
                state.status = 'failed';
                state.error = action.error;
            })
    },
});

export default compSlice.reducer;


export default {
    nl: {
        dropFiles: {
            one: 'Sleep bestand hierheen',
            many: 'Sleep bestanden hierheen'
        },
        addFiles: {
            one: 'Selecteer bestand...',
            many: 'Selecteer bestanden...'
        },
        cancel: 'Annuleren',
        error: {
            tooManyFiles: 'Te veel bestanden.',
            fileIsTooBig: 'Bestand is te groot.',
            incorrectFileType: 'Incorrect bestandstype.'
        },
        uploading: {
            status: {
                connecting: 'Verbinden...',
                stalled: 'Vastgelopen.',
                processing: 'Bestand verwerken...',
                held: 'De bestanden zijn nog niet geupload.'
            },
            remainingTime: {
                prefix: 'resterende tijd: ',
                unknown: 'resterende tijd onbekend'
            },
            error: {
                serverUnavailable: 'Server niet bereikbaar',
                unexpectedServerError: 'Onverwachte serverfout',
                forbidden: 'Geweigerd'
            }
        },
        file: {
            retry: 'Retry',
            start: 'Start',
            remove: 'Remove'
        },
        units: {
            size: ['B', 'kB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'],
            sizeBase: 1000
        },
    },
    en: {
        dropFiles: {
            one: 'Drop file here',
            many: 'Drop files here'
        },
        addFiles: {
            one: 'Select file...',
            many: 'Select files...'
        },
        cancel: 'Cancel',
        error: {
            tooManyFiles: 'Too many files.',
            fileIsTooBig: 'File is too big.',
            incorrectFileType: 'Incorrect file type.'
        },
        uploading: {
            status: {
                connecting: 'Connecting...',
                stalled: 'Stalled.',
                processing: 'Processing file...',
                held: 'The attachments have not yet been uploaded.'
            },
            remainingTime: {
                prefix: 'remaining time: ',
                unknown: 'unknown remaining time'
            },
            error: {
                serverUnavailable: 'Server unavailable',
                unexpectedServerError: 'Unexpected server error',
                forbidden: 'Forbidden'
            }
        },
        file: {
            retry: 'Retry',
            start: 'Start',
            remove: 'Remove'
        },
        units: {
            size: ['B', 'kB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'],
            sizeBase: 1000
        }
    }
};
import { html, css, nothing, LitElement } from 'lit';
import { customElement, state, query } from 'lit/decorators.js';
import { map } from 'lit/directives/map.js';
import sharedStyles from '../../app/styles.js';
import connect from 'wc-context-redux/connect';
import { rollbackReplace } from '../../store/comp/comp.js';
import { stopCatalogItemReplaceStatus } from '../../store/catalog/catalog.js';
import { navigate } from '../../store/route/route.js';
import { translate } from '../../helpers/translate.js';
import { hasFeatureFlag } from '../../app/featureFlags.js';
import moment from 'moment/dist/moment.js';
import './sdb-distribution-dialog.js';
import '../shared/sdb-loading.js';
import '../shared/sdb-overlay-editor-element.js';
import '../shared/sdb-overlay-detail.js';
import '../shared/sdb-chipbar-extended.js';

const mapStateToProps = (state) => ({
    info: state.comp.current,
    replaceStatus: state.catalog.replaceStatus,
    isLoading: state.comp.status === 'loading' && !state.comp.current,
    isDistributing: state.comp.status === 'distributing',
    error: state.comp.error,
    user: state.oidc.user,
});

const mapDispatchToProps = (dispatch) => ({
    rollbackReplace: (args) => dispatch(rollbackReplace(args)),
    stopCatalogItemReplaceStatus: () => dispatch(stopCatalogItemReplaceStatus()),
    navigate: (path) => dispatch(navigate(path)),
});

@customElement('sdb-composition')
class SdbComposition extends connect(mapStateToProps, mapDispatchToProps)(LitElement) {

    @state()
    accessor info;

    @state()
    accessor replaceStatus;

    @state()
    accessor portals;

    @state()
    accessor filteredPortals;

    @state()
    accessor total;

    @state()
    accessor search;

    @state()
    accessor isLoading = false;

    @state()
    accessor isDistributing = false;

    @state()
    accessor error;

    @query('#distribute_dialog')
    accessor distributeDialog;

    @query('#replace_dialog')
    accessor replaceDialog;

    @query('#rollback_dialog')
    accessor rollbackDialog;

    get replaceEnabled() {
        return hasFeatureFlag(this.user, 'replaceCatalogItems');
    }

    get replacedOnPortals() {
        return this.info.portals.filter(p =>
            p.replacesTargetIds?.includes(this.info.log?.currentReplacedTargetId));
    }

    get numberOfPortalsDone() {
        return this.replaceStatus?.statuses.filter(p => p.overallStatus === 'done').length || 0;
    }

    get numberOfPortals() {
        return this.replaceStatus?.statuses.length || 0;
    }

    get replaceRrogress() {
        if (!this.replaceStatus || this.numberOfPortals === 0)
            return 0;
        return Math.floor(100 * this.numberOfPortalsDone / this.numberOfPortals);
    }

    static styles = [
        sharedStyles,
        css`
            .content {
                width: 100%;
            }

            sdb-content-list {
                padding: 0 !important;
                margin-left: -20px;
            }

            .scroller {
                max-height: 300px;
                overflow-x: auto;
            }

            sdb-content-chipbar, .empty-chipbar {
                margin-top: 10px;
            }

            .empty-chipbar {
                font-weight: 300;
                color: var(--text-primary);
            }

            .buttons {
                width: 100%;
                display: flex;
                justify-content: flex-end;
            }

            div[hidden] {
                display: none;
            }

            paper-input[readonly] { /* somehow this icw the custom-style works */
                --paper-input-container-underline_-_display: none;
                --paper-input-container_-_background-color: #fff;
            }
        `,
    ]

    render() {
        if (this.isLoading || !this.info) {
            return nothing;
        }

        return html`
            <custom-style>
                <style>
                    paper-input[readonly]] {
                        --paper-input-container-underline: { display: none; height: 0;};
                    }
                </style>
            </custom-style>

            <sdb-overlay-detail
                headline=${this.info.composition.title}
                @back=${() => this.isDirty ? this.dirtyFormDialog.open() : this.navigate('compositions') }>

                <div slot="buttons">
                </div>

                <div slot="content" class="content">

                    <vaadin-progress-bar
                        indeterminate
                        ?hidden=${!this.isDistributing}>
                    </vaadin-progress-bar>

                    <sdb-layout-50-50>
                        <div slot="left">
                            <sdb-content-collapsablecard
                                id="distribution_card"
                                headline=${translate('headerPortals')}
                                icon="fal fa-globe"
                                headlineSize="16px"
                                iconSize="32"
                                opened>
                                ${this.info.portals.length
                                    ? html`
                                        <sdb-chipbar-extended
                                            .chips=${this.info.portals.map(p => p.displayName)}
                                            collapsible>
                                        </sdb-chipbar-extended>
                                    ` : html`
                                        <div class="empty-chipbar">${translate('noPortals')}</div>
                                    `
                                }

                                <div class="buttons">
                                    <sdb-content-button
                                        id="distribute_button"
                                        label=${translate('distribute')}
                                        secondary
                                        ?disabled=${this.isDistributing}
                                        @click=${() => { this.distributeDialog.open(); }}>
                                    </sdb-content-button>
                                </div>
                            </sdb-content-collapsablecard>
                            <div><br><br></div>
                            <sdb-content-collapsablecard
                                id="replace_card"
                                headline=${translate('headerReplace')}
                                icon="fal fa-arrows-retweet"
                                headlineSize="16px"
                                iconSize="32"
                                ?hidden=${!this.info.log?.currentReplacedTargetId && !this.replaceEnabled}
                                opened>
                                ${this.info.log?.currentReplacedTargetId
                                    ? html`
                                        <paper-input
                                            readonly
                                            label=${translate('replacedElearning')}
                                            value=${this.info.log.currentReplacedName}>
                                        </paper-input>
                                    ` : html`<div>${translate('noReplacements')}</div>`
                                }

                                ${this.replaceStatus?.overallStatus === 'inProgress' ? html`
                                        <sdb-content-progressbar
                                            progress=${this.replaceRrogress}
                                            value=${this.numberOfPortalsDone}
                                            totalvalue=${this.numberOfPortals}
                                            showPercentage>
                                        </sdb-content-progressbar>
                                        <div>${translate('replaceInProgress')}</div>
                                    ` : html`
                                        <sdb-chipbar-extended
                                            .chips=${this.replacedOnPortals.map(p => p.displayName)}
                                            collapsible>
                                        </sdb-chipbar-extended>
                                    `
                                }

                                <div class="buttons"
                                    ?hidden=${!this.replaceEnabled}>
                                    <sdb-content-button
                                        id="rollback_button"
                                        label=${translate('rollback')}
                                        ?hidden=${!this.info.log?.currentReplacedTargetId || true}
                                        ?disabled=${this.isDistributing || this.replaceStatus?.overallStatus === 'inProgress'}
                                        secondary
                                        @click=${() => { this.rollbackDialog.open(); }}>
                                    </sdb-content-button>
                                    <sdb-content-button
                                        id="replace_button"
                                        label=${translate('replace')}
                                        ?disabled=${this.isDistributing || this.replaceStatus?.overallStatus === 'inProgress'}
                                        secondary
                                        @click=${() => { this.replaceDialog.open(); }}>
                                    </sdb-content-button>
                                </div>

                            </sdb-content-collapsablecard>

                        </div>

                        <div slot="right">
                            <sdb-content-collapsablecard
                                id="publications_card"
                                headline=${translate('headerPublications')}
                                icon="fal fa-bullhorn"
                                headlineSize="16px"
                                iconSize="32"
                                opened>
                                <sdb-content-list class="scroller">
                                    ${map(this.info.log?.publishRecords || [], (record) => html`
                                        <sdb-content-listitem
                                            headline=${moment(record.published.timestamp).format('lll')}
                                            subtitle=${record.published.name}
                                            noPadding
                                            noHover>
                                        </sdb-content-listitem>
                                    `)}
                                </sdb-content-list>
                            </sdb-content-collapsablecard>
                            <div><br><br></div>

                            <sdb-content-collapsablecard
                                id="distributions_card"
                                headline=${translate('headerDistributions')}
                                icon="fal fa-book-atlas"
                                headlineSize="16px"
                                iconSize="32"
                                ?hidden=${!this.info.log?.distributionRecords}
                                opened>
                                <sdb-content-list class="scroller">
                                    ${map(this.info.log?.distributionRecords || [], (record) => html`
                                        <sdb-content-listitem
                                            headline=${moment(record.distributed.timestamp).format('lll')}
                                            subtitle=${record.distributed.name}
                                            noPadding
                                            noHover>
                                        </sdb-content-listitem>
                                    `)}
                                </sdb-content-list>
                            </sdb-content-collapsablecard>
                        </div>
                    </sdb-layout-50-50>
                </div>

            </sdb-overlay-detail>

            <sdb-distribution-dialog
                id="distribute_dialog"
                .composition=${this.info.composition}>
            </sdb-distribution-dialog>

            <sdb-distribution-dialog
                id="replace_dialog"
                replace>
            </sdb-distribution-dialog>

            <sdb-dialog
                type="confirm"
                id="rollback_dialog"
                heading=${translate('rollbackHeading')}
                body=${translate('rollbackBody')}
                confirmCaption=${translate('rollback')}
                @confirm=${(e) => { this.rollbackReplace({
                    composition: this.info.composition,
                    portalIds: this.info.portals.map((p) => p.portalIdentifier),
                    replaceTargetId: this.info.log.currentReplacedTargetId,
                }) }}>
            </sdb-dialog>
        `;
    }

    disconnectedCallback() {
        super.disconnectedCallback();
        this.stopCatalogItemReplaceStatus();
    }
}

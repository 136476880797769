import { createSlice, isFulfilled } from '@reduxjs/toolkit';
import { startListening } from '../listenerMiddleware.js';

const routeSlice = createSlice({
    name: 'route',
    initialState: {
        path: null,
    },
    reducers: {
        navigate: (state, action) => {
            state.path = action.payload;
        },
        navigated: (state) => {
            state.path = null;
        },
    },
});

export const { navigate, navigated } = routeSlice.actions;

export default routeSlice.reducer;

startListening({
    matcher: isFulfilled,
    effect: async (action, { dispatch }) => {
        const { payload } = action;

        let path = null;
        switch (action.type) {
            case 'draft/create':
            case 'draft/duplicate':
                path = `draft/${payload.id}`;
                break;
            case 'lti/create':
                path = `ltiitem/${payload.targetId}`;
                break;
        }

        if (path) {
            dispatch(navigate({ path }));
        }
    },
});

//export const BATCHSIZE = 10;
export const DEFAULT_COPYOPTIONS = 240; // 11111000 => title/desc/summ/image off

export const copyOptionFlags =
{
        title: 1 << 0,
        description: 1 << 1,
        summary: 1 << 2,
        image: 1 << 3,
        categories: 1 << 4,
        documents: 1 << 5,
        evaluation: 1 << 6,
        certificate: 1 << 7,
}

export const filterValidPortalIds = (portalIds, validPortals) => {
        if (!portalIds)
                return [];

        const validPortalIds = validPortals.map(p => p.portalIdentifier);
        return portalIds.filter((p) => validPortalIds.includes(p))
};

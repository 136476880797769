/* eslint-disable indent */
import { html, css, LitElement } from 'lit';
import { customElement, query, state } from 'lit/decorators.js';
import connect from 'wc-context-redux/connect';
import { filterMicrolearnings } from '../../store/microlearning/microlearning.js';
import { getIcon } from '../../store/microlearning/helpers.js';
import { translate } from '../../helpers/translate.js';
import '@polymer/paper-input/paper-input.js';
import '@polymer/paper-listbox/paper-listbox.js';
import '@polymer/paper-item/paper-icon-item.js';
import '@polymer/paper-item/paper-item-body.js';
import '../shared/sdb-dialog.js';

const mapStateToProps = (state) => ({
    filteredList: state.microlearning.filteredList || [],
    searchText: state.microlearning.filter || '',
});

const mapDispatchToProps = (dispatch) => ({
    filter: (searchText) => dispatch(filterMicrolearnings({ searchText })),
});

@customElement('sdb-microlearning-select-dialog')
class SdbMicrolearningSelectDialog extends connect(mapStateToProps, mapDispatchToProps)(LitElement) {

    @state()
    accessor filteredList = [];

    @state()
    accessor searchText = '';

    @state()
    accessor selectedListItem = null;

    @state()
    accessor selectedValue = null;

    @query('#select_ml_dialog')
    accessor selectDialog;

    @query('#search')
    accessor searchInput;

    @query('#ml_list')
    accessor listbox;

    constructor() {
        super();
        this.sender = null;

        document.addEventListener('openMicrolearningSelectDialog', (e) => {
            this.sender = e.detail.sender;
            this.selectDialog.open();
        })
    }

    static get styles() {
        return css`

            .filter-bar sdb-search-input {
                margin-left: 20px;
                width: 90%;
            }

            .list {
                height: 550px;
                width: 900px;
                overflow-y: auto;
            }

            #ml_list {
                contain: none !important; /* lit-virtualizer hack */
            }

            sdb-content-listitem {
                width: 100%; /* lit-virtualizer screws this up */
            }

            sdb-content-listitem.selected {
                font-weight: bold;
                background: rgba(0, 0, 0, 0.1);
            }

            .results {
                margin-left: 20px;
                opacity: .8;
            }
        `;
    }

    render() {
        return html`
            <sdb-dialog
                type="form"
                id="select_ml_dialog"
                heading=${translate('chooseMicrolearning')}
                confirmCaption=${translate('select')}
                .confirmDisabled=${this.selectedListItem === null}
                @confirm=${this._confirm}
                @cancel=${this._reset}>
                <div id="filterbar">
                    <sdb-search-input
                        id="search"
                        placeholder=${translate('search')}
                        value=${this.searchText}
                        has-border
                        @search=${(e) => {
                            this.filter(e.detail.searchText);
                            this._reset();
                        }}>
                    </sdb-search-input>
                </div>
                <div class="results">${translate('numberResults')}: ${this.filteredList.length}</div>

                <div class="list">
                    ${!this.filteredList.length === 0
                        ? html`<sdb-empty-list label=${translate('noMicrolearningsFound')}></sdb-empty-list>`
                        : html`
                            <sdb-content-list id="ml_list">
                                <lit-virtualizer
                                    .items=${this.filteredList}
                                    .renderItem=${(item) => this._renderMicrolearningListItem(item)}
                                    .scrollTarget=${document}>
                                </lit-virtualizer>
                            </sdb-content-list>`
                    }
                </div>
            </sdb-dialog>
        `;
    }

    _renderMicrolearningListItem(item) {
        return html`
            <sdb-content-listitem
                icon=${getIcon(item.contentType)}
                headline=${item.name}
                subtitle=${item.contextName}
                @click=${(e) => { this._select(item, e.target) }}>
            </sdb-content-listitem>
        `;
    }

    _select(item, target) {
        if (this.selectedListItem === target) {
            this._reset();
            return;
        }

        if (this.selectedListItem) {
            this.selectedListItem.classList.remove('selected');
        }

        this.selectedListItem = target;
        this.selectedValue = item;

        this.selectedListItem.classList.add('selected');
    }

    _confirm() {
        this.dispatchEvent(new CustomEvent('microlearningSelected', {
            detail: {
                item: this.selectedValue,
                sender: this.sender,
            },
            composed: true,
            bubbles: true,
        }));

        this._reset();
    }

    _reset() {
        if (this.selectedListItem) {
            this.selectedListItem.classList.remove('selected');
        }

        this.selectedListItem = null;
        this.selectedValue = null;
    }
}


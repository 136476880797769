import { html, LitElement } from 'lit';
import { customElement } from 'lit/decorators.js';
import { store } from '../../store/store.js';
import { signedIn, signInError } from '../../store/auth/auth.js';
import { userManager } from '../../store/auth/userManager.js';

@customElement('sdb-authenticate-callback')
class SdbAuthenticateCallback extends LitElement {
    render() {
        return html`<div></div>`;
    }

    firstUpdated() {
        userManager.signinRedirectCallback()
            .then((result) => {
                this._redirect(result.state.url);
                store.dispatch(signedIn(store.getState().oidc.user));
            })
            .catch((error) => {
                store.dispatch(signInError({ error }));
                this._redirect('/');
            });
    }

    async _redirect(path) {
        this.dispatchEvent(new CustomEvent('redirect', {
            detail: {
                path: path,
            },
        }));
    }
}
import { createAsyncThunk, createSlice, isRejected } from '@reduxjs/toolkit';
import { startListening } from '../listenerMiddleware.js';
import { httpGet } from '../../helpers/http.js';
import ScormAPI12Adapter from '@competencegroup/tcg.scorm/dist/ScormAPI12Adapter';

export const initialize = createAsyncThunk(
    'scormPreview/initialize',
    async({ packageId }, { getState, dispatch }) => {
        const state = getState();
        const { settings } = state.env;
        const { user } = state.auth;

        const scorm = await httpGet(`${settings.scormPreviewEndpoint}${packageId}`, user);

        const scormApi = new ScormAPI12Adapter({
            debug: false,
            defaults: [
                { name: 'studentId', value: user.profile.idString },
                { name: 'studentName', value: user.profile.name },
                { name: 'lessonMode', value: 'browse' },
                { name: 'launchData', value: scorm.startData || '' },
            ],
        });

        scormApi.onLmsInitialize.on(() => dispatch(log({ command: 'initialize' })));
        scormApi.onLmsSetValue.on((args) => dispatch(log({ command: 'set', args })));
        scormApi.onLmsGetValue.on((args) => dispatch(log({ command: 'get', args })));
        scormApi.onLmsGetLastError.on(() => dispatch(log({ command: 'get-last-error' })));

        scormApi.onLmsCommit.on(() => {
            dispatch(updateResult());
            dispatch(log({ command: 'commit' }));
        });

        scormApi.onLmsFinish.on(() => {
            dispatch(updateResult());
            dispatch(finish());
            dispatch(log({ command: 'finish' }));
        });

        window.API = scormApi;

        // just strip protocol from startUrl and add to our scorm rewrite path
        const scormUrl = `scorm-az/${scorm.startUrl.replace(/(^\w+:|^)\/\//, '')}`;

        return {
            enabled: scorm.enabled,
            targetId: state.elearning.launchId, // use here cause we need it in our scorm context
            scormUrl,
            scormApi,
            scormResult : scorm.result,
        };
    },
);

const scormPreviewSlice = createSlice({
    name: 'scormPreview',
    initialState: {
        url: null,
        result: null,
        initialized: false,
        finished: false,
        log: [],
    },
    reducers: {
        updateResult: (state) => {
            state.result.cmiElements = window.API.scoData.cmiElements.map((el) => ({
                name: el.name,
                value: new String(el.value),
            }));
        },
        unload: (state) => { // triggered outside sco
            state.url = 'about:blank';
        },
        finish: (state) => { // triggered from sco
            state.finished =true;
            state.closed = true;
            state.url = 'about:blank';

            window.API = null;
        },
        log: (state, action) => {
            state.log.push(action.payload);
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(initialize.pending, (state) => {
                state.initialized = false;
                state.status = 'initializing';
                state.log = [];
            })
            .addCase(initialize.fulfilled, (state, action) => {
                const { payload } = action;

                state.status = 'success';
                state.initialized = true;
                state.finished = false;
                state.enabled = payload.enabled;
                state.targetId = payload.targetId;
                state.url = payload.scormUrl;
                state.result = payload.scormResult;
            })
            .addMatcher(isRejected(initialize), (state, action) => {
                state.status = 'failed';
                state.error = action.error;
            });
    },
});

export const { updateResult, unload, finish, log } = scormPreviewSlice.actions;

export default scormPreviewSlice.reducer;

startListening({
    actionCreator: unload,
    effect: async (_, { dispatch, getState, delay }) => {
        await delay(1000); // time to handle finishing by the sco

        const { scormPreview: state } = getState();
        if (!state.finished) { // if not finished, failsafe by imitating lms finish
            dispatch(updateResult());
            dispatch(finish());
        }
    },
});

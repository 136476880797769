import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { httpGet, PAGESIZE } from '../../helpers/http.js';

export const loadAccreditations = createAsyncThunk(
    'accreditation/fetch',
    async (args, { getState }) => {
        const { targetId } = args;
        const { env, auth, accreditation } = getState();
        const { settings } = env;

        const pageIndex = targetId === accreditation.targetId ? accreditation.pageIndex + 1 : 0;

        const params = new URLSearchParams({
            pageIndex: pageIndex,
            pageSize: PAGESIZE,
        });

        const response = await httpGet(`${settings.accreditationsEndpoint}catalogitem/${targetId}?${params}`, auth.user);

        return {
            targetId: targetId,
            list: response.results,
            pageIndex: pageIndex,
            total: response.count,
        };
    },
);

const accreditationSlice = createSlice({
    name: 'accreditation',
    initialState: {
        status: 'idle',
        targetId: null,
        idString: null,
        list: [],
        pageIndex: -1,
        error: null,
    },
    extraReducers: (builder) => {
        builder
            .addCase(loadAccreditations.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(loadAccreditations.fulfilled, (state, action) => {
                const { payload } = action;

                state.status = 'success';
                state.targetId = payload.targetId ?? null;
                state.list = payload.pageIndex === 0 ? payload.list : [...state.list, ...payload.list],
                state.pageIndex = payload.pageIndex;
                state.total = payload.total;
            })
            .addCase(loadAccreditations.rejected, (state, action) => {
                state.status = 'failed';
                state.error = action.error;
            });
    },
});

export default accreditationSlice.reducer;

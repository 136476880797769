/* eslint-disable indent */
import { html, css, LitElement } from 'lit';
import { customElement, state, query } from 'lit/decorators.js';
import connect from 'wc-context-redux/connect';
import sharedStyles from '../../app/styles.js';
import {
    loadScormItemsIfNeeded,
    publishScormItemAsComposition,
    unpublishScormItemAsComposition,
    createScormItem,
    deleteScormItem,
} from '../../store/scorm/scorm.js';
import { previewElearning } from '../../store/preview/preview.js';
import { navigate } from '../../store/route/route.js';
import { cancelBubble } from '../../helpers/events.js';
import { translate } from '../../helpers/translate.js';
import { renderTimestamp } from '../../helpers/controls.js';
import '@lit-labs/virtualizer';
import '@polymer/app-layout/app-layout.js';
import '@polymer/paper-listbox/paper-listbox.js';
import '@polymer/paper-item/paper-item.js';
import '@polymer/paper-menu-button/paper-menu-button.js';
import '@material/web/fab/fab.js';
import '@sdblearning/sdb-learning-frontend';
import '../shared/sdb-search-input.js';
import '../shared/sdb-loading.js';
import '../shared/sdb-empty-list.js';
import '../shared/sdb-dialog.js';
import './sdb-scorm-package-upload.js';

const mapStateToProps = (state) => ({
    scormItems: state.scorm.list || [],
    total: state.scorm.total,
    search: state.scorm.search,
    isLoadingFirstTime: state.scorm.status === 'loading' && state.scorm.page === -1,
    user: state.auth.user,
    portalId: state.env.portalIdentifier,
    hasGlobalId: state.env.hasGlobalId,
});

const mapDispatchToProps = (dispatch) => ({
    loadScormItemsIfNeeded: (args) => dispatch(loadScormItemsIfNeeded(args)),
    publishScormItemAsComposition: (item) => dispatch(publishScormItemAsComposition({ item })),
    unpublishScormItemAsComposition: (item) => dispatch(unpublishScormItemAsComposition({ item })),
    createScormItem: (name, scormPackage) => dispatch(createScormItem({ name, scormPackage })),
    deleteScormItem: (id) => dispatch(deleteScormItem({ id })),
    previewElearning: (item) => dispatch(previewElearning({ item })),
    navigate: (path) => dispatch(navigate(path)),
});

@customElement('sdb-scorm-item-list')
class SdbScormItemList extends connect(mapStateToProps, mapDispatchToProps)(LitElement) {

    @state()
    accessor scormItems = [];

    @state()
    accessor total;

    @state()
    accessor search;

    @state()
    accessor isLoadingFirstTime;

    @state()
    accessor user;

    @state()
    accessor args;

    @state()
    accessor newPackage = null;

    @state()
    accessor newName = null;

    @query('#scorm_uploader')
    accessor uploader;

    static styles = [
        sharedStyles,
        css`
            :host {
                max-width: var(--tcg-content-max-width);
                width: 100%;
            }

            .right-nav-bar {
                display:flex;
                align-items: center;
                justify-content: space-between;
            }

            sdb-search-input {
                flex-grow: 1;
            }

            sdb-content-iconbutton {
                margin-left: 10px;
            }

            #scorm_items_list {
                contain: none !important; /* lit-virtualizer hack */
            }

            sdb-content-listitem {
                width: 100%; /* lit-virtualizer screws this up */
            }

            paper-item {
                cursor: pointer;
            }

            paper-icon-button {
                color: var(--icon-button-color);
            }

            paper-listbox {
                min-width: 150px;
            }

            .no-results {
                width: 100%;
                max-width: var(--tcg-content-max-width);
                height: 100%;
                display: flex;
                flex-direction: column;
                align-items: center;
            }

            #no_results_img {
                width: 400px;
                height: 400px;
                background-color: var(--tcg-secondary-background-color);
            }
        `,
    ];

    render() {
        if (this.isLoadingFirstTime) {
            return html`<sdb-loading></sdb-loading>`;
        }

        return html`
            <sdb-layout-60-40>
                <sdb-nav-titlebar slot="left">
                    <span>${translate('headerScormItems')}</span>
                    <span ?hidden=${!this.total}>&nbsp;(${this.total})</span>
                </sdb-nav-titlebar>
                <div class="right-nav-bar" slot="right">
                    <sdb-search-input
                        placeholder=${translate('search')}
                        value=${this.search}
                        @search=${(e) => { this.loadScormItemsIfNeeded({ search: e.detail.searchText, reload: true }); }}>
                    </sdb-search-input>
                </div>
            </sdb-layout-60-40>
            <sdb-layout-100>
                ${!this.total
                    ? html`<sdb-empty-list label=${translate('noScormItemsFound')}></sdb-empty-list>`
                    : html`
                        <sdb-content-list separator>
                            <lit-virtualizer
                                id="scorm_items_list"
                                .items=${this.scormItems}
                                .renderItem=${(scormItem, index) => this._renderScormItemListItem(scormItem, index)}
                                .scrollTarget=${document}>
                            </lit-virtualizer>
                        </sdb-content-list>
                    `
                }

                <md-fab @click=${()=> { this._openDialog('add', null); }}>
                    <sdb-content-icon
                        slot="icon"
                        icon="fal fa-plus"
                        size="24">
                    </sdb-content-icon>
                </md-fab>
            </sdb-layout-100>

            <sdb-dialog
                type="confirm"
                id="publish_dialog"
                heading=${translate('publishHeadingScormItem')}
                body=${translate('publishBodyScormItem')}
                confirmCaption=${translate('publish')}
                @confirm=${(e) => { this.publishScormItemAsComposition(e.detail.args); }}>
            </sdb-dialog>

            <sdb-dialog
                type="confirm"
                id="unpublish_dialog"
                heading=${translate('unpublishHeadingScormItem')}
                body=${translate('unpublishBodyScormItem')}
                confirmCaption=${translate('unpublish')}
                @confirm=${(e) => { this.unpublishScormItemAsComposition(e.detail.args); }}>
            </sdb-dialog>

            <sdb-dialog
                type="confirm"
                id="delete_dialog"
                heading=${translate('deleteHeadingScormItem')}
                confirmCaption=${translate('delete')}
                @confirm=${(e) => { this.deleteScormItem(e.detail.args); }}>
            </sdb-dialog>

            <sdb-dialog
                type="form"
                id="add_dialog"
                heading=${translate('createHeadingScormItem')}
                confirmCaption=${translate('create')}
                ?confirmDisabled=${!this.newName || !this.newPackage}
                @confirm=${() => {
                    this.createScormItem(this.newName, this.newPackage );
                    this.newName = '';
                    this.uploader.clear();
                }}>
                <paper-input
                    id="new_name"
                    transparent
                    style="width: 700px;"
                    label=${translate('name')}
                    @value-changed=${(e) => { this.newName = e.target.value; }}>
                </paper-input>
                <sdb-scorm-package-upload
                    id="scorm_uploader"
                    @upload-completed=${(e) => {
                        this.newPackage = e.detail.package;
                    }}>
                </sdb-scorm-package-upload>

            </sdb-dialog>
        `;
    }

    firstUpdated() {
        this.dispatchEvent(
            new CustomEvent('fullscreen', {
                bubbles: true,
                composed: true,
                detail: {
                    fullscreen: false,
                },
            }),
        );
    }

    _renderScormItemListItem(scormItem, index) {
        this.loadScormItemsIfNeeded({ index });

        return html`
            <sdb-content-listitem
                icon="fal fa-square-s"
                .headline=${scormItem.title}
                .subtitle=${this._getSubtitle(scormItem)}
                @click=${()=> { this.navigate(`scormitem/${scormItem.targetId}`); }}>

                <sdb-content-chipbar slot="chipbar">
                    <sdb-content-chip label="catalogus" ?hidden=${scormItem.elearningType !== 0}></sdb-content-chip>
                </sdb-content-chipbar>

                <paper-menu-button
                    slot="secondaryAction"
                    horizontal-align="right"
                    vertical-align="bottom"
                    @click=${cancelBubble}>
                    <sdb-content-iconbutton icon="fal fa-ellipsis-vertical" slot="dropdown-trigger"></sdb-content-iconbutton>
                    <paper-listbox slot="dropdown-content">
                        <paper-item
                            ?hidden=${this.hasGlobalId}
                            ?disabled=${scormItem.elearningType === 0}
                            @click=${()=> { this._openDialog('publish', scormItem); }}>
                            ${translate('publish')}
                        </paper-item>
                        <paper-item
                            ?hidden=${this.hasGlobalId}
                            ?disabled=${scormItem.elearningType === 1}
                            @click=${()=> { this._openDialog('unpublish', scormItem); }}>
                            ${translate('unpublish')}
                        </paper-item>
                        <paper-item
                            @click=${()=> { this.previewElearning(scormItem); }}>
                            ${translate('preview')}
                        </paper-item>
                    </paper-listbox>
                </paper-menu-button>

            </sdb-content-listitem>
        `;
    }

    _getSubtitle(scormItem) {
        var s = `${translate('lastModified')}: ${renderTimestamp(scormItem.lastModified)}`;
        if (scormItem.supplier && scormItem.supplier.supplierKey) {
            s += ` | ${scormItem.supplier.name}`;
        }
        if (scormItem.description) {
            s += ` | ${scormItem.description}`;
        }
        return s;
    }

    _openDialog(action, args) {
        this.renderRoot.querySelector(`#${action}_dialog`).open(args);
    }
}


/* eslint-disable indent */
import { html, css, LitElement } from 'lit';
import { customElement, state, query } from 'lit/decorators.js';
import connect from 'wc-context-redux/connect';
import sharedStyles from '../../app/styles.js';
import { loadCompositionsIfNeeded } from '../../store/comp/comp.js';
import { navigate } from '../../store/route/route.js';
import { previewComposition } from '../../store/preview/player.js';
import { translate } from '../../helpers/translate.js';
import '@lit-labs/virtualizer';
import '@polymer/app-layout/app-layout.js';
import '@polymer/paper-listbox/paper-listbox.js';
import '@polymer/paper-item/paper-item.js';
import '@polymer/paper-menu-button/paper-menu-button.js';
import '@sdblearning/sdb-learning-frontend';
import '../shared/sdb-search-input.js';
import '../shared/sdb-loading.js';
import '../shared/sdb-empty-list.js';
import moment from 'moment/dist/moment.js';

const mapStateToProps = (state) => ({
    compositions: state.comp.list,
    total: state.comp.total,
    search: state.comp.search,
    isLoadingFirstTime: state.comp.status === 'loading' && state.comp.page === -1,
    user: state.auth.user,
});

const mapDispatchToProps = (dispatch) => ({
    loadCompositionsIfNeeded: (args) => dispatch(loadCompositionsIfNeeded(args)),
    navigate: (path) => dispatch(navigate(path)),
});

@customElement('sdb-composition-list')
class SdbCompositionList extends connect(mapStateToProps, mapDispatchToProps)(LitElement) {

    @state()
    accessor compositions;

    @state()
    accessor total;

    @state()
    accessor search;

    @state()
    accessor isLoadingFirstTime;

    @state()
    accessor user;

    @state()
    accessor selectedId;

    @query('#distribution_dialog')
    accessor distributionDialog;

    static styles = [
        sharedStyles,
        css`
            :host {
                max-width: var(--tcg-content-max-width);
                width: 100%;
            }

            #compositions_list {
                contain: none !important; /* lit-virtualizer hack */
            }

            sdb-content-listitem {
                width: 100%; /* lit-virtualizer screws this up */
            }

            paper-item {
                cursor: pointer;
            }

            paper-icon-button {
                color: var(--icon-button-color);
            }

            paper-listbox {
                min-width: 150px;
            }

            tcg-composition-publishinfo {
                margin-left: 66px;
            }

            .nowrap {
                white-space: nowrap;
            }
        `,
    ];

    render() {
        if (this.isLoadingFirstTime) {
            return html`<sdb-loading></sdb-loading>`;
        }

        return html`
            <sdb-layout-60-40>
                <sdb-nav-titlebar slot="left">
                    <span>${translate('headerCompositions')}</span>
                    <span ?hidden=${this.total === undefined}>&nbsp;(${this.total})</span>
                </sdb-nav-titlebar>
                <sdb-search-input
                    slot="right"
                    placeholder=${translate('search')}
                    value=${this.search}
                    @search=${(e) => { this.loadCompositionsIfNeeded({ search: e.detail.searchText, reload: true }); }}>
                </sdb-search-input>
            </sdb-layout-60-40>
            <sdb-layout-100>
                ${!this.total
                    ? html`<sdb-empty-list label=${translate('noCompositionsFound')}></sdb-empty-list>`
                    : html`
                        <sdb-content-list separator>
                            <lit-virtualizer
                                id="compositions_list"
                                .items=${this.compositions}
                                .renderItem=${(composition, index) => this._renderCompositionListItem(composition, index)}
                                .scrollTarget=${document}>
                            </lit-virtualizer>
                        </sdb-content-list>
                    `
                }
            </sdb-layout-100>
        `;
    }

    firstUpdated() {
        this.dispatchEvent(
            new CustomEvent('fullscreen', {
                bubbles: true,
                composed: true,
                detail: {
                    fullscreen: false,
                },
            }),
        );
    }

    _renderCompositionListItem(composition, index) {
        this.loadCompositionsIfNeeded({ index });

        return html`
            <sdb-content-listitem
                icon="fal fa-square-check"
                .headline=${composition.title}
                .subtitle="${translate('lastPublished')}: ${this._renderTimestamp(composition.lastModified)}"
                @click=${()=> { this.navigate(`composition/${composition.uniqueId}`); }}>

                <paper-menu-button
                    slot="secondaryAction"
                    horizontal-align="right"
                    vertical-align="bottom">
                    <sdb-content-iconbutton icon="fal fa-ellipsis-vertical" slot="dropdown-trigger"></sdb-content-iconbutton>
                    <paper-listbox slot="dropdown-content">
                        <paper-item @click=${() => {
                            this.navigate(`composition/${composition.uniqueId}/accreditationinfo`);
                        }}><span class="nowrap">${translate('accreditationinfo')}</span></paper-item>
                        <paper-item @click=${() => {
                            this.navigate(`composition/${composition.uniqueId}/accreditations`);
                        }}>${translate('accreditations')}</paper-item>
                        <paper-item @click=${()=> {
                            previewComposition(composition.id); }}
                        >${translate('preview')}</paper-item>
                    </paper-listbox>
                </paper-menu-button>

            </sdb-content-listitem>
        `;
    }

    _renderTimestamp(field) {
        return moment((field || {}).timestamp).fromNow();
    }
}


import { LitElement, html, css } from 'lit';
import { customElement, property, state } from 'lit/decorators.js';
import { choose } from 'lit/directives/choose.js';
import { classMap } from 'lit/directives/class-map.js';
import connect from 'wc-context-redux/connect';
import { userManager } from '../../store/auth/userManager.js';
import { closePreview } from '../../store/preview/preview.js';
import { launchTypes } from '../../store/elearning/helpers.js';
import '@sdblearning/sdb-os-player/sdb-os-player.js';
import '@sdblearning/sdb-learning-frontend';
import '@polymer/paper-ripple';
import '../scorm/sdb-scorm-preview-player.js';

const mapStateToProps = state => ({
    user: state.auth.user,
    opened: state.preview?.popupOpen === true,
    playerType: state.preview?.playerType,
    launchType: state.preview?.launchType,
    launchData: state.preview?.launchData,
    launchUrl: state.preview?.popupOpen === true ? state.preview.launchUrl : 'about:blank',
    autoClose: state.preview?.popupOpen === true ? !!state.preview.autoClose : true,
});

const mapDispatchToProps = dispatch => ({
    closePreview: () => dispatch(closePreview()),
});

@customElement('sdb-preview-dialog')
class SdbPreviewDialog extends connect(mapStateToProps, mapDispatchToProps)(LitElement) {

    @property({ type: Boolean })
    accessor transparent;

    @state()
    accessor user;

    @state()
    accessor opened;

    @state()
    accessor playerType;

    @state()
    accessor launchData;

    @state()
    accessor launchUrl;

    constructor() {
        super();

        window.addEventListener('message', msg => {
            switch (msg.data) {
                case 'close-preview':
                    this.closePreview();
                    break;
            }
        });
    }

    static get styles() {
        return css`
            paper-dialog {
                width: 90%;
                height: 90%;
            }

            paper-dialog.transparent {
                background: transparent !important;
                box-shadow: none !important;
            }

            paper-dialog.new-window {
                width: 40%;
                height: 30%;
            }

            iframe, sdb-os-player, sdb-scorm-preview-player {
                width: 100%;
                height: 100%;
                border: none;
                margin:0; /* override paper-dialog style */
                padding:0; /* override paper-dialog style */
            }

            div.close {
                position: absolute;
                width: 40px;
                height: 40px;
                right: -30px;
                top: -30px;
                background: white;
                border: 1px solid #ededed;
                border-radius: 50%;
                padding: 0;
                margin: 0;
                display: flex;
                align-items: center;
                justify-content: center;
                cursor: pointer;
                box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 1px 5px 0 rgba(0, 0, 0, 0.12), 0 3px 1px -2px rgba(0, 0, 0, 0.2);
            }

            iron-icon {
                --iron-icon-width: 24px;
                --iron-icon-height: 24px;
            }

            div.close[hidden] {
                display: none;
            }

            @media only screen and (max-width: 768px) {
                paper-dialog[autoclose] {
                    width: 100%;
                    min-width: 100%;
                    height: 100%;
                    min-height: 100%;
                }
            }
        `;
    }

    render() {
        return html`
            <paper-dialog
                id="preview_dialog"
                class=${classMap({
                    transparent: this.transparent,
                    'new-window': this.launchType === launchTypes.LAUNCHTYPE_NEWWINDOW,
                })}
                ?opened=${this.opened}
                ?autoclose=${this.autoClose}
                modal>
                ${choose(this.playerType, [
                        [0, this._renderIFrame],
                        [1, this._renderOsPlayer],
                        [2, this._renderScormPlayer],
                    ],
                    this._renderIFrame,
                )}
                <div
                    class="close"
                    @click=${this.closePreview}
                    ?hidden=${this.autoClose}>
                    <sdb-content-icon icon="fal fa-xmark"></sdb-content-icon>
                    <paper-ripple></paper-ripple>
                </div>
            </paper-dialog>
        `;
    }

    updated(changedProperties) {
        if(changedProperties.has('opened') && this.opened) {
            import('@polymer/paper-dialog').catch(console.error);
        }
    }

    _renderIFrame = () => html`
        <iframe
            id="lti_player"
            src=${this.launchUrl}
            allowfullscreen="true"
            webkitallowfullscreen="true"
            mozallowfullscreen="true">
        </iframe>
    `;

    _renderOsPlayer = () => html`
        <sdb-os-player
            id="os_player"
            .user=${this.user}
            .settings=${userManager._settings}
            referenceId=${this.launchData?.referenceId}
            elearningId=${this.launchData?.elearningId}
            environment=${this.launchData?.environment}
            @playerExit=${this.closePreview}
            browse>
        </sdb-os-player>
    `;

    _renderScormPlayer = () => html`
        <sdb-scorm-preview-player
            id="scorm_player"
            packageId=${this.launchData?.packageId}
            sessionId=${this.launchData?.referenceId}
            launchType=${this.launchType}
            @finish=${this.closePreview}>
        </sdb-scorm-preview--player>
    `;
}

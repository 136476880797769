import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { httpGet, httpPost, httpPut, httpDelete, PAGESIZE } from '../../helpers/http.js';
import { publishAsComposition, unpublishAsComposition } from '../elearning/elearning.js';
import { assignAndIsDirty } from '../../helpers/extend.js';

export const loadLtiItemsIfNeeded = createAsyncThunk(
    'lti/fetch',
    async (args, { getState }) => {
        const { lti: state, env, auth } = getState();

        const portalId = env?.portalIdentifier || '';
        const newPage = args.reload ? 0 : state.page + 1;
        const params = new URLSearchParams({
            player: 'lti1',
            page: newPage,
            pageSize: PAGESIZE,
        });

        const search = args.search || args.search === '' ? args.search : state.search;
        if (search) {
            params.set('search', search);
        }

        const response = await httpGet(`${env.settings.elearningItemsEndpoint}${portalId}?${params}`, auth.user);

        return {
            list: response.data,
            page: newPage,
            total: response.total,
            reload: args.reload,
            search: search,
        };
    },
    {
        condition: (args, { getState }) => {
            const { lti: state } = getState();

            if (!args.reload && (args.index < state.list.length - 1 || args.index >= state.total - 1)) {
                return false;
            }
        },
    },
);

export const loadLtiItem = createAsyncThunk(
    'lti/fetchOne',
    async (args, { getState }) => {
        const { uniqueId } = args;
        const { env, auth } = getState();

        return await httpGet(`${env.settings.getLtiItemEndpoint}${uniqueId}`, auth.user);
    },
    {
        condition: (args, { getState }) => {
            const { lti: state } = getState();
            if (args.uniqueId === state.current?.id) {
                return false;
            }
        },
    },
);

export const publishLtiItemAsComposition = createAsyncThunk(
    'lti/publish',
    publishAsComposition,
);

export const unpublishLtiItemAsComposition = createAsyncThunk(
    'lti/unpublish',
    unpublishAsComposition,
);

export const createLtiItem = createAsyncThunk(
    'lti/create',
    async (args, { getState }) => {
        const { env, auth } = getState();
        const portalId = env?.portalIdentifier || '';

        const ltiItem = await httpPost(`${env.settings.ltiItemEndpoint}`, auth.user, {
            clientName: portalId,
            ...args,
        });

        await httpPost(`${env.settings.packageEndpoint}LTI1`, auth.user, {
            portalId: portalId,
            supplierKey: ltiItem.type,
            supplierName: ltiItem.type,
            publisherIdString: auth.user.profile.sub,
            publisherName: auth.user.profile.name,
            publisherIdString: auth.user.profile.sub,
            publisherName: auth.user.profile.name,
            items: [
                {
                    targetId: ltiItem.uniqueId,
                    title: ltiItem.name,
                    type: 'microlearning',
                    startUrl: env.settings.ltiStartUrl,
                    startUrl: env.settings.ltiStartUrl,
                }
            ],
            updateMode: 0, // 'update'
        });

        return {
            targetId: ltiItem.uniqueId,
            title: ltiItem.name,
            description: null,
            playerType: 0,
            elearningType: 1, // microlearning
            lastModified: new Date(),
            supplier: {
                supplierKey: null,
                name: null
            },
        };
    },
);

export const deleteLtiItem = createAsyncThunk(
    'lti/delete',
    async (args, { getState }) => {
        const { ltiItemId } = args;
        const { env, auth } = getState();

        await httpDelete(`${env.settings.ltiItemEndpoint}${ltiItemId}`, auth.user);
        return {
            id: ltiItemId,
        };
    },
);

export const saveLtiItem = createAsyncThunk(
    'lti/save',
    async (args, { getState }) => {
        const { lti: state, env, auth } = getState();
        const { current: ltiItem } = state;

        await httpPut(`${env.settings.ltiItemEndpoint}`, auth.user, ltiItem);

        return httpPost(`${env.settings.packageEndpoint}LTI1`, auth.user, {
            portalId: env.portalIdentifier,
            supplierKey: ltiItem.type,
            supplierName: ltiItem.type,
            publisherIdString: auth.user.profile.sub,
            publisherName: auth.user.profile.name,
            items: [
                {
                    targetId: ltiItem.uniqueId,
                    title: ltiItem.name,
                    description: ltiItem.description,
                    startUrl: env.settings.ltiStartUrl,
                }
            ],
            updateMode: 0, // 'update'
        });
    },
);

export const loadSuppliers = createAsyncThunk(
    'lti/fetchSuppliers',
    async (_, { getState }) => {
        const { env, auth } = getState();
        const portalId = env?.portalIdentifier || '';

        const response =  await httpGet(`${env.settings.ltiSuppliersEndpoint}${portalId}`, auth.user);
        return Object.fromEntries(Object.entries(response.defaultParameters || {})
                     .filter(([_, value]) => value.useAsPreset));
    },
    {
        condition: (args, { getState }) => {
            const { lti: state } = getState();
            if (state.suppliers.length > 0) {
                return false;
            }
        },
    },
);

export const loadKeySecrets = createAsyncThunk(
    'lti/fetchKeySecrets',
    async (_, { getState }) => {
        const { env, auth } = getState();
        const portalId = env?.portalIdentifier || '';

        const response = await httpGet(`${env.settings.keySecretsEndpoint}${portalId}`, auth.user);
        return response.filter((ks) => !!ks.sharedSecret || !!ks.consumerKey);
    },
    {
        condition: (_, { getState }) => {
            const { lti: state } = getState();
            if (state.keys.length > 0) {
                return false;
            }
        },
    },
);

const ltiSlice = createSlice({
    name: 'lti',
    initialState: {
        status: 'idle',
        list: [],
        page: -1,
        suppliers: [],
        keys: [],
        error: null
    },
    reducers: {
        updateLtiItem: (state, action) => {
            const { payload } = action;

            if (assignAndIsDirty(state.current, payload.changes)) {
                state.dirty = true;
            }
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(loadLtiItemsIfNeeded.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(loadLtiItemsIfNeeded.fulfilled, (state, action) => {
                const { payload } = action;

                state.status = 'success';
                state.list = payload.reload ? [...payload.list] : [...state.list, ...payload.list];
                state.page = payload.page;
                state.total = payload.total;
                state.search = payload.search;
            })
            .addCase(loadLtiItemsIfNeeded.rejected, (state, action) => {
                state.status = 'failed';
                state.error = action.error;
            })
            .addCase(loadLtiItem.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(loadLtiItem.fulfilled, (state, action) => {
                const { payload } = action;

                state.status = 'success';
                state.current = payload;
                state.dirty = false;
            })
            .addCase(loadLtiItem.rejected, (state, action) => {
                state.status = 'failed';
                state.error = action.error;
            })
            .addCase(publishLtiItemAsComposition.pending, (state) => {
                state.status = 'publishing';
            })
            .addCase(publishLtiItemAsComposition.fulfilled, (state, action) => {
                const { payload } = action;
                let ltiItem = state.list.find((i) => i.targetId === payload.targetId);

                state.status = 'success';
                ltiItem = Object.assign(ltiItem, payload);
            })
            .addCase(publishLtiItemAsComposition.rejected, (state, action) => {
                state.status = 'failed';
                state.error = action.error;
            })
            .addCase(unpublishLtiItemAsComposition.pending, (state) => {
                state.status = 'unpublishing';
            })
            .addCase(unpublishLtiItemAsComposition.fulfilled, (state, action) => {
                const { payload } = action;
                let ltiItem = state.list.find((i) => i.targetId === payload.targetId);

                state.status = 'success';
                ltiItem = Object.assign(ltiItem, payload);
            })
            .addCase(unpublishLtiItemAsComposition.rejected, (state, action) => {
                state.status = 'failed';
                state.error = action.error;
            })
            .addCase(createLtiItem.pending, (state) => {
                state.status = 'creating';
            })
            .addCase(createLtiItem.fulfilled, (state, action) => {
                const { payload } = action;

                state.status = 'success';
                state.list.unshift(payload);
                state.total++;
            })
            .addCase(createLtiItem.rejected, (state, action) => {
                state.status = 'failed';
                state.error = action.error;
            })
            .addCase(deleteLtiItem.pending, (state) => {
                state.status = 'deleting';
            })
            .addCase(deleteLtiItem.fulfilled, (state, action) => {
                const { payload } = action;

                state.status = 'success';
                state.list = state.list.filter((i) => i.targetId !== payload.targetId);
                state.total--;
            })
            .addCase(deleteLtiItem.rejected, (state, action) => {
                state.status = 'failed';
                state.error = action.error;
            })
            .addCase(saveLtiItem.pending, (state) => {
                state.status = 'saving';
            })
            .addCase(saveLtiItem.fulfilled, (state) => {
                state.status = 'success';
                state.dirty = false;
            })
            .addCase(saveLtiItem.rejected, (state, action) => {
                state.status = 'failed';
                state.error = action.error;
            })
            .addCase(loadSuppliers.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(loadSuppliers.fulfilled, (state, action) => {
                const { payload } = action;

                state.status = 'success';
                state.suppliers = payload;
            })
            .addCase(loadSuppliers.rejected, (state, action) => {
                state.status = 'failed';
                state.error = action.error;
            })
            .addCase(loadKeySecrets.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(loadKeySecrets.fulfilled, (state, action) => {
                const { payload } = action;

                state.status = 'success';
                state.keys = payload;
            })
            .addCase(loadKeySecrets.rejected, (state, action) => {
                state.status = 'failed';
                state.error = action.error;
            });
    },
});

export const { updateLtiItem } = ltiSlice.actions;

export default ltiSlice.reducer;

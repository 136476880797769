import { html, css, LitElement } from 'lit';
import { customElement, property } from 'lit/decorators.js';
import sharedStyles from '../../app/styles.js';

@customElement('sdb-empty-list')
class SdbEmptyList extends LitElement {

    @property({ type: String})
    accessor label = '' ;

    static get styles() {
        return [
            sharedStyles,
            css`
                :host {
                    width: 100%;
                    max-width: var(--tcg-content-max-width);
                    height: 100%;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                }

                .container {
                    margin-top: -70px;
                }

                .image {
                    width: 400px;
                    background-color: var(--tcg-secondary-background-color);
                }

                .title {
                    text-align: center;
                }
            `,
        ];
    }

    render() {
        return html`
            <div class="container">
                <img class="image" src="/images/default-empty-state.svg">
                <div class="title titleText">${this.label}</div>
            </div>
        `;
    }
}



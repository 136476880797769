import { html, css, LitElement } from 'lit';
import { customElement, property } from 'lit/decorators.js';
import sharedStyles from '../../app/styles.js';
import '@sdblearning/sdb-learning-frontend';

@customElement('sdb-overlay-detail')
class SdbOverlayDetail extends LitElement {

    @property({type: String})
    accessor headline = '';

    static get styles() {
        return [
            sharedStyles,
            css`
                div.overlay {
                    position: relative;
                }

                div.overlay-banner {
                    position: fixed;
                    animation: slideDown 0.3s;
                    top: 0;
                    left: 0;
                    background: #fff;
                    border-bottom: 1px solid rgb(219, 219, 219);
                    width: 100%;
                    height: 56px;
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                    z-index: 1;
                }

                div.overlay-banner-title {
                    display: inline-block;
                    margin-left: 20px;
                }

                div.overlay-banner-text {
                    display: inline-block;
                    color: var(--text-primary);
                    font-family: 'Source Sans Pro', sans-serif;
                    font-size: 16px;
                    -webkit-font-smoothing: antialiased;
                    vertical-align: middle;
                }

                div.overlay-banner-buttons {
                    display: inline-block;
                    margin-right: 20px;
                }

                div.animation {
                    position: relative;
                    animation: zoomIn 0.3s;
                    width: 100%;
                    min-height: 700px;
                }

                div.overlay-content {
                    position: absolute;
                    top: 57px;
                    left: 0;
                    width: 100%;
                    background: #f7f7f7;
                    overflow-y: auto;
                    overflow-x: hidden;
                    display: flex;
                    align-items: stretch;
                    justify-content: center;
                }

                @keyframes zoomIn {
                    0% {
                        transform: scale(0);
                    }
                    100% {
                        transform: scale(1);
                    }
                }

                @keyframes slideDown {
                    0% {
                        top: -56px;
                    }
                    100% {
                        top: 0;
                    }
                }
            `,
        ];
    }

    render() {
        return html`
            <div class="overlay">
                <div class="overlay-banner">
                    <div class="overlay-banner-title">
                        <sdb-content-iconbutton
                            icon="fal fa-arrow-left"
                            @click=${this._click}>
                        </sdb-content-iconbutton>
                        <div class="overlay-banner-text">${this.headline}</div>
                    </div>
                    <div class="overlay-banner-buttons">
                        <slot name="buttons"></slot>
                    </div>
                </div>
                <div class="animation">
                    <div class="overlay-content">
                        <slot name="content"></slot>
                    </div>
                </div>
            </div>
        `;
    }

    firstUpdated() {
        this.dispatchEvent(
            new CustomEvent('fullscreen', {
                bubbles: true,
                composed: true,
                detail: {
                    fullscreen: true,
                },
            })
        );
    }

    _click(e) {
        const { target } = e;

        this.dispatchEvent(
            new CustomEvent('back', {
                bubbles: false, // dont bubble
                composed: false,
                detail: {
                    target,
                },
            })
        );
    }

}

/* eslint-disable indent */
import { html, css, LitElement } from 'lit';
import { customElement, property, query, state } from 'lit/decorators.js';
import sharedStyles from '../../app/styles.js';
import { translate } from '../../helpers/translate.js';
import '@polymer/paper-input/paper-input.js';

@customElement('sdb-parameters')
class SdbParameters extends LitElement {

    @property({ type: Object })
    accessor parameters = {};

    @state()
    accessor addDisabled = true;

    @query('#new_key_input')
    accessor newKey;

    static styles = [
        sharedStyles,
        css`
            .item, .new-item {
                margin-top: 20px;
                display: flex;
                flex-direction: row;
                align-items: center;
            }

            .item paper-input {
                width: 100%;
                margin-right: 20px;
            }

            .new-item paper-input {
                min-width: 300px;
                margin-right: 20px;
            }
        `,
    ];

    render() {
        return html`
            ${Object.entries(this.parameters || {}).map(([key, value]) => html`
                <div class="item">
                    <paper-input
                        id="${key.replace(' ', '_')}_input"
                        transparent
                        label=${key}
                        value=${value}
                        @change=${(e) => this._update(key, e.target.value)}>
                    </paper-input>
                    <sdb-content-iconbutton
                        icon="fal fa-trash-can"
                        @click=${() => this._remove(key)}>
                    </sdb-content-iconbutton>
                </div>
            `)}
            <div class="new-item">
                <paper-input
                    id="new_key_input"
                    transparent
                    label=${translate('newKey')}
                    @value-changed=${(e) => this._validate(e.target.value)}>
                </paper-input>
                <sdb-content-button
                    id="new_key_button"
                    label=${translate('add')}
                    secondary
                    ?disabled=${this.addDisabled}
                    @click=${(e) => { this._add(this.newKey.value) }}>
                </sdb-content-button>
            </div>
        `;
    }

    _validate(value) {
        this.addDisabled = !value;
    }

    _add(key) {
        this.parameters = {...this.parameters};
        this.parameters[key] = '';
        this.newKey.value = '';
        this._changed();
    }

    _update(key, value) {
        this.parameters = {...this.parameters};
        this.parameters[key] = value;
        this._changed();
    }

    _remove(key) {
        this.parameters = {...this.parameters};
        delete this.parameters[key];
        this._changed();
    }

    _changed() {
        //this.parameters = { ...this.parameters }; // trigger observer
        this.dispatchEvent(
            new CustomEvent('changed', {
                bubbles: true,
                composed: true,
                detail: {
                    value: this.parameters,
                },
            }),
        );
    }
}


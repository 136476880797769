import { html, css, LitElement } from 'lit';
import { customElement, property, state, query } from 'lit/decorators.js';
import connect from 'wc-context-redux/connect';
import sharedStyles from '../../app/styles.js';
import { getAuthorizationHeadersOnly } from '../../helpers/http.js';
import { translate } from '../../helpers/translate.js';
import { getIcon } from '../../store/draft/types.js';
import '@sdblearning/sdb-learning-frontend'
import './sdb-upload.js';

const mapStateToProps = (state) => ({
    user: state.auth.user,
    settings: state.env.settings,
});

const mapDispatchToProps = () => ({});

@customElement('sdb-image-editor')
class SdbImageEditor extends connect(mapStateToProps, mapDispatchToProps)(LitElement) {

    @property({ type: String})
    accessor image;

    @property({ type: String})
    accessor draftId;

    @property({ type: String})
    accessor iconType;

    @state()
    accessor imageUrl;

    @query('#image_dialog')
    accessor imageDialog;

    @query('#image_url_input')
    accessor imageUrlInput;

    get imageUrl() {
        if (!this.image) {
            return false;
        }

        if (this.image.indexOf('http') === 0) {
            return this.image;
        }

        return `${this.settings.imageEndpoint}${this.draftId}/${this.image}`;
    }

    static get styles() {
        return [
            sharedStyles,
            css`
                :host {
                }

                .sdb-image-editor {
                    display: flex;
                    align-items: flex-end;
                    background-color: #eee;
                }

                img {
                    width: 330px;
                    height: 194px;
                }

                .icon-fallback {
                    width: 330px;
                    height: 194px;
                }

                .icon-fallback:not([hidden]) {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                }

                iron-icon.fallback-icon {
                    color: rgba(0, 0, 0, .7);
                }

                .icon-overlay {
                    position: absolute;
                    background-color: rgba(0, 0, 0, .3);
                    display: flex;
                    align-self: flex-end;
                    height: 41px;
                    width: 330px;
                }

                .photo-icons {
                    color: rgba(0, 0, 0, .7);
                    position: relative;
                    cursor: pointer;
                }

                .center-icon {
                    display: flex;
                    justify-content: center;
                }

                .icon-padding {
                    padding-top: 10px;
                }

                #image_url_input {
                    width: 700px;
                }
            `,
        ];
    }

    render() {
        return html`
            <div class="sdb-image-editor">
                ${this.imageUrl ? html`
                    <img ?hidden=${!this.imageUrl} src=${this.imageUrl}>
                ` : html`
                    <div class="icon-fallback" ?hidden=${this.image}>
                        <sdb-content-icon icon=${getIcon(this.iconType)} size="96"></sdb-content-icon>
                    </div>
                `}
                <div class="icon-overlay center-icon">
                    <sdb-content-icon
                        class="photo-icons icon-padding"
                        icon="fal fa-camera"
                        @click=${() => { this.imageDialog.open(); }}
                    ></sdb-content-icon>
                </div>
            </div>

            <sdb-dialog
                type="form"
                id="image_dialog"
                heading=${translate('urlHeading')}
                confirmCaption=${translate('ok')}
                @confirm=${this._confirm}>

                <paper-input
                    id="image_url_input"
                    label=${translate('url')}
                    value=${this.image || ''}>
                </paper-input>

                <sdb-upload
                    id="uploader"
                    method="PUT"
                    max-files="1"
                    form-data-name="files"
                    accept=".png, .svg, .jpg, .jpeg, .gif, .tif, .tiff"
                    target=${this.settings.imageEndpoint}${this.draftId}
                    .headers=${getAuthorizationHeadersOnly(this.user)}
                    @upload-success=${(e) => { this.imageUrlInput.value = `${e.detail.file.name}`; }}
                    @upload-error=${(e) => console.error(e)}>
                </sdb-upload>

            </sdb-dialog>
        `;
    }

    _confirm() {
        this.dispatchEvent(new CustomEvent('change', {
            bubbles: true,
            composed: true,
            detail: {
                value: this.imageUrlInput.value,
            },
        }));
    }
}



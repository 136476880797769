import { html, css, LitElement } from 'lit';
import { customElement, property, state, query } from 'lit/decorators.js';
import { classMap } from 'lit/directives/class-map.js';
import { getLanguage } from '../../helpers/translate.js';
import '@competencegroup/wysiwyg-e/wysiwyg-e.js';
import '@competencegroup/wysiwyg-e/tools/bold.js';
import '@competencegroup/wysiwyg-e/tools/italic.js';
import '@competencegroup/wysiwyg-e/tools/underline.js';
import '@competencegroup/wysiwyg-e/tools/strike.js';
import '@competencegroup/wysiwyg-e/tools/color.js';
import '@competencegroup/wysiwyg-e/tools/clear.js';
import '@competencegroup/wysiwyg-e/tools/code.js';
import '@competencegroup/wysiwyg-e/tools/link.js';
import '@competencegroup/wysiwyg-e/tools/image.js';
import '@competencegroup/wysiwyg-e/tools/audio.js';
import '@competencegroup/wysiwyg-e/tools/video.js';
import '@competencegroup/wysiwyg-e/tools/ordered.js';
import '@competencegroup/wysiwyg-e/tools/unordered.js';
import '@competencegroup/wysiwyg-e/tools/indent.js';
import '@competencegroup/wysiwyg-e/tools/outdent.js';
import '@competencegroup/wysiwyg-e/tools/justify.js';
import '@competencegroup/wysiwyg-e/tools/heading.js';
import '@competencegroup/wysiwyg-e/tools/blockquote.js';

const EMPTY = '<p><br></p>';

const LOCALES = {
    'en': {
        'Bold': 'Bold',
        'Italic': 'Italic',
        'Justify': 'Justify',
        'Underline': 'Underline',
        'Strikethrough': 'Strikethrough',
        'Code': 'Code',
        'Link': 'Link',
        'Image': 'Image',
        'Ordered List': 'Ordered List',
        'Unordered List': 'Unordered List',
        'Indent': 'Indent',
        'Outdent': 'Outdent',
        'Heading': 'Headings',
        'Paragraph': 'Paragraph',
        'Undo': 'Undo',
        'Redo': 'Redo',
        'Scroll Previous': 'Scroll Previous',
        'Scroll Next': 'Scroll Next'
    },
    'nl': {
        'Bold': 'Vet',
        'Italic': 'Cursief',
        'Justify': 'Uitvullen',
        'Underline': 'Onderstrepen',
        'Strikethrough': 'Doorhalen',
        'Code': 'Code',
        'Image': 'Afbeelding',
        'Link': 'Hyperlink',
        'Ordered List': 'Nummering',
        'Unordered List': 'Opsommingsteken',
        'Indent': 'Inspringing vergroten',
        'Outdent': 'Inspringing verkleinen',
        'Headings': 'Kopteksten',
        'Paragraph': 'Paragraaf',
        'Undo': 'Ongedaan maken',
        'Redo': 'Opnieuw doen',
        'Scroll Previous': 'Scroll naar vorige',
        'Scroll Next': 'Scroll naar volgende'
    },
};

@customElement('sdb-rich-text-editor')
class SdbRichTextEditor extends LitElement {

    @property({ type: String })
    accessor value = null;

    @property({ type: String })
    accessor label = null;

    @property({ type: String })
    accessor placeholder;

    @query('#editor_label')
    accessor editorLabel;

    @query('#editor')
    accessor editor;

    constructor() {
        super();
        this.value = null;
        this.language = getLanguage();
    }

    static styles = css`
        :host {
            padding-top: 10px;
            width: 100%;
            min-height: 400px; /* TODO: try to make height flexible */
            display: flex;
            flex-direction: column;
        }

        label {
            font-family: 'Roboto', 'sans-serif';
            font-size: 12px;
            margin-bottom: 5px;
            color: rgb(115, 115, 115);
            text-wrap: nowrap;
            white-space-collapse: collapse;
        }

        label.has-focus {
            color: rgb(88, 97, 149);
        }

        wysiwyg-e {
            flex-grow: 1;
            border: 1px solid #999;
            --wysiwyg-toolbar-background: #999;
            --mdc-theme-text-disabled-on-light: rgba(255, 255, 255, 0.7);
        }

        wysiwyg-e.has-focus {
            border: 1px solid rgb(88, 97, 149);
            --wysiwyg-toolbar-background: var(--text-primary);
        }

    `;

    render() {
        return html`
            ${this.label ? html`<label id="editor_label">${this.label}</label>` : nothing}
            <wysiwyg-e
                id="editor"
                value=${this.value}
                language=${this.language}
                .resources=${LOCALES}
                placeholder=${this.placeholder}>

                <wysiwyg-tool-bold
                    language=${this.language}
                    .resources=${LOCALES}>
                </wysiwyg-tool-bold>

                <wysiwyg-tool-italic
                    language=${this.language}
                    .resources=${LOCALES}>
                </wysiwyg-tool-italic>

                <wysiwyg-tool-underline
                    language=${this.language}
                    .resources=${LOCALES}>
                </wysiwyg-tool-underline>

                <wysiwyg-tool-strike
                    language=${this.language}
                    .resources=${LOCALES}>
                </wysiwyg-tool-strike>

                <wysiwyg-tool-code
                    language=${this.language}
                    .resources=${LOCALES}>
                </wysiwyg-tool-code>

                <wysiwyg-tool-link
                    language=${this.language}
                    .resources=${LOCALES}>
                </wysiwyg-tool-link>

                <wysiwyg-tool-image
                    language=${this.language}
                    .resources=${LOCALES}>
                </wysiwyg-tool-image>

                <wysiwyg-tool-ordered
                    language=${this.language}
                    .resources=${LOCALES}>
                </wysiwyg-tool-ordered>

                <wysiwyg-tool-unordered
                    language=${this.language}
                    .resources=${LOCALES}>
                </wysiwyg-tool-unordered>

                <wysiwyg-tool-indent
                    language=${this.language}
                    .resources=${LOCALES}>
                </wysiwyg-tool-indent>

                <wysiwyg-tool-outdent
                    language=${this.language}
                    .resources=${LOCALES}>
                </wysiwyg-tool-outdent>

                <wysiwyg-tool-justify
                    right=""
                    center=""
                    full=""
                    language=${this.language}
                    .resources=${LOCALES}>
                </wysiwyg-tool-justify>

                <wysiwyg-tool-heading
                    h1 h2 h3 h4 h5 h6
                    language=${this.language}
                    .resources=${LOCALES}>
                </wysiwyg-tool-heading>
            </wysiwyg-e>
        `;
    }

    firstUpdated() {
        setInterval(() => {
            const newValue = this.editor.value?.trim() === EMPTY ? '' : this.editor.value;

            if (newValue !== this.value) {
                this.dispatchEvent(
                    new CustomEvent('change', {
                        bubble: true,
                        composed: true,
                        detail: {
                            value: newValue,
                            oldValue: this.value
                        },
                    })
                );

                this.value = newValue;
            }
        }, 300);

        this.editor.addEventListener('focus', (e) => {
            e.target.classList.add('has-focus');
            if (this.editorLabel) {
                this.editorLabel.classList.add('has-focus');
            }
        });
        this.editor.addEventListener('blur', (e) => {
            e.target.classList.remove('has-focus');
            if (this.editorLabel) {
                this.editorLabel.classList.remove('has-focus');
            }
        });
    }
}

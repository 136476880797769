import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { startListening } from '../listenerMiddleware.js';
import { v4 as uuidv4 } from 'uuid';
import { httpGet, httpPost, httpDelete, PAGESIZE } from '../../helpers/http.js';
import { publishAsComposition, unpublishAsComposition } from '../elearning/elearning.js';
import { assignAndIsDirty } from '../../helpers/extend.js';

export const loadScormItemsIfNeeded = createAsyncThunk(
    'scorm/fetch',
    async (args, { getState }) => {
        const { scorm: state, env, auth } = getState();
        const { search, reload } = args;

        const portalId = env?.portalIdentifier || '';
        const newPage = reload ? 0 : state.page + 1;
        const params = new URLSearchParams({
            player: 'scorm12',
            page: newPage,
            pageSize: PAGESIZE,
        });

        const searchFor = search || search === '' ? search : state.search;
        if (searchFor) {
            params.set('search', searchFor);
        }

        const response = await httpGet(`${env.settings.elearningItemsEndpoint}${portalId}?${params}`, auth.user);

        return {
            list: response.data,
            page: newPage,
            total: response.total,
            reload: reload,
            search: searchFor,
        };
    },
    {
        condition: (args, { getState }) => {
            const { scorm: state } = getState();

            if (!args.reload && (args.index < state.list.length - 1 || args.index >= state.total - 1)) {
                return false;
            }
        },
    },
);

export const loadScormItem = createAsyncThunk(
    'scorm/fetchItem',
    async (args, { getState }) => {
        const { targetId } = args;
        const { env, auth } = getState();

        return await httpGet(`${env.settings.elearningItemsEndpoint}${targetId}`, auth.user);
    },
    {
        condition: (args, { getState }) => {
            const { scorm: state } = getState();
            if (args.targetId === state.current?.id) {
                return false;
            }
        },
    },
);

export const publishScormItemAsComposition = createAsyncThunk(
    'scorm/publish',
    publishAsComposition,
);

export const unpublishScormItemAsComposition = createAsyncThunk(
    'scorm/unpublish',
    unpublishAsComposition,
);

export const createScormItem = createAsyncThunk(
    'scorm/create',
    async (args, { getState }) => {
        const { name, scormPackage } = args;
        const { env, auth } = getState();
        const newTargetId = uuidv4();

        await httpPost(`${env.settings.packageEndpoint}scorm12`, auth.user, {
            portalId: env.portalIdentifier,
            publisherIdString: auth.user.profile.sub,
            publisherName: auth.user.profile.name,
            items: [
                {
                    targetId: newTargetId,
                    title: name,
                    type: 'microlearning',
                    startData: {
                        packageId: scormPackage.info?.packageId,
                        timestamp: scormPackage.info?.uploadDate,
                        name: scormPackage.info?.name,
                    },
                }
            ],
            updateMode: 1, // 'new version'
        });

        return {
            targetId: newTargetId,
            title: name,
            description: null,
            playerType: 2, // scorm
            elearningType: 1, // microlearning
            lastModified: new Date(),
            supplier: {
                supplierKey: null,
                name: null
            },
        };
    },
);

export const deleteScormItem = createAsyncThunk(
    'scorm/delete',
    async (args, { getState }) => {
        const { scormItemId } = args;
        const { env, auth } = getState();

        await httpDelete(`${env.settings.scormItemEndpoint}${scormItemId}`, auth.user);
        return {
            id: scormItemId,
        };
    },
);

export const saveScormItem = createAsyncThunk(
    'scorm/save',
    async (_, { getState }) => {
        const { scorm: state, env, auth } = getState();
        const { current: scormItem } = state;
        const { targetId, title, description, supplier, launchType, scormPackage } = scormItem;

        const elearningPackage = {
            portalId: env.portalIdentifier,
            supplierKey: supplier.supplierKey,
            supplierName: supplier.name,
            publisherIdString: auth.user.profile.sub,
            publisherName: auth.user.profile.name,
            items: [
                {
                    targetId: targetId,
                    title: title,
                    description: description,
                    launchType: launchType,
                    startData: scormPackage
                        ? {
                            packageId: scormPackage.info?.packageId,
                            timestamp: scormPackage.info?.uploadDate,
                            name: scormPackage.info?.name,
                        }
                        : null,
                }
            ],
            updateMode: scormPackage ? 1 : 0, // 'newversion' : 'update'
        };

        await httpPost(`${env.settings.packageEndpoint}scorm12`, auth.user, elearningPackage);

        return await httpGet(`${env.settings.elearningItemsEndpoint}${targetId}`, auth.user);
    },
);

const scormSlice = createSlice({
    name: 'scorm',
    initialState: {
        status: 'idle',
        list: [],
        page: -1,
        error: null
    },
    reducers: {
        updateScormItem: (state, action) => {
            const { payload } = action;

            if (assignAndIsDirty(state.current, payload.changes)) {
                state.dirty = true;
            }
        },
        addScormPackage: (state, action) => {
            const { payload } = action;

            state.current.scormPackage = payload.scormPackage;
            state.dirty = true;
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(loadScormItemsIfNeeded.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(loadScormItemsIfNeeded.fulfilled, (state, action) => {
                const { payload } = action;

                state.status = 'success';
                state.list = payload.reload ? [...payload.list] : [...state.list, ...payload.list];
                state.page = payload.page;
                state.total = payload.total;
                state.search = payload.search;
            })
            .addCase(loadScormItemsIfNeeded.rejected, (state, action) => {
                state.status = 'failed';
                state.error = action.error;
            })
            .addCase(loadScormItem.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(loadScormItem.fulfilled, (state, action) => {
                const { payload } = action;

                state.status = 'success';
                state.current = payload;
                state.dirty = false;
            })
            .addCase(loadScormItem.rejected, (state, action) => {
                state.status = 'failed';
                state.error = action.error;
            })
            .addCase(publishScormItemAsComposition.pending, (state) => {
                state.status = 'publishing';
            })
            .addCase(publishScormItemAsComposition.fulfilled, (state, action) => {
                const { payload } = action;
                let scormItem = state.list.find((i) => i.targetId === payload.targetId);

                state.status = 'success';
                scormItem = Object.assign(scormItem, payload);
            })
            .addCase(publishScormItemAsComposition.rejected, (state, action) => {
                state.status = 'failed';
                state.error = action.error;
            })
            .addCase(unpublishScormItemAsComposition.pending, (state) => {
                state.status = 'unpublishing';
            })
            .addCase(unpublishScormItemAsComposition.fulfilled, (state, action) => {
                const { payload } = action;
                let ltiItem = state.list.find((i) => i.targetId === payload.targetId);

                state.status = 'success';
                ltiItem = Object.assign(ltiItem, payload);
            })
            .addCase(unpublishScormItemAsComposition.rejected, (state, action) => {
                state.status = 'failed';
                state.error = action.error;
            })
            .addCase(createScormItem.pending, (state) => {
                state.status = 'creating';
            })
            .addCase(createScormItem.fulfilled, (state, action) => {
                const { payload } = action;

                state.status = 'success';
                state.list.unshift(payload);
                state.total++;
            })
            .addCase(createScormItem.rejected, (state, action) => {
                state.status = 'failed';
                state.error = action.error;
            })
            .addCase(deleteScormItem.pending, (state) => {
                state.status = 'deleting';
            })
            .addCase(deleteScormItem.fulfilled, (state, action) => {
                const { payload } = action;

                state.status = 'success';
                state.list = state.list.filter((i) => i.targetId !== payload.targetId);
                state.total--;
            })
            .addCase(deleteScormItem.rejected, (state, action) => {
                state.status = 'failed';
                state.error = action.error;
            })
            .addCase(saveScormItem.pending, (state) => {
                state.status = 'saving';
            })
            .addCase(saveScormItem.fulfilled, (state, action) => {
                const { payload } = action;

                state.status = 'success';
                state.current = payload;
                state.dirty = false;
            })
            .addCase(saveScormItem.rejected, (state, action) => {
                state.status = 'failed';
                state.error = action.error;
            });
    },
});

export const { updateScormItem, addScormPackage } = scormSlice.actions;

export default scormSlice.reducer;

startListening({
    actionCreator: addScormPackage,
    effect: async (_, { dispatch }) => {
        dispatch(saveScormItem());
    },
});

import { html, css, nothing, LitElement } from 'lit';
import { customElement, property, state, query } from 'lit/decorators.js';
import { map } from 'lit/directives/map.js';
import connect from 'wc-context-redux/connect';
import sharedStyles from '../../app/styles.js';
import { saveScormItem, updateScormItem, addScormPackage } from '../../store/scorm/scorm.js';
import { navigate } from '../../store/route/route.js';
import { previewElearning } from '../../store/preview/preview.js';
import { translate } from '../../helpers/translate.js';
import { formatDateTime } from '../../helpers/controls.js';
import { createSupplierKey, launchTypes } from '../../store/elearning/helpers.js';
import '@sdblearning/sdb-learning-frontend';
import '@vaadin/select/theme/material/vaadin-select.js';
import '@polymer/paper-input/paper-input.js';
import '@polymer/iron-form/iron-form.js';
import '../shared/sdb-loading.js';
import '../shared/sdb-dialog.js';
import '../shared/sdb-parameters.js';
import '../shared/sdb-overlay-detail.js';
import './sdb-scorm-package-upload.js';

const mapStateToProps = (state) => ({
    scormItem: state.scorm.current,
    isLoading: state.scorm.status === 'loading' || !state.scorm.current,
    isDirty: state.scorm.dirty,
    settings: state.env.settings,
    user: state.auth.user,
    portalId: state.env.portalIdentifier,
});

const mapDispatchToProps = (dispatch) => ({
    saveScormItem: (id) => dispatch(saveScormItem({ id })),
    updateScormItem: (changes) => dispatch(updateScormItem({ changes })),
    addScormPackage: (scormPackage) => dispatch(addScormPackage({ scormPackage })),
    previewElearning: (item) => dispatch(previewElearning({ item })),
    navigate: (path) => dispatch(navigate(path)),
});

@customElement('sdb-scorm-item')
class SdbScormItem extends connect(mapStateToProps, mapDispatchToProps)(LitElement) {

    @property({ type: String })
    accessor targetId;

    @state()
    accessor scormItem;

    @state()
    accessor isLoading = false;

    @state()
    accessor isDirty = false;

    @query('#dirty_form_dialog')
    accessor dirtyFormDialog;

    @query('#scorm_item_form')
    accessor scormItemForm;

    @query('#scorm_uploader')
    accessor scormUploader;

    get latestVersion() {
        if (!this.scormItem.versions?.length)
            return null;
        return this.scormItem.versions[this.scormItem.versions.length-1];
    }

    static get styles() {
        return [
            sharedStyles,
            css`
                #scorm_item_form {
                    width: 100%;
                }

                sdb-content-card {
                    max-width: 1400px;
                    margin-bottom: 16px;
                }

                sdb-content-card .headline {
                    display: inline-flex;
                    align-items: center;
                    color: var(--text-primary);
                    padding: 10px 0 20px 0;
                }

                sdb-content-card .headline span {
                    margin-left: 24px;
                    font-size: 16px;
                    font-family: var(--font-family);
                }

                paper-input {
                    margin-bottom: 5px;
                }

                paper-input[required] label {
                    color: red;
                }

                vaadin-select {
                    width: 30%;
                    min-width: 200px;
                }

                .uploader_container {
                    margin-top: 30px;
                }

                .uploader_container > span {
                    display: block;
                    font-size: 14px;
                    margin-bottom: 10px;
                    margin-left: 25px;
                }
            `,
        ];
    }

    render() {
        if (this.isLoading || this.targetId !== this.scormItem.targetId) {
            return html`<sdb-loading></sdb-loading>`;
        }

        return html`
            <sdb-overlay-detail
                headline=${this.scormItem.title}
                @back=${() => this.isDirty ? this.dirtyFormDialog.open() : this.navigate('scorm') }>

                <div slot="buttons">
                    <sdb-content-button
                        id="preview_button"
                        label=${translate('preview')}
                        primary
                        ?disabled=${this.isDirty}
                        @click=${()=> { this.previewElearning(this.scormItem); }}>
                    </sdb-content-button>
                    <sdb-content-button
                        id="save_button"
                        label=${translate('save')}
                        primary
                        ?disabled=${!this.isDirty}
                        @click=${() => {
                            if (this.scormItemForm.validate())
                                this.saveScormItem(this.scormItem.id);
                        }}>
                    </sdb-content-button>
                </div>

                <iron-form id="scorm_item_form" slot="content">
                    <form>
                        <sdb-layout-100>

                            <sdb-content-card id="general_card">
                                <paper-input
                                    id="name_input"
                                    transparent
                                    label=${translate('name')}
                                    value=${this.scormItem.title}
                                    @change=${(e) => this.updateScormItem({ title: e.target.value })}>
                                </paper-input>

                                <paper-input
                                    id="description_textarea"
                                    transparent
                                    label=${translate('description')}
                                    value=${this.scormItem.description}
                                    @change=${(e) => this.updateScormItem({ description: e.target.value })}>
                                </paper-input>

                                <paper-input
                                    id="supplier_input"
                                    transparent
                                    label=${translate('supplier')}
                                    value=${this.scormItem.supplier.name}
                                    @change=${(e) => this.updateScormItem({
                                        supplier: {
                                            name: e.target.value,
                                            supplierKey: createSupplierKey(e.target.value),
                                        }
                                    })}>
                                </paper-input>

                                <vaadin-select
                                    id="launchtype_select"
                                    transparent
                                    label=${translate('launchType')}
                                    value=${String(this.scormItem.launchType)}
                                    @change=${(e) => { this.updateScormItem({ launchType: parseInt(e.target.value, 10) }) }}
                                    .items=${Object.entries(launchTypes).map(([key, value]) => ({
                                        label: translate(key.toLowerCase()),
                                        value: String(value),
                                    }))}>
                                </vaadin-select>

                            </sdb-content-card>

                            ${this.latestVersion ? html`
                                <sdb-content-card id="versions_card">
                                    <div class="headline">
                                        <sdb-content-icon
                                            icon="fal fa-file-zip"
                                            size="32">
                                        </sdb-content-icon>
                                        <span>SCORM Package<span>
                                    </div>

                                    <sdb-content-accordion>
                                        <sdb-content-accordionitem
                                            overline=${translate('latestVersion')}
                                            headline=${this.latestVersion.startData?.name}
                                            subtitle=${formatDateTime(this.latestVersion.startData?.timestamp)}>
                                            <sdb-content-list class="scroller">
                                                <sdb-content-listheading>
                                                    <sdb-content-headingitem
                                                        textDark
                                                        slot="1"
                                                        headline=${translate('version')}>
                                                    </sdb-content-headingitem>
                                                    <sdb-content-headingitem
                                                        textDark
                                                        slot="2"
                                                        headline=${translate('package')}>
                                                    </sdb-content-headingitem>
                                                    <sdb-content-headingitem
                                                        textDark
                                                        slot="3"
                                                        headline=${translate('uploaded')}>
                                                    </sdb-content-headingitem>
                                                </sdb-content-listheading>
                                                ${map(this._reverse(this.scormItem.versions), (version) => html`
                                                    <sdb-content-listitem
                                                        headline=${version.version}
                                                        noPadding
                                                        noHover>
                                                        <span slot="2">${version.startData?.name}</span>
                                                        <span slot="3">${formatDateTime(version.startData?.timestamp)}</span>
                                                    </sdb-content-listitem>
                                                `)}
                                                <sdb-content-listitem
                                                    noPadding
                                                    noHover>
                                                    <div class="uploader_container">
                                                        <span>${translate('uploadNewVersion')}</span>
                                                        ${this._renderUploader()}
                                                    </div>
                                                </sdb-content-listitem>
                                            </sdb-content-list>
                                        </sdb-content-accordionitem>
                                    </sdb-content-accordion>

                                </sdb-content-card>
                            ` : html`
                                <sdb-content-card id="upload_card">
                                    <div class="headline">
                                        <sdb-content-icon
                                            icon="fal fa-file-import"
                                            size="32">
                                        </sdb-content-icon>
                                        <span>${translate('uploadNewPackage')}<span>
                                    </div>

                                    ${this._renderUploader()}
                                </sdb-content-card>
                            `}

                        </sdb-layout-100>
                    </form>
                </iron-form>
            </sdb-overlay-detail>

            <sdb-dialog
                type="confirm"
                id="dirty_form_dialog"
                heading=${translate('dirtyFormHeading')}
                body=${translate('dirtyFormBody')}
                confirmCaption=${translate('continue')}
                @confirm=${(e) => { this.navigate('scorm'); }}>
            </sdb-dialog>
        `;
    }

    firstUpdated() {
        this.dispatchEvent(
            new CustomEvent('fullscreen', {
                bubbles: true,
                composed: true,
                detail: {
                    fullscreen: true,
                },
            }),
        );
    }

    _renderUploader() {
        return html`
            <sdb-scorm-package-upload
                id="scorm_uploader"
                @upload-completed=${(e) => {
                    this.addScormPackage(e.detail.package);
                    this.scormUploader.clear();
                }}>
            </sdb-scorm-package-upload>
        `;
    }

    _reverse(items) {
        return [...items].reverse();

    }
}

export const completionPresets = {
    default: {
        finishImmediately: false,
        finishAfterNumberOfGrades: null,
        finishScore: null,
        masteryScore: null,
    },
    immediately: {
        finishImmediately: true,
        finishAfterNumberOfGrades: null,
        finishScore: null,
        masteryScore: null,
    },
    firstResult: {
        finishImmediately: false,
        finishAfterNumberOfGrades: 1,
        finishScore: null,
    },
    score: {
        finishImmediately: false,
        finishAfterNumberOfGrades: null,
        finishScore: .7,
        masteryScore: null,
    },
};

export const scorePresets = {
    noScore: {
        masteryScore: null,
        canScore: false,
    },
    showScore: {
        masteryScore: null,
        canScore: true,
    },
    masteryScore: {
        masteryScore: .7,
        canScore: true,
    },
};

export const getCompletionPresetKey = (ltiItem) => {
    var preset = Object.entries(completionPresets).find(([key, value]) =>
        value.finishImmediately === ltiItem.finishImmediately
        && value.finishAfterNumberOfGrades === ltiItem.finishAfterNumberOfGrades
        && _isEqualScore(value.finishScore, ltiItem.finishScore)
    );
    return preset ? preset[0] : null;
}

export const getCompletionPreset = (key) => {
    return completionPresets[key];
}

export const getScorePresetKey = (ltiItem) => {
    var preset = Object.entries(scorePresets).find(([key, value]) =>
        value.canScore === ltiItem.canScore
        && _isEqualScore(value.masteryScore, ltiItem.masteryScore)
    );
    return preset ? preset[0] : null;
}

export const getScorePreset = (key) => {
    return scorePresets[key];
}

const _isEqualScore = (score1, score2) => score1 === score2 || (!isNaN(parseFloat(score1)) && !isNaN(parseFloat(score2)));


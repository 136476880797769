import { html, css, nothing, LitElement } from 'lit';
import { property, state, query, customElement } from 'lit/decorators.js';
import connect from 'wc-context-redux/connect';
import sharedStyles from '../../app/styles.js';
import {
    updateAccreditationMapping,
    createAccreditationMapping,
    removeAccreditationMapping,
    openAccreditationMapping,
    saveAccreditationInfo,
    process,
} from '../../store/accreditationInfo/accreditationInfo.js';
import { navigate } from '../../store/route/route.js';
import { translate } from '../../helpers/translate.js';
import { formatDate, getExpirationStatus } from '../../helpers/controls.js';
import '../shared/sdb-empty-list.js';
import '../shared/sdb-overlay-detail.js';
import '@vaadin/select/theme/material/vaadin-select.js';
import '@vaadin/number-field/theme/material/vaadin-number-field.js';
import '@vaadin/text-field/theme/material/vaadin-text-field.js';
import '@vaadin/form-layout/theme/material/vaadin-form-layout.js';
import '@vaadin/progress-bar/theme/material/vaadin-progress-bar.js';
import '@sdblearning/sdb-learning-frontend';
import '@material/web/fab/fab.js';

const mapStateToProps = (state) => ({
    name: state.accreditationInfo.catalogItemName,
    mappings: state.accreditationInfo.current,
    organizations: state.accreditationInfo.organizations,
    isDirty: state.accreditationInfo.dirty,
    isLoading: state.accreditationInfo.status === 'loading' || !state.accreditationInfo.current,
    status: state.accreditationInfo.status,
});

const mapDispatchToProps = (dispatch) => ({
    updateAccreditationMapping: (index, changes) => dispatch(updateAccreditationMapping({ index, changes })),
    createAccreditationMapping: () => dispatch(createAccreditationMapping()),
    removeAccreditationMapping: (index) => dispatch(removeAccreditationMapping({ index })),
    openAccreditationMapping: (index, value) => dispatch(openAccreditationMapping({ index, value })),
    saveAccreditationInfo: (targetId) => dispatch(saveAccreditationInfo({ targetId })),
    process: (targetId) => dispatch(process({ targetId })),
    navigate: (path) => dispatch(navigate(path)),
});

@customElement('sdb-accreditation-info')
class SdbAccreditationInfo extends connect(mapStateToProps, mapDispatchToProps)(LitElement) {
    @property({ type: String })
    accessor targetId;

    @state()
    accessor name = '';

    @state()
    accessor mappings = [];

    @state()
    accessor organizations = [];

    @state()
    accessor isDirty = false;

    @state()
    accessor isLoading = false;

    @state()
    accessor status = 'idle';

    @query('#accreditation_form')
    accessor accreditationForm;

    @query('#dirty_form_dialog')
    accessor dirtyFormDialog;

    @query('#process_dialog')
    accessor processDialog;

    static get styles() {
        return [
            sharedStyles,
            css`
                :host {
                    display: block;
                }

                .actions {
                    display: flex;
                    margin-top: 5px;
                }

                .actions sdb-content-button {
                    margin-left: auto;
                }

                [slot=content] {
                    width: 100%;
                }

                vaadin-number-field.small {
                    width: 100px !important;
                }
            `,
        ];
    }

    render() {
        if (this.isLoading) {
            return nothing;
        }

        return html`
            <sdb-overlay-detail
                headline=${this.name}
                @back=${() => this.isDirty ? this.dirtyFormDialog.open() : this.navigate('compositions') }>

                <div slot="buttons">
                    <sdb-content-button
                        label=${translate('process')}
                        ?disabled=${this.isDirty || this.status === 'processing'}
                        @click=${() => {this.processDialog.open();}}>
                    </sdb-content-button>
                    <sdb-content-button
                        label=${translate('save')}
                        ?disabled=${!this.isDirty || this.status === 'saving'}
                        @click=${() => { this._save(); }}>
                    </sdb-content-button>
                </div>

                <div slot="content">

                    ${this.mappings.length === 0 ? html`
                        <sdb-empty-list label=${translate('noAccreditationInfoFound')}>
                        </sdb-empty-list>
                    ` : html`
                        <sdb-layout-100>
                            <vaadin-progress-bar
                                ?hidden=${this.status !== 'processing'}
                                indeterminate>
                            </vaadin-progress-bar>
                            <iron-form id="accreditation_form">
                                <form>
                                    <sdb-content-accordion separator>

                                        <sdb-content-accordionheading class="hideonmobile">
                                            <sdb-content-headingitem
                                                textDark
                                                slot="1"
                                                icon="fal fa-users"
                                                headline=${translate('pe.organization')}>
                                            </sdb-content-headingitem>
                                            <sdb-content-headingitem
                                                textDark
                                                slot="2"
                                                icon="fal fa-calendar"
                                                headline=${translate('pe.duration')}>
                                            </sdb-content-headingitem>
                                            <sdb-content-headingitem
                                                textDark
                                                slot="3"
                                                icon="fal fa-input-numeric"
                                                headline=${translate('pe.courseId')}>
                                            </sdb-content-headingitem>
                                            <sdb-content-headingitem
                                                textDark
                                                slot="4"
                                                icon="fal fa-input-numeric"
                                                headline=${translate('pe.moduleId')}>
                                            </sdb-content-headingitem>
                                        </sdb-content-accordionheading>

                                        ${this.mappings.map((mapping, index) => html`
                                            <sdb-content-accordionitem
                                                headline=${this.organizations.find((o) => o.value === mapping.organizationKey).label}
                                                statusColor=${this._getWarningColor(mapping.validTill)}
                                                @opened-changed=${(e) => {
                                                    if (e.detail.value !== mapping.$opened) {
                                                        this.openAccreditationMapping(index, e.detail.value);
                                                    }
                                                }}
                                                ?opened=${mapping.$opened}>

                                                <div slot="2">${formatDate(mapping.validFrom)} - ${formatDate(mapping.validTill)}</div>
                                                <div slot="3">${mapping.data.peCourseId}</div>
                                                <div slot="4">${mapping.data.peModuleId}</div>

                                                ${this._renderMappingForm(mapping, index)}

                                                <div class="actions">
                                                    <sdb-content-button
                                                        class="remove-btn"
                                                        label=${translate('remove')}
                                                        @click=${() => { this._remove(index); }}>
                                                    </sdb-content-button>
                                                </div>

                                            </sdb-content-accordionitem>
                                        `)}

                                    </sdb-content-accordion>
                                </form>
                            </iron-form>

                        </sdb-layout-100>
                    `}

                    <md-fab @click=${() => { this.createAccreditationMapping(); }}>
                        <sdb-content-icon slot="icon" icon="fal fa-plus" size="24"></sdb-content-icon>
                    </md-fab>
                </div>
            </sdb-overlay-detail>

            <sdb-dialog
                type="confirm"
                id="dirty_form_dialog"
                heading=${translate('dirtyFormHeading')}
                body=${translate('dirtyFormBody')}
                confirmCaption=${translate('continue')}
                @confirm=${() => { this.navigate('compositions'); }}>
            </sdb-dialog>

            <sdb-dialog
                type="confirm"
                id="process_dialog"
                heading=${translate('processHeading')}
                body=${translate('processBody')}
                confirmCaption=${translate('process')}
                @confirm=${() => { this.process(this.targetId); }}>
            </vaadin-confirm-dialog>
        `;
    }

    _renderMappingForm(mapping, index) {
        const update = (changes) => this.updateAccreditationMapping(index, changes);

        return html`
            <vaadin-form-layout>

                <vaadin-select
                    required
                    transparent
                    label=${translate('pe.organization')}
                    value=${mapping.organizationKey}
                    .items=${this.organizations}
                    @change=${(e) => { update({ organizationKey: e.target.value }); }}
                ></vaadin-select>

                <div></div>

                <sdb-content-date-picker
                    label=${translate('pe.duration.start')}
                    max=${mapping.validTill}
                    value=${mapping.validFrom}
                    @change=${(e) => { update({ validFrom: e.detail.value }); }}
                ></sdb-content-date-picker>

                <sdb-content-date-picker
                    label=${translate('pe.duration.end')}
                    min=${mapping.validFrom}
                    value=${mapping.validTill}
                    @change=${(e) => { update({ validTill: e.detail.value }); }}
                ></sdb-content-date-picker>

                <vaadin-number-field
                    required
                    label=${translate('pe.courseId')}
                    value=${mapping.data.peCourseId}
                    @change=${(e) => { update({ data: { peCourseId: e.target.value } }); }}>
                </vaadin-number-field>

                <vaadin-number-field
                    label=${translate('pe.moduleId')}
                    value=${mapping.data.peModuleId}
                    @change=${(e) => { update({ data: { peModuleId: e.target.value } }); }}>
                </vaadin-number-field>

                <vaadin-number-field
                    class="small"
                    step="0.5"
                    min="0"
                    step-buttons-visible
                    label=${translate('pe.points')}
                    value=${mapping.points}
                    @change=${(e) => { update({ points: e.target.value }); }}>
                </vaadin-number-field>

            </vaadin-form-layout>
        `;
    }

    _getWarningColor(date) {
        switch (getExpirationStatus(date, 3, 'months')) {
        case 'expired': return 'red';
        case 'almost-expired': return 'orange';
        default: return '';
        }
    }

    _remove(index) {
        this.removeAccreditationMapping(index);
    };

    _save() {
        if (!this.accreditationForm || this.accreditationForm.validate()) {
            this.saveAccreditationInfo(this.targetId);
        }
    };
}
